import callApi from "../libs/helpers/callApi";
import {
  createUserApi,
  editUserApi,
  getUserDetailApi,
  getUserListApi,
  getUserProfilesApi,
  getTenantListApi,
  deleteUserApi,
  getPluginListApi,
} from "../api/user.api";
import { goTo } from "./app.actions";
import { refreshPage } from "../libs/utils";

export const ADMIN_LIST_LOADING = "ADMIN_LIST_LOADING";
export const ADMIN_LIST_LOADED = "ADMIN_LIST_LOADED";
export const ADMIN_LIST_LOAD_ERROR = "ADMIN_LIST_LOAD_ERROR";

// ADMIN LIST
export const getAdminListAction = callApi({
  apiCall: (p) => getUserListApi(p),
  secured: true,
  startAction: ADMIN_LIST_LOADING,
  successAction: ADMIN_LIST_LOADED,
  errorAction: ADMIN_LIST_LOAD_ERROR,
});

// SADMIN_LIST

export const SADMIN_LIST_LOADING = "SADMIN_LIST_LOADING";
export const SADMIN_LIST_LOADED = "SADMIN_LIST_LOADED";
export const SADMIN_LIST_LOAD_ERROR = "SADMIN_LIST_LOAD_ERROR";

// SUPER ADMIN LIST
export const getSuperAdminListAction = callApi({
  apiCall: (p) => getUserListApi(p),
  secured: true,
  startAction: SADMIN_LIST_LOADING,
  successAction: SADMIN_LIST_LOADED,
  errorAction: SADMIN_LIST_LOAD_ERROR,
});

export const CUSTOMER_LIST_LOADING = "CUSTOMER_LIST_LOADING";
export const CUSTOMER_LIST_LOADED = "CUSTOMER_LIST_LOADED";
export const CUSTOMER_LIST_LOAD_ERROR = "CUSTOMER_LIST_LOAD_ERROR";

// CUSTOMER LIST
export const getCustomerListAction = callApi({
  apiCall: (p) => getUserListApi(p),
  secured: true,
  startAction: CUSTOMER_LIST_LOADING,
  successAction: CUSTOMER_LIST_LOADED,
  errorAction: CUSTOMER_LIST_LOAD_ERROR,
});

export const ADMIN_PROFILES_LOADING = "ADMIN_PROFILES_LOADING";
export const ADMIN_PROFILES_LOADED = "ADMIN_PROFILES_LOADED";
export const ADMIN_PROFILES_LOAD_ERROR = "ADMIN_PROFILES_LOAD_ERROR";

// ADMIN PROFILES
export const getUserProfilesAction = callApi({
  apiCall: (p) => getUserProfilesApi(p),
  secured: true,
  startAction: ADMIN_PROFILES_LOADING,
  successAction: ADMIN_PROFILES_LOADED,
  errorAction: ADMIN_PROFILES_LOAD_ERROR,
});

export const TENANT_LIST_LOADING = "TENANT_LIST_LOADING";
export const TENANT_LIST_LOADED = "TENANT_LIST_LOADED";
export const TENANT_LIST_LOAD_ERROR = "TENANT_LIST_LOAD_ERROR";

// ADMIN PROFILES
export const getTenantListAction = callApi({
  apiCall: (p) => getTenantListApi(p),
  secured: true,
  startAction: TENANT_LIST_LOADING,
  successAction: TENANT_LIST_LOADED,
  errorAction: TENANT_LIST_LOAD_ERROR,
});

export const PLUGIN_LIST_LOADING = "PLUGIN_LIST_LOADING";
export const PLUGIN_LIST_LOADED = "PLUGIN_LIST_LOADED";
export const PLUGIN_LIST_LOAD_ERROR = "PLUGIN_LIST_LOAD_ERROR";

// ADMIN PROFILES
export const getPluginListAction = callApi({
  apiCall: (p) => getPluginListApi(p),
  secured: true,
  startAction: PLUGIN_LIST_LOADING,
  successAction: PLUGIN_LIST_LOADED,
  errorAction: PLUGIN_LIST_LOAD_ERROR,
});

export const ADMIN_DETAIL_LOADING = "ADMIN_DETAIL_LOADING";
export const ADMIN_DETAIL_LOADED = "ADMIN_DETAIL_LOADED";
export const ADMIN_DETAIL_LOAD_ERROR = "ADMIN_DETAIL_LOAD_ERROR";

// ADMIN_DETAIL
export const getUserDetailAction = callApi({
  apiCall: (p) => getUserDetailApi(p),
  secured: true,
  startAction: ADMIN_DETAIL_LOADING,
  successAction: ADMIN_DETAIL_LOADED,
  errorAction: ADMIN_DETAIL_LOAD_ERROR,
});

export const ADMIN_CREATE_LOADING = "ADMIN_CREATE_LOADING";
export const ADMIN_CREATE_COMPLETE = "ADMIN_CREATE_COMPLETE";
export const ADMIN_CREATE_LOAD_ERROR = "ADMIN_CREATE_LOAD_ERROR";

// ADMIN CREATE
export const createUserAction = callApi({
  apiCall: (p) => createUserApi(p),
  secured: true,
  startAction: ADMIN_CREATE_LOADING,
  successAction: ADMIN_CREATE_COMPLETE,
  errorAction: ADMIN_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    if (!params.isAdmin) {
      dispatch(getCustomerListAction({ group: "USER,EXTERNAL" }));
      dispatch(goTo("/settings/customer"));
    } else {
      dispatch(getSuperAdminListAction());
      dispatch(goTo("/settings/admin"));
    }
  },
});

export const ADMIN_EDIT_LOADING = "ADMIN_EDIT_LOADING";
export const ADMIN_EDIT_COMPLETE = "ADMIN_EDIT_COMPLETE";
export const ADMIN_EDIT_LOAD_ERROR = "ADMIN_EDIT_LOAD_ERROR";

// ADMIN EDIT
export const editUserAction = callApi({
  apiCall: (p) => editUserApi(p),
  secured: true,
  startAction: ADMIN_EDIT_LOADING,
  successAction: ADMIN_EDIT_COMPLETE,
  errorAction: ADMIN_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    if (!params.isAdmin) {
      dispatch(getCustomerListAction({ group: "USER,EXTERNAL" }));
      dispatch(goTo("/settings/customer"));
    } else {
      dispatch(getSuperAdminListAction());
      dispatch(goTo("/settings/admin"));
    }
  },
});

export const ADMIN_DELETE_LOADING = "ADMIN_DELETE_LOADING";
export const ADMIN_DELETE_COMPLETE = "ADMIN_DELETE_COMPLETE";
export const ADMIN_DELETE_LOAD_ERROR = "ADMIN_DELETE_LOAD_ERROR";

// ADMIN DELETE
export const deleteUserAction = callApi({
  apiCall: (p) => deleteUserApi(p),
  secured: true,
  startAction: ADMIN_DELETE_LOADING,
  successAction: ADMIN_DELETE_COMPLETE,
  errorAction: ADMIN_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    if (!params.isAdmin) {
      dispatch(getCustomerListAction({ group: "USER,EXTERNAL" }));
      dispatch(goTo("/settings/customer"));
    } else {
      dispatch(getSuperAdminListAction());
      dispatch(goTo("/settings/admin"));
    }
  },
});
