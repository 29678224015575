import callApi from "../libs/helpers/callApi";
import {
  getCreaEntityApi,
  getCreaDetailApi,
  getCreaSnapshotApi,
  editCreaDetailApi,
  /*
  editCreaDetailApi,
  createCreaDetailApi,*/
} from "../api/crea.api";

export const CREA_ENTITY_LOADING = "CREA_ENTITY_LOADING";
export const CREA_ENTITY_LOADED = "CREA_ENTITY_LOADED";
export const CREA_ENTITY_LOAD_ERROR = "CREA_ENTITY_LOAD_ERROR";

export const getCreaEntityAction = callApi({
  apiCall: (p) => getCreaEntityApi(p),
  secured: true,
  startAction: CREA_ENTITY_LOADING,
  successAction: CREA_ENTITY_LOADED,
  errorAction: CREA_ENTITY_LOAD_ERROR,
});

export const CREA_DETAIL_LOADING = "CREA_DETAIL_LOADING";
export const CREA_DETAIL_LOADED = "CREA_DETAIL_LOADED";
export const CREA_DETAIL_LOAD_ERROR = "CREA_DETAIL_LOAD_ERROR";

export const getCreaDetailAction = callApi({
  apiCall: (p) => getCreaDetailApi(p),
  secured: true,
  startAction: CREA_DETAIL_LOADING,
  successAction: CREA_DETAIL_LOADED,
  errorAction: CREA_DETAIL_LOAD_ERROR,
});

export const CREA_SNAPSHOT_LOADING = "CREA_SNAPSHOT_LOADING";
export const CREA_SNAPSHOT_LOADED = "CREA_SNAPSHOT_LOADED";
export const CREA_SNAPSHOT_LOAD_ERROR = "CREA_SNAPSHOT_LOAD_ERROR";

export const getCreaSnapshotAction = callApi({
  apiCall: (p) => getCreaSnapshotApi(p),
  secured: true,
  startAction: CREA_SNAPSHOT_LOADING,
  successAction: CREA_SNAPSHOT_LOADED,
  errorAction: CREA_SNAPSHOT_LOAD_ERROR,
});

export const CREA_DETAIL_EDITING = "CREA_DETAIL_EDITING";
export const CREA_DETAIL_EDITED = "CREA_DETAIL_EDITED";
export const CREA_DETAIL_EDIT_ERROR = "CREA_DETAIL_EDIT_ERROR";

export const editCreaDetailAction = callApi({
  apiCall: (p) => editCreaDetailApi(p),
  secured: true,
  startAction: CREA_DETAIL_EDITING,
  successAction: CREA_DETAIL_EDITED,
  errorAction: CREA_DETAIL_EDIT_ERROR,
});
