import axios from "axios";
import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import { getFromLocalStorage } from "../libs/helpers/localstorage";
import Localize from "../libs/localization";

export const getCompanyEntityApi = async ({ headers, page }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.list}/entity`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const OLDgetCompanyListApi = async ({
  headers = "autofill",
  page,
  params = null,
}) => {
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacTenant").data;
  const token = ls && ls.token;
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.list}?pageNum=${page || 1}${
      params ? `&${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers: {
        "x-tenant": tenant,
        Authorization: `Bearer ${token}`,
        ...headers,
      },
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

const abortChannels = {};

export async function getCompanyListApi({
  headers,
  page,
  params = null,
  abortChannel = null,
}) {
  if (abortChannel !== null) {
    if (abortChannels[abortChannel]) {
      abortChannels[abortChannel].abort();
    }
    abortChannels[abortChannel] = new AbortController();
  }
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacTenant")?.data;
  const token = ls && ls.token;
  try {
    const res = await axios.get(
      `${Config.apiBaseurl}/${Config.apis.company.list}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-tenant": tenant,
          ...headers,
        },
        params: {
          pageNum: page || 1,
          ...params,
        },
        signal:
          abortChannel !== null ? abortChannels[abortChannel].signal : null,
      }
    );
    return res.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return { canceled: true };
    }
    throw e;
  }
}

export const getCompanyDuplicatesApi = async ({ headers, params = [] }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.company.list
    }/checkDuplicate?${params.join("&")}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const createCompanyDetailApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const generateContactEmailUsernamesApi = async ({
  headers,
  companyId,
  contactIds,
}) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.generateContactUsernames}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify({
        companyId,
        contactIds,
      }),
    }
  );

  if (res.ok) {
    return;
  }

  const response = await res.json();
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const generateContactEmailDomainsApi = async ({
  headers,
  companyId,
  contactIds,
}) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.generateContactDomains}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify({
        companyId,
        contactIds,
      }),
    }
  );

  if (res.ok) {
    return;
  }
  const response = await res.json();
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanyDetailApi = async ({
  headers,
  id,
  countView = false,
}) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}?stats=${
      countView ? "yes" : "no"
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;

    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const getCompanyGroupApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/ownershipGraph`,
    {
      method: "GET",
      headers,
    }
  );
  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const editCompanyDetailApi = async ({ headers, id, data }) => {
  if (data.modified_by) delete data.modified_by;
  delete data.jobOffers;
  delete data.jobOffersUpdatedAt;
  if (data.international_invest?.length > 0) {
    data.international_invest.forEach((element, i) => {
      if (element === "") data.international_invest.splice(i, 1);
    });
  }
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify({
        ...data,
        jobOffers: undefined,
      }),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const acquireCompanyContactsApi = async ({
  headers,
  id,
  contactsId,
}) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/acquireContacts`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(contactsId),
    }
  );

  const response = await res;

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const acquireCompanyBrandsApi = async ({ headers, id, brandsId }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/acquireBrands`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(brandsId),
    }
  );

  const response = await res;

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const mergeCompanyApi = async ({ headers, destinationId, sourceId }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${destinationId}/merge/${sourceId}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res;

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const mergeSectorApi = async ({ headers, destinationId, sourceId }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/mergeSectors/${destinationId}/${sourceId}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res;

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const exportCompanyListApi = async ({ headers, params = null }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.list}/export?${
      params ? `${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.blob();

  if (res.ok) {
    return response;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    status !== 403 ? description : Localize("ERROR.UNEXPECTED"),
    httpStatus,
    status !== 403 ? status : null,
    clientType
  );
};

export const downloadNielsenFileCompanyApi = async ({ headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.nielsen.export}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.blob();

  if (res.ok) {
    return response;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const downloadDataStatsCompanyApi = async ({ headers }) => {
  const res = await fetch(`${Config.apiBaseurl}/${Config.apis.utils.stats}`, {
    method: "GET",
    headers,
  });

  const response = await res.blob();

  if (res.ok) {
    return response;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const downloadSpecificExtractionApi = async ({ headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.utils.specificExtraction}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.blob();

  if (res.ok) {
    return response;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const downloadLoginReportApi = async ({ headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.utils.loginReport}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.blob();

  if (res.ok) {
    return response;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const downloadSpecificCompanyExtractionApi = async ({ headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.utils.specificCompanyExtraction}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.blob();

  if (res.ok) {
    return response;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanyContactsApi = async ({ headers, id, params = null }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/contacts${
      params ? `?${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const createCompanyContactApi = async ({ headers, data, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/contact`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const getCompanyBrandsApi = async ({ headers, id, params = null }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/brands${
      params ? `?${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const createCompanyBrandApi = async ({ headers, data, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/brand`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const getCompanyNielsenApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/nielsen`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const uploadNielsenFileApi = async ({ headers, data }) => {
  const url = Config.apiBaseurl + "/api/v1/nielsen";
  const options = {
    method: "POST",
    headers,
    body: data,
  };
  delete options.headers["Content-Type"];

  const res = await fetch(url, options);

  const response = await res;

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const uploadFinancialsFileApi = async ({ headers, data }) => {
  const url = Config.apiBaseurl + "/api/v1/financials";
  const options = {
    method: "POST",
    headers,
    body: data,
  };
  delete options.headers["Content-Type"];

  const res = await fetch(url, options);

  const response = await res;

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const getCompanyPitchApi = async ({ headers, id, params = null }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/pitchs${
      params ? `?${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const createCompanyPitchApi = async ({ headers, id, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/pitch`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const getCompanyCreaApi = async ({ headers, id, params = null }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/creas${
      params ? `?${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const createCompanyCreaApi = async ({ headers, id, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/crea`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const getCompaniesById = async ({ ids }) => {
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacTenant").data;
  const token = ls && ls.token;
  const res = await axios.post(
    `${Config.apiBaseurl}/${Config.apis.company.getManyById}`,
    {
      ids,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
      },
    }
  );
  return res;
};
