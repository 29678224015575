import {
  SECONDARY_GROUP_CREATE_COMPLETE,
  SECONDARY_GROUP_CREATE_LOADING,
  SECONDARY_GROUP_CREATE_LOAD_ERROR,
  SECONDARY_GROUP_DELETE_COMPLETE,
  SECONDARY_GROUP_DELETE_LOADING,
  SECONDARY_GROUP_DELETE_LOAD_ERROR,
  SECONDARY_GROUP_EDIT_COMPLETE,
  SECONDARY_GROUP_EDIT_LOADING,
  SECONDARY_GROUP_EDIT_LOAD_ERROR,
  SECONDARY_GROUP_LOADED,
  SECONDARY_GROUP_LOADING,
  SECONDARY_GROUP_LOAD_ERROR,
} from "../../actions/registry/registry.secondary_group.actions";
import { reducerFromMap } from "../../libs/helpers/reducers";

const reducer = {
  /*
   * SECONDARY_GROUP LIST
   */
  [SECONDARY_GROUP_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SECONDARY_GROUP_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data,
  }),
  [SECONDARY_GROUP_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SECONDARY_GROUP CREATE
   */
  [SECONDARY_GROUP_CREATE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SECONDARY_GROUP_CREATE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SECONDARY_GROUP_CREATE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SECONDARY_GROUP EDIT
   */
  [SECONDARY_GROUP_EDIT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SECONDARY_GROUP_EDIT_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SECONDARY_GROUP_EDIT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SECONDARY_GROUP DELETE
   */
  [SECONDARY_GROUP_DELETE_LOADING]: (state) => ({
    ...state,
    group: { ...state.group, loading: true },
  }),
  [SECONDARY_GROUP_DELETE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SECONDARY_GROUP_DELETE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    group: { ...state.group, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
