import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  MAIN_CATEGORY_LOADING,
  MAIN_CATEGORY_LOADED,
  MAIN_CATEGORY_LOAD_ERROR,
  MAIN_CATEGORY_CREATE_LOADING,
  MAIN_CATEGORY_CREATE_LOAD_ERROR,
  MAIN_CATEGORY_EDIT_LOADING,
  MAIN_CATEGORY_EDIT_COMPLETE,
  MAIN_CATEGORY_EDIT_LOAD_ERROR,
  MAIN_CATEGORY_DELETE_LOADING,
  MAIN_CATEGORY_DELETE_COMPLETE,
  MAIN_CATEGORY_DELETE_LOAD_ERROR,
  MAIN_CATEGORY_CREATE_COMPLETE,
} from "../../actions/registry/registry.main_category.actions";

const reducer = {
  /*
   * MAIN_CATEGORY LIST
   */
  [MAIN_CATEGORY_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [MAIN_CATEGORY_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [MAIN_CATEGORY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * MAIN_CATEGORY CREATE
   */
  [MAIN_CATEGORY_CREATE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [MAIN_CATEGORY_CREATE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [MAIN_CATEGORY_CREATE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * MAIN_CATEGORY EDIT
   */
  [MAIN_CATEGORY_EDIT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [MAIN_CATEGORY_EDIT_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [MAIN_CATEGORY_EDIT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * MAIN_CATEGORY DELETE
   */
  [MAIN_CATEGORY_DELETE_LOADING]: (state) => ({
    ...state,
    main_category: { ...state.main_category, loading: true },
  }),
  [MAIN_CATEGORY_DELETE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [MAIN_CATEGORY_DELETE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    main_category: { ...state.main_category, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
