import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  INCOME_RANGE_LOADING,
  INCOME_RANGE_LOADED,
  INCOME_RANGE_LOAD_ERROR,
  INCOME_RANGE_CREATE_LOADING,
  INCOME_RANGE_CREATE_LOAD_ERROR,
  INCOME_RANGE_EDIT_LOADING,
  INCOME_RANGE_EDIT_COMPLETE,
  INCOME_RANGE_EDIT_LOAD_ERROR,
  INCOME_RANGE_DELETE_LOADING,
  INCOME_RANGE_DELETE_COMPLETE,
  INCOME_RANGE_DELETE_LOAD_ERROR,
  INCOME_RANGE_CREATE_COMPLETE,
} from "../../actions/registry/registry.income_range.actions";

const reducer = {
  /*
   * INCOME_RANGE LIST
   */
  [INCOME_RANGE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [INCOME_RANGE_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [INCOME_RANGE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * INCOME_RANGE CREATE
   */
  [INCOME_RANGE_CREATE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [INCOME_RANGE_CREATE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [INCOME_RANGE_CREATE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * INCOME_RANGE EDIT
   */
  [INCOME_RANGE_EDIT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [INCOME_RANGE_EDIT_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [INCOME_RANGE_EDIT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * INCOME_RANGE DELETE
   */
  [INCOME_RANGE_DELETE_LOADING]: (state) => ({
    ...state,
    income_range: { ...state.income_range, loading: true },
  }),
  [INCOME_RANGE_DELETE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [INCOME_RANGE_DELETE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    income_range: { ...state.income_range, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
