import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  SOURCE_LOADING,
  SOURCE_LOADED,
  SOURCE_LOAD_ERROR,
  SOURCE_CREATE_LOADING,
  SOURCE_CREATE_LOAD_ERROR,
  SOURCE_EDIT_LOADING,
  SOURCE_EDIT_COMPLETE,
  SOURCE_EDIT_LOAD_ERROR,
  SOURCE_DELETE_LOADING,
  SOURCE_DELETE_COMPLETE,
  SOURCE_DELETE_LOAD_ERROR,
  SOURCE_CREATE_COMPLETE,
} from "../../actions/registry/registry.source.actions";

const reducer = {
  /*
   * SOURCE LIST
   */
  [SOURCE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SOURCE_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [SOURCE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SOURCE CREATE
   */
  [SOURCE_CREATE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SOURCE_CREATE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SOURCE_CREATE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SOURCE EDIT
   */
  [SOURCE_EDIT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SOURCE_EDIT_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SOURCE_EDIT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SOURCE DELETE
   */
  [SOURCE_DELETE_LOADING]: (state) => ({
    ...state,
    source: { ...state.source, loading: true },
  }),
  [SOURCE_DELETE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SOURCE_DELETE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    source: { ...state.source, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
