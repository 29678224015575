import React from "react";
import _ from "lodash";
import getUuidByString from "uuid-by-string";
import IdleTimer from "react-idle-timer";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import firebase from "firebase";

import { get } from "./libs/helpers/ioc";
import routes from "./routes";

import {
  getFromLocalStorage,
  setOnLocalStorage,
} from "./libs/helpers/localstorage";

import history from "./store/history";
import Router from "./libs/router";

import Loading from "./components/Loading/Loading";
import ErrorBoundary from "./libs/ErrorBoundary";
import { setSidebarCollapsed } from "./actions/app.actions";

import "./App.scss";
import "./App.less";
import Config from "./Config";
import { updateUserInfo } from "./actions/auth.actions";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    // this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  componentDidMount() {
    const { setSidebarCollapsed, updateUserInfo } = this.props;
    if (!_.has(getFromLocalStorage(), "app-run-id")) {
      const uuid = `${new Date().getTime()}-${getUuidByString(
        [
          navigator.platform,
          navigator.appVersion || navigator.userAgent,
          new Date().getTime(),
        ].join(" ")
      )}`;
      setOnLocalStorage({ "app-run-id": uuid });
    }
    setOnLocalStorage({
      "app-launch-count":
        _.get(getFromLocalStorage(), "app-launch-count", 0) + 1,
    });
    if (window.screen.width < 1024) {
      setSidebarCollapsed(true);
    }
    // Initialize Firebase on App Mount
    if (!(firebase.apps || []).length) {
      firebase.initializeApp(Config.firebaseConfig);
    }
    // Setting Firebase listener
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        console.log("AUTH", user);
        updateUserInfo(user.toJSON());
      } else {
        // No user is signed in.
        console.log("AUTH");
      }
    });
  }

  componentDidUpdate() {
    if (!_.has(getFromLocalStorage(), "app-run-id")) {
      const uuid = `${new Date().getTime()}-${getUuidByString(
        [
          navigator.platform,
          navigator.appVersion || navigator.userAgent,
          new Date().getTime(),
        ].join(" ")
      )}`;
      setOnLocalStorage({ "app-run-id": uuid });
    }
  }

  componentDidCatch(error, info) {
    this.setState({ caughtError: true });
    console.log("[ERROR Catched]", error, info);
  }

  render() {
    const { authorized, user, loading } = this.props;
    const permissions = get("scopes");
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          // onAction={this.onAction}
          debounce={250}
          timeout={90000}
        />
        {loading && !user && <Loading />}
        {/* {(user && (
          <h3>
            Hi {user.firstName} {user.lastName}!
          </h3>
        )) || <h3>Please wait... </h3>} */}
        {/*<code>{(user && JSON.stringify(user)) || ""}</code>*/}
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <Router
              routes={routes}
              authorized={authorized}
              OLD_permissions={permissions}
            />
          </ErrorBoundary>
        </ConnectedRouter>
      </>
    );
  }

  // _onAction(e) {
  //   console.log('user did something', e);
  // }

  _onActive(e) {
    console.log("user is active", e);
    console.log("time remaining", this.idleTimer.getRemainingTime());
    // userInfo();
    // getCuboidListAction();
  }

  _onIdle(e) {
    //   console.log('user is idle', e);
    console.log("last active", this.idleTimer.getLastActiveTime());
  }
}

const mapStateToProps = ({ auth }) => ({
  /* Sets the authorized flag on the routes object if an access_token is available */
  // authorized: false
  authorized: !!auth.user && !!auth.token,
  loading: auth.loading,
  user: auth.user,
});

export default connect(mapStateToProps, {
  setSidebarCollapsed,
  updateUserInfo,
})(App);
