import {
  createInvestmentRoundApi,
  deleteInvestmentRoundApi,
  editInvestmentRoundApi,
  getInvestmentRoundApi,
} from "../api/investmentRound.api";
import callApi from "../libs/helpers/callApi";

export const COMPANY_INVESTMENT_ROUND_LOADING =
  "COMPANY_INVESTMENT_ROUND_LOADING";
export const COMPANY_INVESTMENT_ROUND_LOADED =
  "COMPANY_INVESTMENT_ROUND_LOADED";
export const COMPANY_INVESTMENT_ROUND_LOAD_ERROR =
  "COMPANY_INVESTMENT_ROUND_LOAD_ERROR";

export const getCompanyInvestmentRoundAction = callApi({
  apiCall: (p) => getInvestmentRoundApi(p),
  secured: true,
  startAction: COMPANY_INVESTMENT_ROUND_LOADING,
  successAction: COMPANY_INVESTMENT_ROUND_LOADED,
  errorAction: COMPANY_INVESTMENT_ROUND_LOAD_ERROR,
});

export const CREATE_INVESTMENT_ROUND_LOADING =
  "CREATE_INVESTMENT_ROUND_LOADING";
export const CREATE_INVESTMENT_ROUND_LOADED = "CREATE_INVESTMENT_ROUND_LOADED";
export const CREATE_INVESTMENT_ROUND_LOAD_ERROR =
  "CREATE_INVESTMENT_ROUND_LOAD_ERROR";

export const createInvestmentRoundAction = callApi({
  apiCall: (p) => createInvestmentRoundApi(p),
  secured: true,
  startAction: CREATE_INVESTMENT_ROUND_LOADING,
  successAction: CREATE_INVESTMENT_ROUND_LOADED,
  errorAction: CREATE_INVESTMENT_ROUND_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params }) => {
    dispatch(
      getCompanyInvestmentRoundAction({
        id: params.data.companyId,
      })
    );
  },
});

export const EDIT_INVESTMENT_ROUND_LOADING = "EDIT_INVESTMENT_ROUND_LOADING";
export const EDIT_INVESTMENT_ROUND_LOADED = "EDIT_INVESTMENT_ROUND_LOADED";
export const EDIT_INVESTMENT_ROUND_LOAD_ERROR =
  "EDIT_INVESTMENT_ROUND_LOAD_ERROR";

export const editInvestmentRoundAction = callApi({
  apiCall: (p) => editInvestmentRoundApi(p),
  secured: true,
  startAction: EDIT_INVESTMENT_ROUND_LOADING,
  successAction: EDIT_INVESTMENT_ROUND_LOADED,
  errorAction: EDIT_INVESTMENT_ROUND_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params }) => {
    dispatch(
      getCompanyInvestmentRoundAction({
        id: params.data.companyId,
      })
    );
  },
});

export const DELETE_INVESTMENT_ROUND_LOADING =
  "DELETE_INVESTMENT_ROUND_LOADING";
export const DELETE_INVESTMENT_ROUND_LOADED = "DELETE_INVESTMENT_ROUND_LOADED";
export const DELETE_INVESTMENT_ROUND_LOAD_ERROR =
  "DELETE_INVESTMENT_ROUND_LOAD_ERROR";

export const deleteInvestmentRoundAction = callApi({
  apiCall: (p) => deleteInvestmentRoundApi(p),
  secured: true,
  startAction: DELETE_INVESTMENT_ROUND_LOADING,
  successAction: DELETE_INVESTMENT_ROUND_LOADED,
  errorAction: DELETE_INVESTMENT_ROUND_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params }) => {
    dispatch(
      getCompanyInvestmentRoundAction({
        id: params.companyId,
      })
    );
  },
});
