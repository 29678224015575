import React, { useState, useEffect } from "react";
import { Button, Popconfirm, Row, Col, Spin } from "antd";
import { PlusOutlined, SaveOutlined, EditOutlined } from "@ant-design/icons";
import Localize from "../Localized/Localize.comp";

import "./DetailPane.scss";
import { renderDetailItem, checkIfNeedsRender } from "./_DetailPane.helpers";
import { isEmpty, isEqual, toUpper, forEach, get, isNil } from "lodash";

import { ReactComponent as EmptyIcon } from "./empty.svg";
import { translateSchemaKey, turnKeyIntoLabel } from "../../libs/utils";
import Form from "../Form/Form";
import Led from "../Led/Led";
import Stepper from "../Stepper/Stepper";
import Address from "../Address/Address";

const viewOptionsDefault = {
  cols: 3,
  displayAs: "GRID", // or MASONRY
  hideToolbar: false,
  noShadow: false,
  noBorder: false,
};

const Tile = ({ type = "GRID", children }) =>
  type === "GRID" ? (
    <Row>{children}</Row>
  ) : (
    <div className="masonry">{children}</div>
  );

export { Tile };

export default ({
  title,
  component,
  creatingComponent = null,
  data = {},
  entitySchema = [],
  editing = false,
  creating = false,
  disableEditing = false,
  disableCreation = false,
  customPlaceholders = {},
  toggleEditMode,
  toggleCreateMode,
  loading = false,
  loadingDescription = null,
  onSave = (data) => data,
  onEdit = (data) => data,
  onChange = (data) => data,
  onBlur = (key, value, data) => key && value && data,
  imageUploadOptions = {
    headers: null,
    url: null,
    callback: null,
    disabled: !editing,
    onDelete: null,
  },
  viewOptions = {
    ...viewOptionsDefault,
  },
  customRendererForType = {},
  customPropsForKey = {},
  extra,
  leftSwitch,
  stepper = false,
  stepData,
  address = false,
  renderType = null,
  schemaType = null,
  hideSave = false,
  sideComponent = null,
  disableSave = false,
}) => {
  const [dataForm, setDataForm] = useState(data);
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [formIsCompleted, setFormIsCompleted] = useState(!isEmpty(data));
  const [requiredFields, setRequiredFields] = useState([]);

  // compose viewOptions
  viewOptions = {
    ...viewOptionsDefault,
    ...viewOptions,
  };

  useEffect(() => {
    if (!creating && !isEmpty(data) && !editing) {
      setDataForm(data);
      setFormIsCompleted(true);
    }
  }, [data]);

  useEffect(() => {
    setDataForm(data);
    const reqArr = [];
    entitySchema.map((schema) => {
      if (schema.required) reqArr.push(schema.key);
    });
    setRequiredFields(reqArr);
  }, [creating, editing]);

  const renderStepper = () => {
    const steps = [];
    forEach(stepData, (data) => {
      let isNeeded = false;
      forEach(data, (schema) => {
        if (checkIfNeedsRender(schema, renderType || "CREATE")) {
          isNeeded = true;
        }
      });

      if (data.length > 0 && isNeeded) {
        steps.push({
          title: Localize(
            `PAGES.COMPANIES.DETAIL.SECTIONS.GENERAL.${toUpper(
              data[0].section
            )}`
          ),
          content: (
            <>
              {data[0].section === "address" ? (
                <Col span={24} className="animation fadein">
                  <Tile type={viewOptions.displayAs}>
                    <Address
                      style={
                        viewOptions.displayAs === "GRID" && {
                          padding: "10px",
                          minWidth: `${Math.floor(100 / viewOptions.cols)}%`,
                        }
                      }
                      searchStyle={
                        viewOptions.displayAs === "GRID" && {
                          padding: "10px",
                          minWidth: `${Math.floor(100 / viewOptions.cols)}%`,
                        }
                      }
                      dataForm={dataForm}
                      onChange={(newdata) => setDataForm(newdata)}
                      searchPlaceholder={Localize("COMMON.FIND_ADDRESS")}
                      formattedAddressPlaceholder={customPlaceholders?.address}
                      editing={true}
                    />
                  </Tile>
                </Col>
              ) : (
                <Col span={24} className="animation fadein">
                  <Form
                    id={`${data[0].section}-form`}
                    name={`${data[0].section}-form`}
                    initialValues={dataForm}
                    required={requiredFields}
                    onChange={({ values, errors, completed }) => {
                      setDataForm({ ...dataForm, ...values, ...errors });
                      setFormHasErrors(!isEmpty(errors));
                      setFormIsCompleted(completed);
                      typeof onChange === "function" &&
                        onChange({ ...dataForm, ...values, ...errors });
                    }}
                  >
                    {({ values, errors, completed, updateValue }) => (
                      <Tile type={viewOptions.displayAs}>
                        {data.map(
                          (schema, i) =>
                            checkIfNeedsRender(
                              schema,
                              renderType || "CREATE"
                            ) && (
                              <Col
                                key={`${i}-${schema.key}`}
                                style={
                                  viewOptions.displayAs === "GRID" && {
                                    padding: 10,
                                    minWidth:
                                      customPropsForKey &&
                                      customPropsForKey[schema.key] &&
                                      customPropsForKey[schema.key].cols
                                        ? `${Math.floor(
                                            customPropsForKey[schema.key].cols *
                                              (100 / viewOptions.cols)
                                          )}%`
                                        : `${Math.floor(
                                            100 / viewOptions.cols
                                          )}%`,
                                  }
                                }
                              >
                                <p>
                                  <b>
                                    {translateSchemaKey(
                                      schema.key,
                                      schemaType
                                    ) ||
                                      turnKeyIntoLabel(
                                        schema.label || schema.key
                                      )}
                                    :{" "}
                                    {schema.required &&
                                    (creating || editing) ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : (
                                      ""
                                    )}
                                  </b>
                                </p>
                                {renderDetailItem({
                                  idx: `${i}-${schema.key}`,
                                  item: dataForm[schema.key],
                                  error: errors && errors[schema.key],
                                  placeholder:
                                    schema.key === "tel"
                                      ? customPlaceholders.tel
                                      : null,
                                  itemSchema: schema,
                                  disabled: !(creating && !disableCreation),
                                  onChange: (key, value, regex) => {
                                    updateValue({ key, value, regex });
                                  },
                                  onBlur: (key, value) =>
                                    typeof onBlur === "function" &&
                                    key === schema.key &&
                                    onBlur(key, value, dataForm),
                                  imageUploadOptions,
                                  customRenderer:
                                    // !isNil(
                                    //   customRendererForType[schema.type]
                                    // ) &&
                                    // ((props) =>
                                    //   React.cloneElement(
                                    //     customRendererForType[schema.type],
                                    //     props
                                    //   )),
                                    typeof customRendererForType[
                                      schema.type
                                    ] === "function"
                                      ? (props) =>
                                          customRendererForType[schema.type](
                                            props
                                          )
                                      : null,
                                })}
                              </Col>
                            )
                        )}
                      </Tile>
                    )}
                  </Form>
                </Col>
              )}
            </>
          ),
        });
      }
    });

    return (
      <Stepper
        steps={steps}
        status={formHasErrors || !formIsCompleted ? "error" : ""}
        save={() => onSave(dataForm)}
      />
    );
  };

  const colSpan = sideComponent ? 16 : 24;

  return (
    <Spin spinning={loading} tip={loadingDescription}>
      <Row
        className={`detail-pane ${
          viewOptions.noShadow ? "" : "box-shadow-small"
        } ${viewOptions.noBorder ? "" : "soft-border"} animation fadein-down`}
      >
        {!isEmpty(data) || component ? (
          <>
            {!viewOptions.hideToolbar && (
              <Col span={24}>
                <Row justify="space-between" align="middle" className="toolbar">
                  <Col span="auto">
                    <h2>
                      <b>{title || ""}</b>
                      {(editing || creating) && formHasErrors && (
                        <span className="form-error-led">
                          <Led on={true} type="error" />
                          {Localize("FORMS.HAS_ERRORS")}
                        </span>
                      )}
                    </h2>
                    {leftSwitch}
                  </Col>
                  <Col span="auto">
                    {extra}
                    {editing || creating ? (
                      <>
                        <Button
                          type="link"
                          onClick={() => {
                            setDataForm(data);
                            setFormHasErrors(false);
                            setFormIsCompleted(!isEmpty(data));
                            if (
                              typeof toggleEditMode === "function" &&
                              editing
                            ) {
                              toggleEditMode(!editing);
                            } else if (
                              typeof toggleCreateMode === "function" &&
                              creating
                            ) {
                              toggleCreateMode(!creating);
                            }
                          }}
                        >
                          {Localize("COMMON.CANCEL")}
                        </Button>
                        {!stepper && !creatingComponent && !hideSave && (
                          <Popconfirm
                            placement="left"
                            title={Localize("CONFIRM.SAVE_EDIT")}
                            onConfirm={() => {
                              if (formIsCompleted && !formHasErrors) {
                                if (editing) {
                                  if (typeof toggleEditMode === "function") {
                                    toggleEditMode(!editing);
                                  }
                                  if (typeof onEdit === "function") {
                                    onEdit(dataForm);
                                  }
                                } else if (creating) {
                                  if (typeof toggleCreateMode === "function") {
                                    toggleCreateMode(!editing);
                                  }
                                  if (typeof onSave === "function") {
                                    onSave(dataForm);
                                  }
                                }
                              }
                            }}
                            disabled={
                              !formIsCompleted ||
                              formHasErrors ||
                              loading ||
                              disableSave
                            }
                            okText={Localize("COMMON.YES")}
                            cancelText={Localize("COMMON.NO")}
                          >
                            <Button
                              type="primary"
                              disabled={
                                !formIsCompleted ||
                                formHasErrors ||
                                loading ||
                                disableSave
                              }
                            >
                              <SaveOutlined /> {Localize("COMMON.SAVE")}
                            </Button>
                          </Popconfirm>
                        )}
                      </>
                    ) : (
                      <>
                        {!disableEditing && (
                          <Button
                            onClick={() => {
                              typeof toggleEditMode === "function" &&
                                toggleEditMode(!editing) &&
                                setDataForm(data);
                            }}
                            disabled={loading}
                          >
                            <EditOutlined /> {Localize("COMMON.EDIT")}
                          </Button>
                        )}
                        {!disableCreation && (
                          <Button
                            type="primary"
                            disabled={loading}
                            onClick={() => {
                              typeof toggleCreateMode === "function" &&
                                toggleCreateMode(!creating);
                            }}
                          >
                            <PlusOutlined /> {Localize("COMMON.CREATE_NEW")}
                          </Button>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            )}
            {sideComponent && <Col span={7}>{sideComponent}</Col>}
            {creating && !stepper && !creatingComponent && (
              <Col span={colSpan} className="animation fadein">
                <Form
                  name="form-create"
                  initialValues={dataForm}
                  required={requiredFields}
                  onChange={({ values, errors, completed }) => {
                    setDataForm({
                      ...dataForm,
                      ...values,
                      ...errors,
                    });
                    setFormHasErrors(!isEmpty(errors));
                    setFormIsCompleted(completed);
                    typeof onChange === "function" &&
                      onChange({ ...dataForm, ...values, ...errors });
                  }}
                >
                  {({ values, errors, completed, updateValue }) => (
                    <Row
                      className={[
                        "creation-box",
                        [creating ? "open" : "closed"],
                      ].join(" ")}
                    >
                      {entitySchema.map((schema, i) => {
                        if (address && schema.key === "address") {
                          return (
                            <Address
                              style={
                                viewOptions.displayAs === "GRID" && {
                                  padding: "10px",
                                  minWidth: `${Math.floor(
                                    100 / viewOptions.cols
                                  )}%`,
                                }
                              }
                              searchStyle={
                                viewOptions.displayAs === "GRID" && {
                                  padding: "10px",
                                  minWidth: `${Math.floor(
                                    100 / viewOptions.cols
                                  )}%`,
                                }
                              }
                              dataForm={dataForm}
                              onChange={(newdata) => setDataForm(newdata)}
                              searchPlaceholder={Localize(
                                "COMMON.FIND_ADDRESS"
                              )}
                              editing={true}
                              showNote={false}
                            />
                          );
                        }
                        return (
                          checkIfNeedsRender(
                            schema,
                            renderType || "CREATE"
                          ) && (
                            <Col
                              key={i}
                              style={{
                                padding: 10,
                                minWidth:
                                  customPropsForKey &&
                                  customPropsForKey[schema.key] &&
                                  customPropsForKey[schema.key].cols
                                    ? `${Math.floor(
                                        customPropsForKey[schema.key].cols *
                                          (100 / viewOptions.cols)
                                      )}%`
                                    : `${Math.floor(100 / viewOptions.cols)}%`,
                              }}
                            >
                              <p>
                                <b>
                                  {translateSchemaKey(schema.key, schemaType) ||
                                    turnKeyIntoLabel(
                                      schema.label || schema.key
                                    )}
                                  :{" "}
                                  {schema.required && (creating || editing) ? (
                                    <span style={{ color: "red" }}>*</span>
                                  ) : (
                                    ""
                                  )}
                                </b>
                              </p>
                              {renderDetailItem({
                                idx: i,
                                item: dataForm[schema.key],
                                error: errors && errors[schema.key],
                                itemSchema: schema,
                                disabled: !(creating && !disableCreation),
                                onChange: (key, value, regex) =>
                                  updateValue({ key, value, regex }),
                                onBlur: (key, value) =>
                                  typeof onBlur === "function" &&
                                  key === schema.key &&
                                  onBlur(key, value, dataForm),
                                imageUploadOptions,
                                customRenderer:
                                  // !isNil(customRendererForType[schema.type]) &&
                                  // ((props) =>
                                  //   React.cloneElement(
                                  //     customRendererForType[schema.type],
                                  //     props
                                  //   )),
                                  typeof customRendererForType[schema.type] ===
                                  "function"
                                    ? (props) =>
                                        customRendererForType[schema.type](
                                          props
                                        )
                                    : null,
                              })}
                            </Col>
                          )
                        );
                      })}
                    </Row>
                  )}
                </Form>
              </Col>
            )}
            {creating && stepper && !creatingComponent && (
              <Col span={colSpan}>{renderStepper()}</Col>
            )}
            {creating &&
              creatingComponent &&
              React.cloneElement(creatingComponent, { toggleCreateMode })}
            {!creating &&
              (!component ? (
                <Col span={colSpan}>
                  <Form
                    name="form-edit"
                    initialValues={dataForm}
                    required={requiredFields}
                    onChange={({ values, errors, completed }) => {
                      setDataForm({ ...dataForm, ...values, ...errors });
                      setFormHasErrors(!isEmpty(errors));
                      setFormIsCompleted(completed);
                      typeof onChange === "function" &&
                        onChange({ ...dataForm, ...values, ...errors });
                    }}
                  >
                    {({ values, errors, completed, updateValue }) => (
                      <Tile type={viewOptions.displayAs}>
                        {entitySchema.map((schema, i) => {
                          if (address && schema.key === "address") {
                            return (
                              <Address
                                style={
                                  viewOptions.displayAs === "GRID" && {
                                    padding: "10px",
                                    minWidth: `${Math.floor(
                                      100 / viewOptions.cols
                                    )}%`,
                                  }
                                }
                                searchStyle={
                                  viewOptions.displayAs === "GRID" && {
                                    padding: "10px",
                                    minWidth: `${Math.floor(
                                      100 / viewOptions.cols
                                    )}%`,
                                  }
                                }
                                formattedAddressPlaceholder={
                                  customPlaceholders?.address
                                }
                                dataForm={dataForm}
                                onChange={(newdata) => setDataForm(newdata)}
                                searchPlaceholder={Localize(
                                  "COMMON.FIND_ADDRESS"
                                )}
                                editing={editing}
                                showNote={false}
                              />
                            );
                          }
                          return (
                            checkIfNeedsRender(
                              schema,
                              renderType || "CREATE"
                            ) && (
                              <Col
                                key={i}
                                style={
                                  viewOptions.displayAs === "GRID" && {
                                    padding: 10,
                                    minWidth:
                                      customPropsForKey &&
                                      customPropsForKey[schema.key] &&
                                      customPropsForKey[schema.key].cols
                                        ? `${Math.floor(
                                            customPropsForKey[schema.key].cols *
                                              (100 / viewOptions.cols)
                                          )}%`
                                        : `${Math.floor(
                                            100 / viewOptions.cols
                                          )}%`,
                                  }
                                }
                              >
                                <p>
                                  <b>
                                    {translateSchemaKey(
                                      schema.key,
                                      schemaType
                                    ) ||
                                      turnKeyIntoLabel(
                                        schema.label || schema.key
                                      )}
                                    :{" "}
                                    {schema.required &&
                                    (creating || editing) ? (
                                      <span style={{ color: "red" }}>*</span>
                                    ) : (
                                      ""
                                    )}
                                  </b>
                                </p>
                                {renderDetailItem({
                                  idx: i,
                                  item: dataForm[schema.key],
                                  // item: dataForm[schema.key] || data[schema.key],
                                  error: errors && errors[schema.key],
                                  itemSchema: schema,
                                  placeholder:
                                    schema.key === "tel"
                                      ? customPlaceholders.tel
                                      : null,
                                  disabled: !(editing && !disableEditing),
                                  onChange: (key, value, regex) => {
                                    updateValue({ key, value, regex });
                                  },
                                  onBlur: (key, value) =>
                                    typeof onBlur === "function" &&
                                    key === schema.key &&
                                    onBlur(key, value, dataForm),
                                  imageUploadOptions,
                                  customRenderer:
                                    typeof customRendererForType[
                                      schema.type
                                    ] === "function"
                                      ? (props) =>
                                          customRendererForType[schema.type](
                                            props
                                          )
                                      : null,
                                })}
                              </Col>
                            )
                          );
                        })}
                      </Tile>
                    )}
                  </Form>
                </Col>
              ) : (
                <Col span={colSpan} style={{ padding: 10 }}>
                  {component}
                </Col>
              ))}
          </>
        ) : (
          <>
            <Col span={24} className="toolbar">
              <h2>
                <b>{title || ""}</b>
              </h2>
            </Col>
            <Col span={24}>
              <div style={{ textAlign: "center", opacity: 0.8 }}>
                <EmptyIcon />
                {!loading && (
                  <p style={{ opacity: 0.6 }}>{Localize("COMMON.NO_DATA")}</p>
                )}
              </div>
            </Col>
          </>
        )}
      </Row>
    </Spin>
  );
};
