import callApi from "../../libs/helpers/callApi";
import {
  createFlatV2EntityApi,
  deleteFlatV2EntityApi,
  editFlatV2EntityApi,
  getFlatV2EntityListApi,
} from "../../api/flat.api";
import { loadDictionaries } from "../../locales";

export const SECONDARY_GROUP_LOADING = "SECONDARY_GROUP_LOADING";
export const SECONDARY_GROUP_LOADED = "SECONDARY_GROUP_LOADED";
export const SECONDARY_GROUP_LOAD_ERROR = "SECONDARY_GROUP_LOAD_ERROR";

// SECONDARY_GROUP GET LIST
export const getSecondaryGroupAction = callApi({
  apiCall: (p) => getFlatV2EntityListApi({ ...p, entity: "group" }),
  secured: true,
  startAction: SECONDARY_GROUP_LOADING,
  successAction: SECONDARY_GROUP_LOADED,
  errorAction: SECONDARY_GROUP_LOAD_ERROR,
});

export const SECONDARY_GROUP_CREATE_LOADING = "SECONDARY_GROUP_CREATE_LOADING";
export const SECONDARY_GROUP_CREATE_COMPLETE =
  "SECONDARY_GROUP_CREATE_COMPLETE";
export const SECONDARY_GROUP_CREATE_LOAD_ERROR =
  "SECONDARY_GROUP_CREATE_LOAD_ERROR";

// SECONDARY_GROUP CREATE
export const createSecondaryGroupAction = callApi({
  apiCall: (p) => createFlatV2EntityApi(p),
  secured: true,
  startAction: SECONDARY_GROUP_CREATE_LOADING,
  successAction: SECONDARY_GROUP_CREATE_COMPLETE,
  errorAction: SECONDARY_GROUP_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getSecondaryGroupAction());
    loadDictionaries();
  },
});

export const SECONDARY_GROUP_EDIT_LOADING = "SECONDARY_GROUP_EDIT_LOADING";
export const SECONDARY_GROUP_EDIT_COMPLETE = "SECONDARY_GROUP_EDIT_COMPLETE";
export const SECONDARY_GROUP_EDIT_LOAD_ERROR =
  "SECONDARY_GROUP_EDIT_LOAD_ERROR";

// SECONDARY_GROUP EDIT
export const editSecondaryGroupAction = callApi({
  apiCall: (p) => editFlatV2EntityApi(p),
  secured: true,
  startAction: SECONDARY_GROUP_EDIT_LOADING,
  successAction: SECONDARY_GROUP_EDIT_COMPLETE,
  errorAction: SECONDARY_GROUP_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getSecondaryGroupAction());
    loadDictionaries();
  },
});

export const SECONDARY_GROUP_DELETE_LOADING = "SECONDARY_GROUP_DELETE_LOADING";
export const SECONDARY_GROUP_DELETE_COMPLETE =
  "SECONDARY_GROUP_DELETE_COMPLETE";
export const SECONDARY_GROUP_DELETE_LOAD_ERROR =
  "SECONDARY_GROUP_DELETE_LOAD_ERROR";

// SECONDARY_GROUP DELETE
export const deleteSecondaryGroupAction = callApi({
  apiCall: (p) => deleteFlatV2EntityApi(p),
  secured: true,
  startAction: SECONDARY_GROUP_DELETE_LOADING,
  successAction: SECONDARY_GROUP_DELETE_COMPLETE,
  errorAction: SECONDARY_GROUP_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getSecondaryGroupAction());
  },
});
