import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Modal, Popconfirm, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";

import EditOutlined from "@ant-design/icons/es/icons/EditOutlined";
import { isEqual, orderBy } from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import {
  createTreeSectorDetailAction,
  deleteTreeSectorAction,
  editTreeSectorDetailAction,
  getTreeSectorAction,
  mergeSectorAction,
} from "../../actions/registry/registry.sector.actions";
import DetailPane from "../../components/DetailPane/DetailPane";
import TreeView from "../../components/Tree/TreeView";
import Tree from "../../components/Tree/Tree";
import Localize from "../../libs/localization";
import { checkForSpecialChar, translateRegistryKey } from "../../libs/utils";

export const openSectorMergedNotification = () => {
  notification.open({
    message: `${Localize("PAGES.SETTINGS.MERGE.SECTOR_NOTIFICATION_DESC")}`,
    onClick: () => {},
  });
};

const Sectors = (props) => {
  const { title } = props;
  const [current, setCurrent] = useState(null);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [setorToMerge, setSetorToMerge] = useState(null);
  const [finalSector, setFinalSector] = useState(null);
  const [list, setList] = useState([]);
  const [backup, setBackup] = useState([]);
  const [itTranslate, setItTranslate] = useState(null);
  const [enTranslate, setEnTranslate] = useState(null);
  const [frTranslate, setFrTranslate] = useState(null);
  const [esTranslate, setEsTranslate] = useState(null);
  const [errorMessage, setErrorMessage] = useState();

  const sectors = useSelector(
    (state) => state.sector.data,
    (a, b) => isEqual(a, b)
  );
  const loading = useSelector((state) => state.sector.loading);
  const dispatch = useDispatch();

  const createTranslationData = () => {
    let translations = {};
    if (itTranslate) translations.it = { key: itTranslate.label };
    if (enTranslate) translations.en = { key: enTranslate.label };
    if (frTranslate) translations.fr = { key: frTranslate.label };
    if (esTranslate) translations.es = { key: esTranslate.label };
    return translations;
  };

  useEffect(() => {
    if (sectors.length == 0) {
      dispatch(getTreeSectorAction());
    }
  }, [sectors]);

  useEffect(() => {
    if (current) {
      if (translateRegistryKey(current.key, "Sector", "it"))
        setItTranslate({
          label: translateRegistryKey(current.key, "Sector", "it"),
        });
      if (translateRegistryKey(current.key, "Sector", "en"))
        setEnTranslate({
          label: translateRegistryKey(current.key, "Sector", "en"),
        });
      if (translateRegistryKey(current.key, "Sector", "fr"))
        setFrTranslate({
          label: translateRegistryKey(current.key, "Sector", "fr"),
        });
      if (translateRegistryKey(current.key, "Sector", "es"))
        setEsTranslate({
          label: translateRegistryKey(current.key, "Sector", "es"),
        });
    } else {
      setItTranslate(null);
      setEnTranslate(null);
      setFrTranslate(null);
      setEsTranslate(null);
    }
  }, [current]);

  return (
    <>
      <DetailPane
        extra={
          <div>
            <Button
              style={{ marginRight: "12px" }}
              type="primary"
              disabled={loading}
              onClick={() => {
                setShowMergeModal(true);
              }}
            >
              {Localize("PAGES.SETTINGS.MERGE.MERGE_SECTORS_BTN")}
            </Button>
            <Button
              type="primary"
              disabled={loading}
              onClick={() => {
                setCurrent({ title: "", path: "/", isNew: true });
              }}
            >
              <PlusOutlined /> {Localize("COMMON.CREATE_NEW")}
            </Button>
          </div>
        }
        title={title}
        component={
          <div>
            <TreeView
              entityList={orderBy(sectors, "key")}
              entityName="Sector"
              onSelect={(data) => setCurrent(data)}
              moveCallback={editTreeSectorDetailAction}
            />
          </div>
        }
        loading={loading}
        disableEditing={true}
        disableCreation={true}
      />
      <Modal
        title={Localize(
          current && current.isNew ? "COMMON.CREATE_NEW" : "COMMON.EDIT"
        )}
        centered
        visible={current !== null}
        onCancel={() => setCurrent(null)}
        footer={
          <Row justify={current && current.isNew ? "end" : "space-between"}>
            {current && !current.isNew && (
              <>
                {current.isActive ? (
                  <Button
                    danger
                    type="button"
                    onClick={() => {
                      dispatch(
                        deleteTreeSectorAction({
                          entity: "Sector",
                          id: escape(current.key),
                        })
                      );
                      setCurrent(null);
                    }}
                  >
                    {Localize("COMMON.DEACTIVATE")}
                  </Button>
                ) : (
                  <Button
                    type="button"
                    style={{ color: "green" }}
                    onClick={() => {
                      dispatch(
                        editTreeSectorDetailAction({
                          id: escape(current.key),
                          entity: "Sector",
                          data: {
                            key: current.label,
                            path: current.path,
                            active: true,
                            translations: createTranslationData(),
                          },
                        })
                      );
                      setCurrent(null);
                    }}
                  >
                    {Localize("COMMON.ACTIVATE")}
                  </Button>
                )}
              </>
            )}
            <Col>
              <Button
                type="button"
                onClick={() => {
                  setCurrent(null);
                }}
              >
                {Localize("COMMON.CANCEL")}
              </Button>
              <Button
                type="primary"
                disabled={errorMessage}
                onClick={() => {
                  if (current && current.isNew) {
                    dispatch(
                      createTreeSectorDetailAction({
                        entity: "Sector",
                        data: {
                          key: current.label,
                          path: "/",
                          translations: createTranslationData(),
                        },
                      })
                    );
                  } else {
                    dispatch(
                      editTreeSectorDetailAction({
                        id: escape(current.key),
                        entity: "Sector",
                        data: {
                          key: current.label,
                          path: current.path,
                          translations: createTranslationData(),
                        },
                      })
                    );
                  }

                  setCurrent(null);
                }}
              >
                <EditOutlined /> {Localize("COMMON.SAVE")}
              </Button>
            </Col>
          </Row>
        }
      >
        {current && (
          <>
            <Input
              style={{ marginBottom: 12 }}
              placeholder="Key"
              value={current.label}
              type="text"
              onChange={(e) => {
                const checkInput = checkForSpecialChar(e.target.value);
                if (checkInput) {
                  setErrorMessage(Localize("ERROR.NO_SPECIAL_CHAR"));
                } else {
                  setErrorMessage(false);
                }
                setCurrent({ ...current, label: e.target.value });
              }}
            />
            <Input
              style={{ marginBottom: 12 }}
              placeholder={Localize("TRANSLATE.REGISTRY_IT_PLACEHOLDER")}
              value={itTranslate?.label}
              type="text"
              onChange={(e) => {
                setItTranslate({ ...itTranslate, label: e.target.value });
              }}
            />
            <Input
              style={{ marginBottom: 12 }}
              placeholder={Localize("TRANSLATE.REGISTRY_EN_PLACEHOLDER")}
              value={enTranslate?.label}
              type="text"
              onChange={(e) => {
                setEnTranslate({ ...enTranslate, label: e.target.value });
              }}
            />
            <Input
              style={{ marginBottom: 12 }}
              placeholder={Localize("TRANSLATE.REGISTRY_FR_PLACEHOLDER")}
              value={frTranslate?.label}
              type="text"
              onChange={(e) => {
                setFrTranslate({ ...frTranslate, label: e.target.value });
              }}
            />
            <Input
              placeholder={Localize("TRANSLATE.REGISTRY_ES_PLACEHOLDER")}
              value={esTranslate?.label}
              type="text"
              onChange={(e) => {
                setEsTranslate({ ...esTranslate, label: e.target.value });
              }}
            />
            <p style={{ color: "red" }}>{errorMessage}</p>
          </>
        )}
      </Modal>

      <Modal
        title={Localize("PAGES.SETTINGS.MERGE.MERGE_SECTORS_BTN")}
        centered
        visible={showMergeModal}
        onCancel={() => setShowMergeModal(false)}
        footer={
          <Row justify="end">
            <Col>
              <Button
                type="button"
                style={{ marginRight: "12px" }}
                onClick={() => {
                  setShowMergeModal(false);
                }}
              >
                {Localize("COMMON.CANCEL")}
              </Button>
              <Popconfirm
                placement="left"
                title={Localize("CONFIRM.SAVE_EDIT")}
                onConfirm={() => {
                  dispatch(
                    mergeSectorAction({
                      destinationId: finalSector[0],
                      sourceId: setorToMerge[0],
                    })
                  );
                  setFinalSector(null);
                  setSetorToMerge(null);
                  setShowMergeModal(false);
                }}
                okText={Localize("COMMON.YES")}
                cancelText={Localize("COMMON.NO")}
              >
                <Button
                  type="primary"
                  disabled={
                    !setorToMerge ||
                    !finalSector ||
                    !setorToMerge.length ||
                    !finalSector.length ||
                    isEqual(setorToMerge, finalSector)
                  }
                >
                  <EditOutlined /> {Localize("COMMON.SAVE")}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        }
      >
        <Row>
          <Col span={12} style={{ paddingRight: "5px" }}>
            <p>{Localize("PAGES.SETTINGS.MERGE.FIRST_SECTOR")}</p>
            <Tree
              treeCheckable={false}
              valueData={setorToMerge}
              datatree={orderBy(sectors, "key")}
              onChange={(key, value) => {
                setSetorToMerge(value);
              }}
              onSelect={(value, e) => {
                setSetorToMerge([value]);
              }}
            />
          </Col>
          <Col span={12} style={{ paddingRight: "5px" }}>
            <p>{Localize("PAGES.SETTINGS.MERGE.SECOND_SECTOR")}</p>
            <Tree
              treeCheckable={false}
              valueData={finalSector}
              datatree={orderBy(sectors, "key")}
              onChange={(key, value) => {
                setFinalSector(value);
              }}
              onSelect={(value, e) => {
                setFinalSector([value]);
              }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Sectors;
