import moment from "moment";
import { upperFirst, toUpper } from "lodash";
import { getFromLocalStorage } from "./helpers/localstorage";
import locales from "../locales";

export const getAddress = () =>
  `${global.location.protocol}//${global.location.host}/`;

// export const capitalizeFirstLetter = (string) =>
//   string.charAt(0).toUpperCase() + string.slice(1);

export const wait = (durationMs) =>
  new Promise((fulfill) => setTimeout(fulfill, durationMs));

export const refreshPage = () => {
  window.location.reload();
};

export const currencyDecimal = (value) => {
  return Number.parseFloat(value).toFixed(2).toString().concat(" €");
};

export const currencyNoDecimal = (value) => {
  return Number.parseFloat(value).toFixed(0).toString().concat("€");
};

export const isMobileDevice = () => window.screen.width < 767;

export const isTouchScreen = () =>
  !!("ontouchstart" in window || navigator.maxTouchPoints);

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollToId = (id) => {
  const element = document.getElementById(id);
  // if (element) element.scrollIntoView();
  if (element) {
    const top = element.offsetTop;
    window.scrollTo({ top });
  }
};

export const getAge = (birthdate) => moment().diff(moment(birthdate), "years");

export const turnKeyIntoLabel = (key) =>
  key && toTitleCase(key.replace(/(\_)/g, " "));

export const turnIntoErrorCode = (code) =>
  code && toUpper(code.replace(/[A-Za-z0-9]+/g, " "));

export const isEmptyOrSpaces = (str) => {
  return str === null || str.match(/^ *$/) !== null || str.length === 0;
};

export const truncate = (input, val = 20) =>
  input && input.length > val ? `${input.substring(0, val)}...` : input;

export const flagMap = {
  italy: "it",
  france: "fr",
  spain: "es",
  germany: "de",
  portugal: "pt",
  mexico: "mx",
  uk: "gb",
};

export const objToId = (keyList, obj) => {
  keyList.map((key) => {
    if (obj[key]) {
      obj[key] = obj[key].id;
    }
  });

  return obj;
};

export const checkForSpecialChar = (string) => {
  const specialChars = "<>@!#$£%^&*()_+[]{}?:;|'\\";
  for (let i = 0; i < specialChars.length; i++) {
    if (string.indexOf(specialChars[i]) > -1) {
      return true;
    }
  }
  return false;
};

export const translateSchemaKey = (key, entity) => {
  const tenant = getFromLocalStorage("lefacTenant")?.data;
  const lang = getFromLocalStorage("lang")?.data;
  if (key && tenant && lang) {
    // TODO: TOGLIERE XING QUANDO SI ANDRA IN PROD
    if (key === "xing")
      return turnKeyIntoLabel(
        locales[lang]?.SCHEMA?.DEFAULT?.CONTACT?.SALES_NAVIGATOR
      );
    if (
      entity &&
      locales[lang]?.SCHEMA?.[tenant]?.[toUpper(entity)]?.[toUpper(key)]
    )
      return turnKeyIntoLabel(
        locales[lang]?.SCHEMA?.[tenant]?.[toUpper(entity)]?.[toUpper(key)]
      );
    if (locales[lang]?.SCHEMA?.[tenant]?.DEFAULT?.[toUpper(key)])
      return turnKeyIntoLabel(
        locales[lang]?.SCHEMA?.[tenant]?.DEFAULT?.[toUpper(key)]
      );
    if (
      entity &&
      locales[lang]?.SCHEMA?.DEFAULT?.[toUpper(entity)]?.[toUpper(key)]
    )
      return turnKeyIntoLabel(
        locales[lang]?.SCHEMA?.DEFAULT?.[toUpper(entity)]?.[toUpper(key)]
      );
    if (locales[lang]?.SCHEMA?.DEFAULT?.DEFAULT?.[toUpper(key)])
      return turnKeyIntoLabel(
        locales[lang]?.SCHEMA?.DEFAULT?.DEFAULT?.[toUpper(key)]
      );
  }
};

export const translateRegistryOnView = (id, translateEntity, isFlat) => {
  const lang = getFromLocalStorage("lang")?.data;
  const type = isFlat ? "FLAT" : "TREE";
  const fieldName = `${translateEntity}/${id}`;

  return locales[lang]?.BACKEND?.ENTITIES?.[type]?.[fieldName]?.NAME;
};

export const translateRegistryKey = (id, entity, lang, isFlat) => {
  const type = isFlat ? "FLAT" : "TREE";
  const fieldName = `${entity}/${id}`;

  if (id && entity && lang) {
    return locales[lang]?.BACKEND?.ENTITIES?.[type]?.[fieldName]?.NAME;
  }
};

export const translateRegistryKeyFlatV2 = (
  id,
  entity,
  lang = getFromLocalStorage("lang")?.data
) => {
  const type = "FLATV2";
  const fieldName = `${entity}/${id}`;

  if (id && entity && lang) {
    return locales[lang]?.BACKEND?.ENTITIES?.[type]?.[fieldName]?.NAME;
  }
};

export const translateRegistryParent = (id, entity, lang) => {
  const fieldName = `${entity}/${id}`;

  if (id && entity && lang) {
    return locales[lang]?.BACKEND?.ENTITIES?.FLAT_PARENTS?.[fieldName]?.NAME;
  }
};

export const toTitleCase = (str) => {
  if (str)
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const currencyFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});
