import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const OTHER_CATEGORIES_LOADING = "OTHER_CATEGORIES_LOADING";
export const OTHER_CATEGORIES_LOADED = "OTHER_CATEGORIES_LOADED";
export const OTHER_CATEGORIES_LOAD_ERROR = "OTHER_CATEGORIES_LOAD_ERROR";

// OTHER CATEGORIES GET LIST
export const getOtherCategoriesAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "other_categories" }),
  secured: true,
  startAction: OTHER_CATEGORIES_LOADING,
  successAction: OTHER_CATEGORIES_LOADED,
  errorAction: OTHER_CATEGORIES_LOAD_ERROR,
});

export const OTHER_CATEGORIES_CREATE_LOADING =
  "OTHER_CATEGORIES_CREATE_LOADING";
export const OTHER_CATEGORIES_CREATE_COMPLETE =
  "OTHER_CATEGORIES_CREATE_COMPLETE";
export const OTHER_CATEGORIES_CREATE_LOAD_ERROR =
  "OTHER_CATEGORIES_CREATE_LOAD_ERROR";

// OTHER_CATEGORIES CREATE
export const createOtherCategoriesAction = callApi({
  apiCall: (p) => createFlatEntityApi(p),
  secured: true,
  startAction: OTHER_CATEGORIES_CREATE_LOADING,
  successAction: OTHER_CATEGORIES_CREATE_COMPLETE,
  errorAction: OTHER_CATEGORIES_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getOtherCategoriesAction());
    loadDictionaries();
  },
});

export const OTHER_CATEGORIES_EDIT_LOADING = "OTHER_CATEGORIES_EDIT_LOADING";
export const OTHER_CATEGORIES_EDIT_COMPLETE = "OTHER_CATEGORIES_EDIT_COMPLETE";
export const OTHER_CATEGORIES_EDIT_LOAD_ERROR =
  "OTHER_CATEGORIES_EDIT_LOAD_ERROR";

// OTHER_CATEGORIES EDIT
export const editOtherCategoriesAction = callApi({
  apiCall: (p) => editFlatEntityApi(p),
  secured: true,
  startAction: OTHER_CATEGORIES_EDIT_LOADING,
  successAction: OTHER_CATEGORIES_EDIT_COMPLETE,
  errorAction: OTHER_CATEGORIES_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getOtherCategoriesAction());
    loadDictionaries();
  },
});

export const OTHER_CATEGORIES_DELETE_LOADING =
  "OTHER_CATEGORIES_DELETE_LOADING";
export const OTHER_CATEGORIES_DELETE_COMPLETE =
  "OTHER_CATEGORIES_DELETE_COMPLETE";
export const OTHER_CATEGORIES_DELETE_LOAD_ERROR =
  "OTHER_CATEGORIES_DELETE_LOAD_ERROR";

// OTHER_CATEGORIES DELETE
export const deleteOtherCategoriesAction = callApi({
  apiCall: (p) => deleteFlatEntityApi(p),
  secured: true,
  startAction: OTHER_CATEGORIES_DELETE_LOADING,
  successAction: OTHER_CATEGORIES_DELETE_COMPLETE,
  errorAction: OTHER_CATEGORIES_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getOtherCategoriesAction());
  },
});
