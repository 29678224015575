import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const SOURCE_LOADING = "SOURCE_LOADING";
export const SOURCE_LOADED = "SOURCE_LOADED";
export const SOURCE_LOAD_ERROR = "SOURCE_LOAD_ERROR";

// INCOME RANGE GET LIST
export const getSourceAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "source" }),
  secured: true,
  startAction: SOURCE_LOADING,
  successAction: SOURCE_LOADED,
  errorAction: SOURCE_LOAD_ERROR,
});

export const SOURCE_CREATE_LOADING = "SOURCE_CREATE_LOADING";
export const SOURCE_CREATE_COMPLETE = "SOURCE_CREATE_COMPLETE";
export const SOURCE_CREATE_LOAD_ERROR = "SOURCE_CREATE_LOAD_ERROR";

// SOURCE CREATE
export const createSourceDetailAction = callApi({
  apiCall: (p) => createFlatEntityApi(p),
  secured: true,
  startAction: SOURCE_CREATE_LOADING,
  successAction: SOURCE_CREATE_COMPLETE,
  errorAction: SOURCE_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getSourceAction());
    loadDictionaries();
  },
});

export const SOURCE_EDIT_LOADING = "SOURCE_EDIT_LOADING";
export const SOURCE_EDIT_COMPLETE = "SOURCE_EDIT_COMPLETE";
export const SOURCE_EDIT_LOAD_ERROR = "SOURCE_EDIT_LOAD_ERROR";

// SOURCE EDIT
export const editSourceAction = callApi({
  apiCall: (p) => editFlatEntityApi(p),
  secured: true,
  startAction: SOURCE_EDIT_LOADING,
  successAction: SOURCE_EDIT_COMPLETE,
  errorAction: SOURCE_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getSourceAction());
    loadDictionaries();
  },
});

export const SOURCE_DELETE_LOADING = "SOURCE_DELETE_LOADING";
export const SOURCE_DELETE_COMPLETE = "SOURCE_DELETE_COMPLETE";
export const SOURCE_DELETE_LOAD_ERROR = "SOURCE_DELETE_LOAD_ERROR";

// SOURCE DELETE
export const deleteSourceAction = callApi({
  apiCall: (p) => deleteFlatEntityApi(p),
  secured: true,
  startAction: SOURCE_DELETE_LOADING,
  successAction: SOURCE_DELETE_COMPLETE,
  errorAction: SOURCE_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getSourceAction());
  },
});
