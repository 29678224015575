import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  EMPLOYER_RANGE_LOADING,
  EMPLOYER_RANGE_LOADED,
  EMPLOYER_RANGE_LOAD_ERROR,
  EMPLOYER_RANGE_CREATE_LOADING,
  EMPLOYER_RANGE_CREATE_LOAD_ERROR,
  EMPLOYER_RANGE_EDIT_LOADING,
  EMPLOYER_RANGE_EDIT_COMPLETE,
  EMPLOYER_RANGE_EDIT_LOAD_ERROR,
  EMPLOYER_RANGE_DELETE_LOADING,
  EMPLOYER_RANGE_DELETE_COMPLETE,
  EMPLOYER_RANGE_DELETE_LOAD_ERROR,
  EMPLOYER_RANGE_CREATE_COMPLETE,
} from "../../actions/registry/registry.employer_range.actions";

const reducer = {
  /*
   * EMPLOYER_RANGE LIST
   */
  [EMPLOYER_RANGE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [EMPLOYER_RANGE_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [EMPLOYER_RANGE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * EMPLOYER_RANGE CREATE
   */
  [EMPLOYER_RANGE_CREATE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [EMPLOYER_RANGE_CREATE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [EMPLOYER_RANGE_CREATE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * EMPLOYER_RANGE EDIT
   */
  [EMPLOYER_RANGE_EDIT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [EMPLOYER_RANGE_EDIT_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [EMPLOYER_RANGE_EDIT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * EMPLOYER_RANGE DELETE
   */
  [EMPLOYER_RANGE_DELETE_LOADING]: (state) => ({
    ...state,
    income_range: { ...state.income_range, loading: true },
  }),
  [EMPLOYER_RANGE_DELETE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [EMPLOYER_RANGE_DELETE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    income_range: { ...state.income_range, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
