import callApi from "../libs/helpers/callApi";

import {
  getCompanyEntityApi,
  OLDgetCompanyListApi,
  getCompanyDetailApi,
  editCompanyDetailApi,
  getCompanyContactsApi,
  getCompanyBrandsApi,
  createCompanyDetailApi,
  getCompanyNielsenApi,
  uploadNielsenFileApi,
  getCompanyPitchApi,
  getCompanyCreaApi,
  acquireCompanyContactsApi,
  acquireCompanyBrandsApi,
  mergeCompanyApi,
  uploadFinancialsFileApi,
  exportCompanyListApi,
  downloadNielsenFileCompanyApi,
  downloadDataStatsCompanyApi,
  getCompanyGroupApi,
  downloadSpecificExtractionApi,
  downloadSpecificCompanyExtractionApi,
  downloadLoginReportApi,
} from "../api/company.api";
import { getContactListApi } from "../api/contact.api";
import { message } from "antd";
import Localize from "../libs/localization";
import { openCompanyMergedNotification } from "../pages/settings/_transfer.settings.partial";

export const COMPANY_ENTITY_LOADING = "COMPANY_ENTITY_LOADING";
export const COMPANY_ENTITY_LOADED = "COMPANY_ENTITY_LOADED";
export const COMPANY_ENTITY_LOAD_ERROR = "COMPANY_ENTITY_LOAD_ERROR";

export const getCompanyEntityAction = callApi({
  apiCall: (p) => getCompanyEntityApi(p),
  secured: true,
  startAction: COMPANY_ENTITY_LOADING,
  successAction: COMPANY_ENTITY_LOADED,
  errorAction: COMPANY_ENTITY_LOAD_ERROR,
});

export const COMPANY_LIST_LOADING = "COMPANY_LIST_LOADING";
export const COMPANY_LIST_LOADED = "COMPANY_LIST_LOADED";
export const COMPANY_LIST_LOAD_ERROR = "COMPANY_LIST_LOAD_ERROR";

export const getCompanyListAction = callApi({
  apiCall: (p) => OLDgetCompanyListApi(p),
  secured: true,
  startAction: COMPANY_LIST_LOADING,
  successAction: COMPANY_LIST_LOADED,
  errorAction: COMPANY_LIST_LOAD_ERROR,
});

export const COMPANY_DETAIL_LOADING = "COMPANY_DETAIL_LOADING";
export const COMPANY_DETAIL_LOADED = "COMPANY_DETAIL_LOADED";
export const COMPANY_DETAIL_LOAD_ERROR = "COMPANY_DETAIL_LOAD_ERROR";

export const getCompanyDetailAction = callApi({
  apiCall: (p) => getCompanyDetailApi(p),
  secured: true,
  startAction: COMPANY_DETAIL_LOADING,
  successAction: COMPANY_DETAIL_LOADED,
  errorAction: COMPANY_DETAIL_LOAD_ERROR,
});

export const COMPANY_DETAIL_CREATING = "COMPANY_DETAIL_CREATING";
export const COMPANY_DETAIL_CREATED = "COMPANY_DETAIL_CREATED";
export const COMPANY_DETAIL_CREATE_ERROR = "COMPANY_DETAIL_CREATE_ERROR";

export const createCompanyDetailAction = callApi({
  apiCall: (p) => createCompanyDetailApi(p),
  secured: true,
  startAction: COMPANY_DETAIL_CREATING,
  successAction: COMPANY_DETAIL_CREATED,
  errorAction: COMPANY_DETAIL_CREATE_ERROR,
});

export const COMPANY_DETAIL_EDITING = "COMPANY_DETAIL_EDITING";
export const COMPANY_DETAIL_EDITED = "COMPANY_DETAIL_EDITED";
export const COMPANY_DETAIL_EDIT_ERROR = "COMPANY_DETAIL_EDIT_ERROR";

export const editCompanyDetailAction = callApi({
  apiCall: (p) => editCompanyDetailApi(p),
  secured: true,
  startAction: COMPANY_DETAIL_EDITING,
  successAction: COMPANY_DETAIL_EDITED,
  errorAction: COMPANY_DETAIL_EDIT_ERROR,
});

export const COMPANY_CONTACTS_LOADING = "COMPANY_CONTACTS_LOADING";
export const COMPANY_CONTACTS_LOADED = "COMPANY_CONTACTS_LOADED";
export const COMPANY_CONTACTS_LOAD_ERROR = "COMPANY_CONTACTS_LOAD_ERROR";

export const getCompanyContactsAction = callApi({
  apiCall: (p) => getContactListApi(p),
  secured: true,
  startAction: COMPANY_CONTACTS_LOADING,
  successAction: COMPANY_CONTACTS_LOADED,
  errorAction: COMPANY_CONTACTS_LOAD_ERROR,
});

export const COMPANY_BRANDS_LOADING = "COMPANY_BRANDS_LOADING";
export const COMPANY_BRANDS_LOADED = "COMPANY_BRANDS_LOADED";
export const COMPANY_BRANDS_LOAD_ERROR = "COMPANY_BRANDS_LOAD_ERROR";

export const getCompanyBrandsAction = callApi({
  apiCall: (p) => getCompanyBrandsApi(p),
  secured: true,
  startAction: COMPANY_BRANDS_LOADING,
  successAction: COMPANY_BRANDS_LOADED,
  errorAction: COMPANY_BRANDS_LOAD_ERROR,
});

export const COMPANY_NIELSEN_LOADING = "COMPANY_NIELSEN_LOADING";
export const COMPANY_NIELSEN_LOADED = "COMPANY_NIELSEN_LOADED";
export const COMPANY_NIELSEN_LOAD_ERROR = "COMPANY_NIELSEN_LOAD_ERROR";

export const getCompanyNielsenAction = callApi({
  apiCall: (p) => getCompanyNielsenApi(p),
  secured: true,
  startAction: COMPANY_NIELSEN_LOADING,
  successAction: COMPANY_NIELSEN_LOADED,
  errorAction: COMPANY_NIELSEN_LOAD_ERROR,
});

export const UPLOAD_NIELSEN_LOADING = "UPLOAD_NIELSEN_LOADING";
export const UPLOAD_NIELSEN_COMPLETE = "UPLOAD_NIELSEN_COMPLETE";
export const UPLOAD_NIELSEN_ERROR = "UPLOAD_NIELSEN_ERROR";

export const uploadNielsenFileAction = callApi({
  apiCall: (p) => uploadNielsenFileApi(p),
  secured: true,
  startAction: UPLOAD_NIELSEN_LOADING,
  successAction: UPLOAD_NIELSEN_COMPLETE,
  errorAction: UPLOAD_NIELSEN_ERROR,
  afterSuccessAction: () =>
    message.success(Localize("PAGES.REGISTRY.UPLOAD_COMPLETED"), 10),
  processError: () =>
    message.error(Localize("PAGES.REGISTRY.UPLOAD_ERROR"), 10),
});

export const UPLOAD_FINANCIALS_LOADING = "UPLOAD_FINANCIALS_LOADING";
export const UPLOAD_FINANCIALS_COMPLETE = "UPLOAD_FINANCIALS_COMPLETE";
export const UPLOAD_FINANCIALS_ERROR = "UPLOAD_FINANCIALS_ERROR";

export const uploadFinancialsFileAction = callApi({
  apiCall: (p) => uploadFinancialsFileApi(p),
  secured: true,
  startAction: UPLOAD_FINANCIALS_LOADING,
  successAction: UPLOAD_FINANCIALS_COMPLETE,
  errorAction: UPLOAD_FINANCIALS_ERROR,
  afterSuccessAction: () =>
    message.success(Localize("PAGES.REGISTRY.UPLOAD_COMPLETED"), 10),
  processError: () =>
    message.error(Localize("PAGES.REGISTRY.UPLOAD_ERROR"), 10),
});

export const COMPANY_PITCH_LOADING = "COMPANY_PITCH_LOADING";
export const COMPANY_PITCH_LOADED = "COMPANY_PITCH_LOADED";
export const COMPANY_PITCH_LOAD_ERROR = "COMPANY_PITCH_LOAD_ERROR";

export const getCompanyPitchAction = callApi({
  apiCall: (p) => getCompanyPitchApi(p),
  secured: true,
  startAction: COMPANY_PITCH_LOADING,
  successAction: COMPANY_PITCH_LOADED,
  errorAction: COMPANY_PITCH_LOAD_ERROR,
});

export const COMPANY_CREA_LOADING = "COMPANY_CREA_LOADING";
export const COMPANY_CREA_LOADED = "COMPANY_CREA_LOADED";
export const COMPANY_CREA_LOAD_ERROR = "COMPANY_CREA_LOAD_ERROR";

export const getCompanyCreaAction = callApi({
  apiCall: (p) => getCompanyCreaApi(p),
  secured: true,
  startAction: COMPANY_CREA_LOADING,
  successAction: COMPANY_CREA_LOADED,
  errorAction: COMPANY_CREA_LOAD_ERROR,
});

export const COMPANY_ACQUIRE_CONTACTS_LOADING =
  "COMPANY_ACQUIRE_CONTACTS_LOADING";
export const COMPANY_ACQUIRE_CONTACTS_LOADED =
  "COMPANY_ACQUIRE_CONTACTS_LOADED";
export const COMPANY_ACQUIRE_CONTACTS_LOAD_ERROR =
  "COMPANY_ACQUIRE_CONTACTS_LOAD_ERROR";

export const acquireContactsAction = callApi({
  apiCall: (p) => acquireCompanyContactsApi(p),
  secured: true,
  startAction: COMPANY_ACQUIRE_CONTACTS_LOADING,
  successAction: COMPANY_ACQUIRE_CONTACTS_LOADED,
  errorAction: COMPANY_ACQUIRE_CONTACTS_LOAD_ERROR,
});

export const COMPANY_ACQUIRE_BRANDS_LOADING = "COMPANY_ACQUIRE_BRANDS_LOADING";
export const COMPANY_ACQUIRE_BRANDS_LOADED = "COMPANY_ACQUIRE_BRANDS_LOADED";
export const COMPANY_ACQUIRE_BRANDS_LOAD_ERROR =
  "COMPANY_ACQUIRE_BRANDS_LOAD_ERROR";

export const acquireBrandsAction = callApi({
  apiCall: (p) => acquireCompanyBrandsApi(p),
  secured: true,
  startAction: COMPANY_ACQUIRE_BRANDS_LOADING,
  successAction: COMPANY_ACQUIRE_BRANDS_LOADED,
  errorAction: COMPANY_ACQUIRE_BRANDS_LOAD_ERROR,
});

export const COMPANY_MERGE_LOADING = "COMPANY_MERGE_LOADING";
export const COMPANY_MERGE_LOADED = "COMPANY_MERGE_LOADED";
export const COMPANY_MERGE_LOAD_ERROR = "COMPANY_MERGE_LOAD_ERROR";

export const mergeCompanyAction = callApi({
  apiCall: (p) => mergeCompanyApi(p),
  secured: true,
  startAction: COMPANY_MERGE_LOADING,
  successAction: COMPANY_MERGE_LOADED,
  errorAction: COMPANY_MERGE_LOAD_ERROR,
  afterSuccessAction: () => openCompanyMergedNotification(),
});

export const EXPORT_COMPANY_LOADING = "EXPORT_COMPANY_LOADING";
export const EXPORT_COMPANY_LOADED = "EXPORT_COMPANY_LOADED";
export const EXPORT_COMPANY_LOAD_ERROR = "EXPORT_COMPANY_LOAD_ERROR";

export const exportCompanyAction = callApi({
  apiCall: (p) => exportCompanyListApi(p),
  secured: true,
  startAction: EXPORT_COMPANY_LOADING,
  successAction: EXPORT_COMPANY_LOADED,
  errorAction: EXPORT_COMPANY_LOAD_ERROR,
  afterSuccessAction: ({ result }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "company-list.xlsx";
    a.click();
  },
});

export const DOWNLOAD_NIELSEN_FILE_LOADING = "DOWNLOAD_NIELSEN_FILE_LOADING";
export const DOWNLOAD_NIELSEN_FILE_LOADED = "DOWNLOAD_NIELSEN_FILE_LOADED";
export const DOWNLOAD_NIELSEN_FILE_LOAD_ERROR =
  "DOWNLOAD_NIELSEN_FILE_LOAD_ERROR";

export const downloadNielsenFileAction = callApi({
  apiCall: (p) => downloadNielsenFileCompanyApi(p),
  secured: true,
  startAction: DOWNLOAD_NIELSEN_FILE_LOADING,
  successAction: DOWNLOAD_NIELSEN_FILE_LOADED,
  errorAction: DOWNLOAD_NIELSEN_FILE_LOAD_ERROR,
  afterSuccessAction: ({ result }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "nielsen-information.xlsx";
    a.click();
  },
});

export const DOWNLOAD_DATA_STATS_FILE_LOADING =
  "DOWNLOAD_DATA_STATS_FILE_LOADING";
export const DOWNLOAD_DATA_STATS_FILE_LOADED =
  "DOWNLOAD_DATA_STATS_FILE_LOADED";
export const DOWNLOAD_DATA_STATS_FILE_LOAD_ERROR =
  "DOWNLOAD_DATA_STATS_FILE_LOAD_ERROR";

export const downloadDataStatsFileAction = callApi({
  apiCall: (p) => downloadDataStatsCompanyApi(p),
  secured: true,
  startAction: DOWNLOAD_DATA_STATS_FILE_LOADING,
  successAction: DOWNLOAD_DATA_STATS_FILE_LOADED,
  errorAction: DOWNLOAD_DATA_STATS_FILE_LOAD_ERROR,
  afterSuccessAction: ({ result }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "data-stats.xlsx";
    a.click();
  },
});

export const DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOADING =
  "DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOADING";
export const DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOADED =
  "DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOADED";
export const DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOAD_ERROR =
  "DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOAD_ERROR";

export const downloadSpecificExtractionFileAction = callApi({
  apiCall: (p) => downloadSpecificExtractionApi(p),
  secured: true,
  startAction: DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOADING,
  successAction: DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOADED,
  errorAction: DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOAD_ERROR,
  afterSuccessAction: ({ result }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "Blinko specifique Fc Mvt.csv";
    a.click();
  },
});

export const DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOADING =
  "DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOADING";
export const DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOADED =
  "DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOADED";
export const DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOAD_ERROR =
  "DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOAD_ERROR";

export const downloadSpecificCompanyExtractionFileAction = callApi({
  apiCall: (p) => downloadSpecificCompanyExtractionApi(p),
  secured: true,
  startAction: DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOADING,
  successAction: DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOADED,
  errorAction: DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOAD_ERROR,
  afterSuccessAction: ({ result }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "External links.csv";
    a.click();
  },
});

export const DOWNLOAD_LOGIN_REPORT_LOADING = "DOWNLOAD_LOGIN_REPORT_LOADING";
export const DOWNLOAD_LOGIN_REPORT_LOADED = "DOWNLOAD_LOGIN_REPORT_LOADED";
export const DOWNLOAD_LOGIN_REPORT_LOAD_ERROR =
  "DOWNLOAD_LOGIN_REPORT_LOAD_ERROR";

export const downloadLoginReportAction = callApi({
  apiCall: (p) => downloadLoginReportApi(p),
  secured: true,
  startAction: DOWNLOAD_LOGIN_REPORT_LOADING,
  successAction: DOWNLOAD_LOGIN_REPORT_LOADED,
  errorAction: DOWNLOAD_LOGIN_REPORT_LOAD_ERROR,
  afterSuccessAction: ({ result }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "Login report.csv";
    a.click();
  },
});

export const COMPANY_GROUP_LOADING = "COMPANY_GROUP_LOADING";
export const COMPANY_GROUP_LOADED = "COMPANY_GROUP_LOADED";
export const COMPANY_GROUP_LOAD_ERROR = "COMPANY_GROUP_LOAD_ERROR";

export const getCompanyGroupAction = callApi({
  apiCall: (p) => getCompanyGroupApi(p),
  secured: true,
  startAction: COMPANY_GROUP_LOADING,
  successAction: COMPANY_GROUP_LOADED,
  errorAction: COMPANY_GROUP_LOAD_ERROR,
});
