import callApi from "../../libs/helpers/callApi";
import {
  createFlatV2EntityApi,
  deleteFlatV2EntityApi,
  editFlatV2EntityApi,
  getFlatV2EntityListApi,
} from "../../api/flat.api";
import { loadDictionaries } from "../../locales";

export const FUNDING_TYPE_LOADING = "FUNDING_TYPE_LOADING";
export const FUNDING_TYPE_LOADED = "FUNDING_TYPE_LOADED";
export const FUNDING_TYPE_LOAD_ERROR = "FUNDING_TYPE_LOAD_ERROR";

// FUNDING_TYPE GET LIST
export const getFundingTypeAction = callApi({
  apiCall: (p) =>
    getFlatV2EntityListApi({ ...p, entity: "InvestmentRoundFundingType" }),
  secured: true,
  startAction: FUNDING_TYPE_LOADING,
  successAction: FUNDING_TYPE_LOADED,
  errorAction: FUNDING_TYPE_LOAD_ERROR,
});

export const FUNDING_TYPE_CREATE_LOADING = "FUNDING_TYPE_CREATE_LOADING";
export const FUNDING_TYPE_CREATE_COMPLETE = "FUNDING_TYPE_CREATE_COMPLETE";
export const FUNDING_TYPE_CREATE_LOAD_ERROR = "FUNDING_TYPE_CREATE_LOAD_ERROR";

// FUNDING_TYPE CREATE
export const createFundingTypeAction = callApi({
  apiCall: (p) => createFlatV2EntityApi(p),
  secured: true,
  startAction: FUNDING_TYPE_CREATE_LOADING,
  successAction: FUNDING_TYPE_CREATE_COMPLETE,
  errorAction: FUNDING_TYPE_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getFundingTypeAction());
    loadDictionaries();
  },
});

export const FUNDING_TYPE_EDIT_LOADING = "FUNDING_TYPE_EDIT_LOADING";
export const FUNDING_TYPE_EDIT_COMPLETE = "FUNDING_TYPE_EDIT_COMPLETE";
export const FUNDING_TYPE_EDIT_LOAD_ERROR = "FUNDING_TYPE_EDIT_LOAD_ERROR";

// FUNDING_TYPE EDIT
export const editFundingTypeAction = callApi({
  apiCall: (p) => editFlatV2EntityApi(p),
  secured: true,
  startAction: FUNDING_TYPE_EDIT_LOADING,
  successAction: FUNDING_TYPE_EDIT_COMPLETE,
  errorAction: FUNDING_TYPE_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getFundingTypeAction());
    loadDictionaries();
  },
});

export const FUNDING_TYPE_DELETE_LOADING = "FUNDING_TYPE_DELETE_LOADING";
export const FUNDING_TYPE_DELETE_COMPLETE = "FUNDING_TYPE_DELETE_COMPLETE";
export const FUNDING_TYPE_DELETE_LOAD_ERROR = "FUNDING_TYPE_DELETE_LOAD_ERROR";

// FUNDING_TYPE DELETE
export const deleteFundingTypeAction = callApi({
  apiCall: (p) => deleteFlatV2EntityApi(p),
  secured: true,
  startAction: FUNDING_TYPE_DELETE_LOADING,
  successAction: FUNDING_TYPE_DELETE_COMPLETE,
  errorAction: FUNDING_TYPE_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getFundingTypeAction());
  },
});
