import callApi from "../libs/helpers/callApi";

import {
  getContactEntityApi,
  getContactListApi,
  getContactDetailApi,
  createContactDetailApi,
  editContactDetailApi,
  getContactCompanyApi,
  getContactBrandsApi,
} from "../api/contact.api";

export const CONTACT_ENTITY_LOADING = "CONTACT_ENTITY_LOADING";
export const CONTACT_ENTITY_LOADED = "CONTACT_ENTITY_LOADED";
export const CONTACT_ENTITY_LOAD_ERROR = "CONTACT_ENTITY_LOAD_ERROR";

export const getContactEntityAction = callApi({
  apiCall: (p) => getContactEntityApi(p),
  secured: true,
  startAction: CONTACT_ENTITY_LOADING,
  successAction: CONTACT_ENTITY_LOADED,
  errorAction: CONTACT_ENTITY_LOAD_ERROR,
});

export const CONTACT_LIST_LOADING = "CONTACT_LIST_LOADING";
export const CONTACT_LIST_LOADED = "CONTACT_LIST_LOADED";
export const CONTACT_LIST_LOAD_ERROR = "CONTACT_LIST_LOAD_ERROR";

export const getContactListAction = callApi({
  apiCall: (p) => getContactListApi(p),
  secured: true,
  startAction: CONTACT_LIST_LOADING,
  successAction: CONTACT_LIST_LOADED,
  errorAction: CONTACT_LIST_LOAD_ERROR,
});

export const CONTACT_DETAIL_LOADING = "CONTACT_DETAIL_LOADING";
export const CONTACT_DETAIL_LOADED = "CONTACT_DETAIL_LOADED";
export const CONTACT_DETAIL_LOAD_ERROR = "CONTACT_DETAIL_LOAD_ERROR";

export const getContactDetailAction = callApi({
  apiCall: (p) => getContactDetailApi(p),
  secured: true,
  startAction: CONTACT_DETAIL_LOADING,
  successAction: CONTACT_DETAIL_LOADED,
  errorAction: CONTACT_DETAIL_LOAD_ERROR,
});

export const CONTACT_DETAIL_CREATING = "CONTACT_DETAIL_CREATING";
export const CONTACT_DETAIL_CREATED = "CONTACT_DETAIL_CREATED";
export const CONTACT_DETAIL_CREATE_ERROR = "CONTACT_DETAIL_CREATE_ERROR";

export const createContactDetailAction = callApi({
  apiCall: (p) => createContactDetailApi(p),
  secured: true,
  startAction: CONTACT_DETAIL_CREATING,
  successAction: CONTACT_DETAIL_CREATED,
  errorAction: CONTACT_DETAIL_CREATE_ERROR,
});

export const CONTACT_DETAIL_EDITING = "CONTACT_DETAIL_EDITING";
export const CONTACT_DETAIL_EDITED = "CONTACT_DETAIL_EDITED";
export const CONTACT_DETAIL_EDIT_ERROR = "CONTACT_DETAIL_EDIT_ERROR";

export const editContactDetailAction = callApi({
  apiCall: (p) => editContactDetailApi(p),
  secured: true,
  startAction: CONTACT_DETAIL_EDITING,
  successAction: CONTACT_DETAIL_EDITED,
  errorAction: CONTACT_DETAIL_EDIT_ERROR,
});

export const CONTACT_COMPANY_LOADING = "CONTACT_COMPANY_LOADING";
export const CONTACT_COMPANY_LOADED = "CONTACT_COMPANY_LOADED";
export const CONTACT_COMPANY_LOAD_ERROR = "CONTACT_COMPANY_LOAD_ERROR";

export const getContactCompanyAction = callApi({
  apiCall: (p) => getContactCompanyApi(p),
  secured: true,
  startAction: CONTACT_COMPANY_LOADING,
  successAction: CONTACT_COMPANY_LOADED,
  errorAction: CONTACT_COMPANY_LOAD_ERROR,
});

export const CONTACT_BRAND_LOADING = "CONTACT_BRAND_LOADING";
export const CONTACT_BRAND_LOADED = "CONTACT_BRAND_LOADED";
export const CONTACT_BRAND_LOAD_ERROR = "CONTACT_BRAND_LOAD_ERROR";

export const getContactBrandsAction = callApi({
  apiCall: (p) => getContactBrandsApi(p),
  secured: true,
  startAction: CONTACT_BRAND_LOADING,
  successAction: CONTACT_BRAND_LOADED,
  errorAction: CONTACT_BRAND_LOAD_ERROR,
});
