import {
  FUNDING_TYPE_CREATE_COMPLETE,
  FUNDING_TYPE_CREATE_LOADING,
  FUNDING_TYPE_CREATE_LOAD_ERROR,
  FUNDING_TYPE_DELETE_COMPLETE,
  FUNDING_TYPE_DELETE_LOADING,
  FUNDING_TYPE_DELETE_LOAD_ERROR,
  FUNDING_TYPE_EDIT_COMPLETE,
  FUNDING_TYPE_EDIT_LOADING,
  FUNDING_TYPE_EDIT_LOAD_ERROR,
  FUNDING_TYPE_LOADED,
  FUNDING_TYPE_LOADING,
  FUNDING_TYPE_LOAD_ERROR,
} from "../../actions/registry/registry.funding_type.actions";
import { reducerFromMap } from "../../libs/helpers/reducers";

const reducer = {
  /*
   * FUNDING_TYPE LIST
   */
  [FUNDING_TYPE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [FUNDING_TYPE_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data,
  }),
  [FUNDING_TYPE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * FUNDING_TYPE CREATE
   */
  [FUNDING_TYPE_CREATE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [FUNDING_TYPE_CREATE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [FUNDING_TYPE_CREATE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * FUNDING_TYPE EDIT
   */
  [FUNDING_TYPE_EDIT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [FUNDING_TYPE_EDIT_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [FUNDING_TYPE_EDIT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * FUNDING_TYPE DELETE
   */
  [FUNDING_TYPE_DELETE_LOADING]: (state) => ({
    ...state,
    fundingType: { ...state.fundingType, loading: true },
  }),
  [FUNDING_TYPE_DELETE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [FUNDING_TYPE_DELETE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    fundingType: { ...state.fundingType, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
