import { Select } from "antd";
import { find, groupBy, isEqual, orderBy } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFlatV2EntityAction } from "../../actions/flatV2.actions";
import { translateRegistryKeyFlatV2 } from "../../libs/utils";

export default function FlatV2Picker({
  value,
  onChange,
  disabled,
  entityName,
  multi = false,
  displayExtraField = null,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFlatV2EntityAction({ entity: entityName }));
  }, [entityName]);
  const entities = useSelector(
    (state) => ((state.flatV2 || {}).entities || {})[entityName]?.data || [],
    (a, b) => isEqual(a, b)
  );
  const entitiesById = {};
  if (entities)
    entities.forEach((entity) => {
      entitiesById[entity._id] = entity;
    });
  const loading = useSelector(
    (state) => ((state.flatV2 || {}).entities || {})[entityName]?.loading
  );

  const renderOptions = () => {
    const hasParent = !!entities[0]?.parent;
    if (hasParent) {
      const group = groupBy(entities, "parent");

      return Object.keys(group).map((g, k) => {
        // CHECK if there is at least 1 element active in group
        const findActive = find(group[g], (o) => o.active);
        if (findActive) {
          return (
            <Select.OptGroup key={k} label={g}>
              {group[g].map((el, jl) => {
                if (el.active) {
                  return (
                    <Select.Option
                      key={`${g}-${jl}`}
                      value={el.key}
                      searchIndex={`${el.key} ${
                        displayExtraField
                          ? el.extraFields[displayExtraField]
                          : ""
                      }`}
                    >
                      {translateRegistryKeyFlatV2(el.key, entityName) || el.key}
                      {displayExtraField ? (
                        <p
                          style={{
                            marginLeft: 12,
                            fontWeight: "bold",
                            opacity: 0.4,
                          }}
                        >
                          {el.extraFields[displayExtraField]}
                        </p>
                      ) : null}
                    </Select.Option>
                  );
                }
              })}
            </Select.OptGroup>
          );
        }
        return null;
      });
    }

    return (orderBy(entities, "id") || []).map((el, j) => {
      if (el.active) {
        return (
          <Select.Option
            key={j}
            value={el._id}
            searchIndex={`${el.key} ${
              displayExtraField ? el.extraFields[displayExtraField] : ""
            }`}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "calc(100% - 24px)",
              }}
            >
              {translateRegistryKeyFlatV2(el.key, entityName) || el.key}
              {displayExtraField ? (
                <p
                  style={{
                    marginLeft: 12,
                    fontWeight: "bold",
                    opacity: 0.4,
                  }}
                >
                  {el.extraFields[displayExtraField]}
                </p>
              ) : null}
            </div>
          </Select.Option>
        );
      }
      return null;
    });
  };

  let onChange_;
  let value_;

  if (multi) {
    onChange_ = function (ids) {
      const v = ids
        .map((id) => entitiesById[id])
        .map((obj) => ({
          ...(obj.extraFields || {}),
          id: obj._id,
          key: obj.key,
        }));
      return onChange(v);
    };
    value_ = value?.map((el) => el.id) || [];
  } else {
    onChange_ = function (id) {
      let v = entitiesById[id] || entities.find((el) => el.key === value);
      v = {
        ...(v.extraFields || {}),
        id: v._id,
        key: v.key,
        parent: v.parent,
      };
      return onChange(v);
    };
    value_ = value?.id || entities.find((el) => el.key === value)?._id || null;
  }

  return (
    <Select
      value={value_}
      style={{ width: "100%" }}
      disabled={disabled}
      placeholder={disabled ? "—" : ""}
      onChange={(v) =>
        typeof onChange === "function" && !disabled && onChange_(v)
      }
      showSearch
      optionFilterProp="searchIndex"
      filterOption={(input, option) => {
        return option.searchIndex?.toLowerCase().includes(input);
      }}
      mode={multi ? "multiple" : undefined}
    >
      {renderOptions()}
    </Select>
  );
}
