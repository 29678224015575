import React, { useEffect, useState } from "react";
import { AutoComplete, Select } from "antd";
import { OLDgetCompanyListApi, getCompanyListApi } from "../../api/company.api";
import { headersBuilder } from "../../libs/helpers/callApi";

const { Option } = AutoComplete;

const CompanyAutocomplete = ({
  item,
  placeholder = "",
  onChange,
  disabled,
  itemSchema,
  token,
  onSelect,
  cancelValue = false,
  isSelect = false,
  resetValue,
  ...props
}) => {
  const [companyList, setCompanyList] = useState([]);
  const [currValue, setCurrValue] = useState(item?.name);
  const [loading, setLoading] = useState(false);

  if (!token) token = localStorage.getItem("token");

  useEffect(() => {
    setCurrValue(item?.name);
  }, [item]);

  useEffect(() => {
    resetValue && setCurrValue(null);
  }, [resetValue]);

  // se isSelect === true il componente è Select
  // se isSelect === false il componente è Autocomplete
  return (
    <>
      {isSelect ? (
        <Select
          loading={loading}
          value={currValue}
          style={{ width: "100%" }}
          disabled={disabled}
          showSearch
          onSelect={(value, opt) => {
            setCurrValue(value);
            if (typeof onChange === "function" && !disabled) {
              onChange(itemSchema.key, (opt && opt.obj) || null);
            }
          }}
          allowClear
          onChange={(value, opt) => {
            setCurrValue(value);
            if (typeof onChange === "function" && !disabled) {
              onChange(itemSchema.key, (opt && opt.obj) || null);
            }
          }}
          onSearch={async (val) => {
            if (val && val.length > 2) {
              setLoading(true);
              const res = await getCompanyListApi({
                params: {
                  name: val,
                },
                abortChannel: "COMPANY_AUTOCOMPLETE",
              });
              if (!res.canceled) {
                setLoading(false);
                setCompanyList(
                  (res.data || []).map((r) => ({
                    label: r.name,
                    value: r.name,
                    obj: { id: r._id, name: r.name },
                  }))
                );
              }
            }
          }}
          placeholder={disabled ? "—" : placeholder}
          {...props}
        >
          {companyList.map((el, key) => (
            <Option key={key} value={el.value} obj={el.obj}>
              {el.label}
            </Option>
          ))}
        </Select>
      ) : (
        <AutoComplete
          value={currValue}
          style={{ width: "100%" }}
          disabled={disabled}
          onSelect={(value, opt) => {
            setCurrValue(value);
            if (typeof onSelect === "function" && !disabled) {
              onSelect(value, opt);
            }
            if (typeof onChange === "function" && !disabled) {
              if (itemSchema) {
                onChange(itemSchema.key, (opt && opt.obj) || null);
              } else {
                onChange(value);
              }
            }
          }}
          allowClear
          onChange={(value, opt) => {
            if (cancelValue) {
              setCurrValue(undefined);
            } else {
              setCurrValue(value);
            }
            if (itemSchema) {
              onChange(itemSchema.key, (opt && opt.obj) || null);
            } else {
              onChange(value);
            }
          }}
          onFocus={async () => {
            const res = await OLDgetCompanyListApi({
              headers: {
                ...headersBuilder(),
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
              },
            });
            setCompanyList(
              (res.data || []).map((r) => ({
                label: r.name,
                value: r.name,
                obj: { id: r._id, name: r.name },
              }))
            );
          }}
          onSearch={async (val) => {
            if (val) {
              setLoading(true);
              const res = await getCompanyListApi({
                params: {
                  name: val,
                },
                abortChannel: "COMPANY_AUTOCOMPLETE",
              });
              if (!res.canceled) {
                setLoading(false);
                setCompanyList(
                  (res.data || []).map((r) => ({
                    label: r.name,
                    value: r.name,
                    obj: { id: r._id, name: r.name },
                  }))
                );
              }
            }
          }}
          placeholder={disabled ? "—" : placeholder}
          {...props}
        >
          {companyList.map((el, key) => (
            <Option key={key} value={el.value} obj={el.obj}>
              {el.label}
            </Option>
          ))}
        </AutoComplete>
      )}
    </>
  );
};

export default CompanyAutocomplete;
