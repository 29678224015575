import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import { googlePlacesParser } from "../DetailPane/_DetailPane.helpers";

const SearchPlaces = ({ onChange, placeholder, placeInput }) => {
  const placeInputRef = useRef(null);
  const [defaultValue, setDefaultValue] = useState(
    placeInput ? placeInput : ""
  );

  useEffect(() => {
    initPlaceAPI();
  }, []);

  useEffect(() => {
    if (!defaultValue) onChange("");
  }, [defaultValue]);

  // initialize the google place autocomplete
  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current,
      { types: ["address"] }
    );
    new window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();
        onChange(place);
        setDefaultValue(getFormattedAddress(place));
      }
    );
  };

  const getFormattedAddress = (data) => {
    const { address_components, geometry } = data;
    const place = googlePlacesParser(address_components);
    const { location } = geometry || {};

    if (place && location) {
      const {
        street_number,
        route,
        locality,
        postal_town,
        administrative_area_level_3: hamlet,
        administrative_area_level_2: province,
        administrative_area_level_1: region,
        country: nation,
        postal_code: CP,
      } = place;

      const city = locality || hamlet || postal_town;

      const dataAddress = {
        address: `${route || ""} ${street_number || ""}`,
        region,
        province,
        CP,
        city,
        nation,
        position: {
          lat: location.lat(),
          lng: location.lng(),
        },
      };

      if (dataAddress && dataAddress.address) {
        return `${dataAddress.address ? dataAddress.address + "," : ""} ${
          dataAddress.CP ? dataAddress.CP : ""
        } ${dataAddress.city ? dataAddress.city : ""} ${
          dataAddress.province ? dataAddress.province : ""
        } ${dataAddress.region ? dataAddress.region + "," : ""} ${
          dataAddress.nation ? dataAddress.nation : ""
        }`;
      }
      return "";
    }
  };

  return (
    <>
      <input
        value={defaultValue}
        onChange={(e) => setDefaultValue(e.target.value)}
        type="text"
        ref={placeInputRef}
        className="ant-input"
        placeholder={placeholder}
      />
    </>
  );
};

export default SearchPlaces;
