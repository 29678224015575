import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const MAIN_CATEGORY_LOADING = "MAIN_CATEGORY_LOADING";
export const MAIN_CATEGORY_LOADED = "MAIN_CATEGORY_LOADED";
export const MAIN_CATEGORY_LOAD_ERROR = "MAIN_CATEGORY_LOAD_ERROR";

// INCOME RANGE GET LIST
export const getMainCategoryAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "main_category" }),
  secured: true,
  startAction: MAIN_CATEGORY_LOADING,
  successAction: MAIN_CATEGORY_LOADED,
  errorAction: MAIN_CATEGORY_LOAD_ERROR,
});

export const MAIN_CATEGORY_CREATE_LOADING = "MAIN_CATEGORY_CREATE_LOADING";
export const MAIN_CATEGORY_CREATE_COMPLETE = "MAIN_CATEGORY_CREATE_COMPLETE";
export const MAIN_CATEGORY_CREATE_LOAD_ERROR =
  "MAIN_CATEGORY_CREATE_LOAD_ERROR";

// MAIN_CATEGORY CREATE
export const createMainCategoryDetailAction = callApi({
  apiCall: (p) => createFlatEntityApi(p),
  secured: true,
  startAction: MAIN_CATEGORY_CREATE_LOADING,
  successAction: MAIN_CATEGORY_CREATE_COMPLETE,
  errorAction: MAIN_CATEGORY_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getMainCategoryAction());
    loadDictionaries();
  },
});

export const MAIN_CATEGORY_EDIT_LOADING = "MAIN_CATEGORY_EDIT_LOADING";
export const MAIN_CATEGORY_EDIT_COMPLETE = "MAIN_CATEGORY_EDIT_COMPLETE";
export const MAIN_CATEGORY_EDIT_LOAD_ERROR = "MAIN_CATEGORY_EDIT_LOAD_ERROR";

// MAIN_CATEGORY EDIT
export const editMainCategoryAction = callApi({
  apiCall: (p) => editFlatEntityApi(p),
  secured: true,
  startAction: MAIN_CATEGORY_EDIT_LOADING,
  successAction: MAIN_CATEGORY_EDIT_COMPLETE,
  errorAction: MAIN_CATEGORY_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getMainCategoryAction());
    loadDictionaries();
  },
});

export const MAIN_CATEGORY_DELETE_LOADING = "MAIN_CATEGORY_DELETE_LOADING";
export const MAIN_CATEGORY_DELETE_COMPLETE = "MAIN_CATEGORY_DELETE_COMPLETE";
export const MAIN_CATEGORY_DELETE_LOAD_ERROR =
  "MAIN_CATEGORY_DELETE_LOAD_ERROR";

// MAIN_CATEGORY DELETE
export const deleteMainCategoryAction = callApi({
  apiCall: (p) => deleteFlatEntityApi(p),
  secured: true,
  startAction: MAIN_CATEGORY_DELETE_LOADING,
  successAction: MAIN_CATEGORY_DELETE_COMPLETE,
  errorAction: MAIN_CATEGORY_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getMainCategoryAction());
  },
});
