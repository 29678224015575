import axios from "axios";
import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export const getUserListApi = async ({
  headers,
  page,
  group = "SUPER-ADMIN,ADMIN",
  params = null,
}) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.admin.list}/user?pageNum=${
      page || 1
    }&group=${group}${params ? `&${params.join("&")}` : ""}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const exportUserListApi = async ({
  headers,
  group = "SUPER-ADMIN,ADMIN",
  params = null,
}) => {
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  const tenant = getFromLocalStorage("lefacTenant").data;
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.admin.list}/user/export?group=${group}${
      params ? `&${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
        ...headers,
      },
    }
  );

  if (res.ok) {
    const response = await res.blob();
    return response;
  }
  const response = await res.json();
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getUserProfilesApi = async ({ headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.admin.list}/profile`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};
export const getPluginListApi = async ({ headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.admin.list}/plugin`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getTenantListApi = async ({ headers }) => {
  const res = await fetch(`${Config.apiBaseurl}/api/v1/tenant`, {
    method: "GET",
    headers,
  });

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const createUserApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.admin.detail}/user`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getUserDetailApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.admin.detail}/user/${id}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const editUserApi = async ({ headers, id, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.admin.detail}/user/${id}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const deleteUserApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.admin.detail}/user/${id}`,
    {
      method: "DELETE",
      headers,
    }
  );
  return res;
};

export const getUserConsumptionSinceDateApi = async ({
  tenant,
  startDate,
  userId,
}) => {
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  const res = await axios.get(
    `${Config.apiBaseurl}/${Config.apis.admin.detail}/user/${userId}/consumption`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
      },
      params: {
        startDate,
      },
    }
  );
  return res;
};

export const updateExtraCreditsApi = async ({
  companyCredits,
  contactCredits,
  userId,
}) => {
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacTenant").data;
  const token = ls && ls.token;
  const res = await axios.post(
    `${Config.apiBaseurl}/${Config.apis.extraCredits.update}/${userId}`,
    {
      companyCredits,
      contactCredits,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
      },
    }
  );
  return res;
};
