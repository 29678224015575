import { reducerFromMap } from "../libs/helpers/reducers";

import {
  AUTHORIZE_DONE,
  USERINFO_LOADED,
  USERINFO_LOADING,
} from "../actions/auth.actions";
import { USER_LOGOUT } from "../actions/app.actions";

const initialState = { user: {} };

const reducer = {
  [AUTHORIZE_DONE]: (state, actions) => {
    return {
      ...state,
      loading: false,
      // user: actions.data.user,
      // token: actions.data.token,
      ...actions.data,
    };
  },
  [USERINFO_LOADING]: (state, actions) => ({
    ...state,
    loading: true,
    // user: null,
  }),
  [USERINFO_LOADED]: (state, actions) => ({
    ...state,
    loading: false,
    user: actions.data,
  }),
  [USER_LOGOUT]: (state, actions) => {
    return {
      ...state,
      loading: false,
      user: null,
      token: null,
    };
  },
};

export default reducerFromMap(reducer);
