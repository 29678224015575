import React from "react";
import { Button, InputNumber, Spin, Table } from "antd";
import moment from "moment";
import Column from "antd/lib/table/Column";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toUpper } from "lodash";
import {
  CheckOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import EditOutlined from "@ant-design/icons/es/icons/EditOutlined";
import CloseOutlined from "@ant-design/icons/es/icons/CloseOutlined";
import Localize from "../../libs/localization";
import usePitchScores from "../../hooks/usePitchScores";
import CompanyAutocomplete from "../../components/Autocomplete/CompanyAutocomplete";
import { editPitchScoreApi } from "../../api/pitch.api";

function EditableScoreOverride({ row, reload }) {
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const [newOverride, setNewOverride] = React.useState("");

  const [saving, setSaving] = React.useState(false);

  const save = React.useCallback(
    async ({ shouldDelete = false } = {}) => {
      setSaving(true);
      try {
        await editPitchScoreApi({
          id: row._id,
          data: shouldDelete
            ? { scoreOverride: null }
            : {
                scoreOverride: newOverride,
              },
        });
      } catch (e) {
        window.setError(e);
      } finally {
        setNewOverride("");
        setSaving(false);
        reload();
        setEditing(false);
        setDeleting(false);
      }
    },
    [newOverride]
  );

  const renderScenario = () => {
    if (saving) return <Spin />;
    if (deleting)
      return (
        <>
          <span style={{ width: "30px" }}>
            {row.scoreOverride ? `${row.scoreOverride}%` : "--"}
          </span>
          <Button
            type="primary"
            shape="circle"
            danger
            onClick={(e) => save({ shouldDelete: true })}
            icon={<DeleteOutlined />}
          />
          <Button
            type="default"
            shape="circle"
            onClick={(e) => setDeleting(false)}
            icon={<CloseOutlined />}
          />
        </>
      );
    if (editing)
      return (
        <>
          <InputNumber
            value={newOverride}
            onChange={(value) => setNewOverride(value)}
          />
          <Button
            type="primary"
            shape="circle"
            onClick={(e) => save()}
            icon={<CheckOutlined />}
          />
          <Button
            type="default"
            shape="circle"
            onClick={(e) => setEditing(false)}
            icon={<CloseOutlined />}
          />
        </>
      );
    return (
      <>
        <span style={{ width: "30px" }}>
          {row.scoreOverride ? `${row.scoreOverride}%` : "--"}
        </span>
        <Button
          type="primary"
          shape="circle"
          onClick={(e) => setEditing(true)}
          icon={<EditOutlined />}
        />
        {row.scoreOverride ? (
          <Button
            type="primary"
            danger
            shape="circle"
            onClick={(e) => setDeleting(true)}
            icon={<DeleteOutlined />}
          />
        ) : null}
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {renderScenario()}
    </div>
  );
}

export default function PitchScoreListPage() {
  const {
    data,
    loading,
    options,
    setOptions,
    filters,
    setFilters,
    reload,
  } = usePitchScores();

  const sdMap = {
    asc: "ascend",
    desc: "descend",
  };

  return (
    <Table
      loading={loading}
      scroll={{ x: 1000 }}
      onChange={(pagination, filters_, sorter, extra) => {
        if (extra.action === "sort") {
          console.log("table onChange");
          if (sorter.order) {
            setOptions({
              ...options,
              sortBy: sorter.columnKey,
              sortDirection: sorter.order === "ascend" ? "asc" : "desc",
            });
          } else {
            setOptions({
              ...options,
              sortBy: undefined,
              sortDirection: undefined,
            });
          }
        }
      }}
      dataSource={data?.results?.map((o) => {
        return {
          company: o.company,
          category: o.category?.key || o._id?.category?.id,
          endDate: moment(o.latestEndDate).format("DD/MM/YYYY"),
          scope: o.scope,
          currentAgency: o.latestPitch.winnerCompany,
          contractScore: `${o.score}%`,
          scoreOverride: o.scoreOverride,
          _id: o._id,
        };
      })}
      pagination={{
        total: data?.pageInfo?.totalResults || 0,
        pageSize: options.pageSize,
        current: options.page,
        onChange: (page_, pageSize_) => {
          setOptions({ ...options, page: page_, pageSize: pageSize_ });
        },
      }}
    >
      <Column
        title={
          <span style={{ fontWeight: "bold" }}>
            {Localize("PAGES.PITCH_SCORES.FILTERS.COMPANY")}
          </span>
        }
        dataIndex="company"
        render={(company) => {
          return (
            <Link to={`/companies/${company?.id}`}>
              {toUpper(company.name || company.id)}
            </Link>
          );
        }}
        key="company"
        sortDirections={["ascend", "descend"]}
        sorter
        sortOrder={options.sortBy === "company" && sdMap[options.sortDirection]}
        filterIcon={(filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
        )}
        filterDropdown={() => {
          return (
            <div
              style={{
                padding: 8,
                width: "300px",
              }}
              role="presentation"
              onKeyDown={(e) => e.stopPropagation()}
            >
              <CompanyAutocomplete
                autoFocus={true}
                placeholder={Localize("PAGES.PITCH_SCORE.FILTERS.COMPANY")}
                onSelect={(value, opt) => {
                  setFilters({
                    ...filters,
                    companyId: [opt?.obj?.id],
                  });
                }}
                onClear={() => {
                  setFilters({
                    ...filters,
                    companyId: undefined,
                  });
                }}
                onChange={() => {}}
              />
            </div>
          );
        }}
      />
      <Column
        title={
          <span style={{ fontWeight: "bold" }}>
            {Localize("PAGES.PITCH_SCORES.FILTERS.CATEGORY")}
          </span>
        }
        dataIndex="category"
        key="category"
        sortDirections={["ascend", "descend"]}
        sorter
        sortOrder={
          options.sortBy === "category" && sdMap[options.sortDirection]
        }
      />
      <Column
        title={
          <span style={{ fontWeight: "bold" }}>
            {Localize("PAGES.PITCH_SCORES.FILTERS.END_DATE")}
          </span>
        }
        dataIndex="endDate"
        key="endDate"
        sortDirections={["ascend", "descend"]}
        sorter
        sortOrder={options.sortBy === "endDate" && sdMap[options.sortDirection]}
      />
      <Column
        title={
          <span style={{ fontWeight: "bold" }}>
            {Localize("PAGES.PITCH_SCORES.FILTERS.GEO_AREA")}
          </span>
        }
        dataIndex="scope"
        key="scope"
        sortDirections={["ascend", "descend"]}
        sorter
        sortOrder={options.sortBy === "scope" && sdMap[options.sortDirection]}
      />
      <Column
        title={
          <span style={{ fontWeight: "bold" }}>
            {Localize("PAGES.PITCH_SCORES.FILTERS.AGENCY")}
          </span>
        }
        dataIndex="currentAgency"
        render={(currentAgency) => {
          return (
            <Link to={`/companies/${currentAgency?.id}`}>
              {toUpper(currentAgency?.name || currentAgency?.id)}
            </Link>
          );
        }}
        key="currentAgency"
      />
      <Column
        title={
          <span style={{ fontWeight: "bold" }}>
            {Localize("PAGES.PITCH_SCORES.FILTERS.CONTRACT_SCORE")}
          </span>
        }
        dataIndex="contractScore"
        key="score"
        sortDirections={["ascend", "descend"]}
        sorter
        sortOrder={options.sortBy === "score" && sdMap[options.sortDirection]}
      />
      <Column
        title={
          <span style={{ fontWeight: "bold" }}>
            {Localize("PAGES.PITCH_SCORES.SCORE_OVERRIDE")}
          </span>
        }
        dataIndex="scoreOverride"
        key="scoreOverride"
        render={(value, row) => {
          return <EditableScoreOverride row={row} reload={reload} />;
        }}
        sortDirections={["ascend", "descend"]}
        sorter
        sortOrder={
          options.sortBy === "scoreOverride" && sdMap[options.sortDirection]
        }
      />
    </Table>
  );
}
