import { reducerFromMap } from "../libs/helpers/reducers";
import {
  getPaginatedProps,
  getDetailAndSchemaProps,
  getDetailProps,
} from "../libs/helpers/callApi";

import {
  BRAND_ENTITY_LOADING,
  BRAND_ENTITY_LOADED,
  BRAND_ENTITY_LOAD_ERROR,
  BRAND_LIST_LOADING,
  BRAND_LIST_LOADED,
  BRAND_LIST_LOAD_ERROR,
  BRAND_DETAIL_LOADING,
  BRAND_DETAIL_LOADED,
  BRAND_DETAIL_LOAD_ERROR,
  BRAND_COMPANY_LOADING,
  BRAND_COMPANY_LOADED,
  BRAND_COMPANY_LOAD_ERROR,
  BRAND_DETAIL_EDITING,
  BRAND_DETAIL_EDITED,
  BRAND_DETAIL_EDIT_ERROR,
} from "../actions/brand.actions";

const reducer = {
  /*
   * BRAND_ENTITY
   */
  [BRAND_ENTITY_LOADING]: (state) => ({
    ...state,
    entity: { loading: true },
  }),
  [BRAND_ENTITY_LOADED]: (state, { data }) => ({
    ...state,
    entity: {
      loading: false,
      schema: data,
    },
  }),
  [BRAND_ENTITY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    entity: { loading: false },
    error: data,
  }),
  /*
   * BRAND_LIST
   */
  [BRAND_LIST_LOADING]: (state) => ({
    ...state,
    list: { ...state.list, loading: true },
  }),
  [BRAND_LIST_LOADED]: (state, { data }) => ({
    ...state,
    list: { loading: false, ...getPaginatedProps(data) },
  }),
  [BRAND_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    list: { ...state.list, loading: false },
    error: data,
  }),
  /*
   * BRAND_DETAIL
   */
  [BRAND_DETAIL_LOADING]: (state) => ({
    ...state,
    detail: {
      ...state.detail,
      loading: true,
    },
  }),
  [BRAND_DETAIL_LOADED]: (state, { data }) => ({
    ...state,
    detail: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [BRAND_DETAIL_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*
   * BRAND_DETAIL
   */
  [BRAND_COMPANY_LOADING]: (state) => ({
    ...state,
    company: {
      ...state.company,
      loading: true,
    },
  }),
  [BRAND_COMPANY_LOADED]: (state, { data }) => ({
    ...state,
    company: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [BRAND_COMPANY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    company: { loading: false },
    error: data,
  }),
  [BRAND_DETAIL_EDITING]: (state) => ({
    ...state,
    detail: { ...state.detail, loading: true },
  }),
  [BRAND_DETAIL_EDITED]: (state, { data }) => ({
    ...state,
    detail: {
      ...state.detail,
      loading: false,
      ...getDetailProps(data),
    },
  }),
  [BRAND_DETAIL_EDIT_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, { list: {} });
