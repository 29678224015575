import callApi from "../libs/helpers/callApi";
import {
  getPitchEntityApi,
  getPitchDetailApi,
  editPitchDetailApi,
  createPitchDetailApi,
} from "../api/pitch.api";

export const PITCH_ENTITY_LOADING = "PITCH_ENTITY_LOADING";
export const PITCH_ENTITY_LOADED = "PITCH_ENTITY_LOADED";
export const PITCH_ENTITY_LOAD_ERROR = "PITCH_ENTITY_LOAD_ERROR";

export const getPitchEntityAction = callApi({
  apiCall: (p) => getPitchEntityApi(p),
  secured: true,
  startAction: PITCH_ENTITY_LOADING,
  successAction: PITCH_ENTITY_LOADED,
  errorAction: PITCH_ENTITY_LOAD_ERROR,
});

export const PITCH_DETAIL_LOADING = "PITCH_DETAIL_LOADING";
export const PITCH_DETAIL_LOADED = "PITCH_DETAIL_LOADED";
export const PITCH_DETAIL_LOAD_ERROR = "PITCH_DETAIL_LOAD_ERROR";

export const getPitchDetailAction = callApi({
  apiCall: (p) => getPitchDetailApi(p),
  secured: true,
  startAction: PITCH_DETAIL_LOADING,
  successAction: PITCH_DETAIL_LOADED,
  errorAction: PITCH_DETAIL_LOAD_ERROR,
});

/*export const PITCH_DETAIL_CREATING = "PITCH_DETAIL_CREATING";
export const PITCH_DETAIL_CREATED = "PITCH_DETAIL_CREATED";
export const PITCH_DETAIL_CREATE_ERROR = "PITCH_DETAIL_CREATE_ERROR";

export const createPitchDetailAction = callApi({
  apiCall: (p) => createPitchDetailApi(p),
  secured: true,
  startAction: PITCH_DETAIL_CREATING,
  successAction: PITCH_DETAIL_CREATED,
  errorAction: PITCH_DETAIL_CREATE_ERROR,
});*/

export const PITCH_DETAIL_EDITING = "PITCH_DETAIL_EDITING";
export const PITCH_DETAIL_EDITED = "PITCH_DETAIL_EDITED";
export const PITCH_DETAIL_EDIT_ERROR = "PITCH_DETAIL_EDIT_ERROR";

export const editPitchDetailAction = callApi({
  apiCall: (p) => editPitchDetailApi(p),
  secured: true,
  startAction: PITCH_DETAIL_EDITING,
  successAction: PITCH_DETAIL_EDITED,
  errorAction: PITCH_DETAIL_EDIT_ERROR,
});
