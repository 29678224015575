import callApi from "../libs/helpers/callApi";
import {
  createRssFeedApi,
  deleteRssFeedApi,
  editRssFeedApi,
  getRssFeedLisApi,
} from "../api/rssFeed.api";
import { goTo } from "./app.actions";

export const RSS_FEED_LIST_LOADING = "RSS_FEED_LIST_LOADING";
export const RSS_FEED_LIST_LOADED = "RSS_FEED_LIST_LOADED";
export const RSS_FEED_LIST_LOAD_ERROR = "RSS_FEED_LIST_LOAD_ERROR";

export const getRssFeedListAction = callApi({
  apiCall: (p) => getRssFeedLisApi(p),
  secured: true,
  startAction: RSS_FEED_LIST_LOADING,
  successAction: RSS_FEED_LIST_LOADED,
  errorAction: RSS_FEED_LIST_LOAD_ERROR,
});

export const RSS_FEED_CREATE_LOADING = "RSS_FEED_CREATE_LOADING";
export const RSS_FEED_CREATE_LOADED = "RSS_FEED_CREATE_LOADED";
export const RSS_FEED_CREATE_LOAD_ERROR = "RSS_FEED_CREATE_LOAD_ERROR";

export const createRssFeedAction = callApi({
  apiCall: (p) => createRssFeedApi(p),
  secured: true,
  startAction: RSS_FEED_CREATE_LOADING,
  successAction: RSS_FEED_CREATE_LOADED,
  errorAction: RSS_FEED_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch }) => {
    dispatch(getRssFeedListAction());
  },
});

export const RSS_FEED_DELETE_LOADING = "RSS_FEED_DELETE_LOADING";
export const RSS_FEED_DELETE_LOADED = "RSS_FEED_DELETE_LOADED";
export const RSS_FEED_LOAD_ERROR = "RSS_FEED_LOAD_ERROR";

export const deleteRssFeedAction = callApi({
  apiCall: (p) => deleteRssFeedApi(p),
  secured: true,
  startAction: RSS_FEED_DELETE_LOADING,
  successAction: RSS_FEED_DELETE_LOADED,
  errorAction: RSS_FEED_LOAD_ERROR,
  afterSuccessAction: ({ dispatch }) => {
    dispatch(getRssFeedListAction());
    dispatch(goTo("/settings/rss"));
  },
});

export const RSS_FEED_EDIT_LOADING = "RSS_FEED_EDIT_LOADING";
export const RSS_FEED_EDIT_LOADED = "RSS_FEED_EDIT_LOADED";
export const RSS_FEED_EDIT_LOAD_ERROR = "RSS_FEED_EDIT_LOAD_ERROR";

export const editRssFeedAction = callApi({
  apiCall: (p) => editRssFeedApi(p),
  secured: true,
  startAction: RSS_FEED_EDIT_LOADING,
  successAction: RSS_FEED_EDIT_LOADED,
  errorAction: RSS_FEED_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch }) => {
    dispatch(getRssFeedListAction());
    dispatch(goTo("/settings/rss"));
  },
});
