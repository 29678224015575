// import { Layout, Button } from "antd";
import React, { useState, useEffect } from "react";

import Localize from "../../components/Localized/Localize.comp";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";

import { Button } from "antd";

import "./system.style.scss";

const ErrorPage = ({ error, dismiss, forceLogin }) => {
  const [seeMore, setSeeMore] = useState(false);

  useEffect(() => {
    document.body.style = "overflow: hidden";
    setSeeMore(false);
    return () => (document.body.style = "");
  }, []);

  const { id, message, code } = error || {};

  return (
    <div className="system-error animation fadein-up fast">
      <div className="system-page">
        <div className="system-box animation fadein fast">
          <div className="logo">
            <Logo />
          </div>
          <div className="message-head">{Localize("ERROR.UNEXPECTED")}</div>
          {message && (
            <div className="message-error animation fadein fast">
              {code && id !== "USERNAME_TAKEN" && `(${code}): `}
              {message}
            </div>
          )}
          {id && id !== "USERNAME_TAKEN" && (
            <div className="code-error animation fadein fast">{id}</div>
          )}
          {typeof dismiss === "function" && (
            <Button
              onClick={() => {
                dismiss();
              }}
            >
              {Localize(`COMMON.OK`)}
            </Button>
          )}
          {/*
          <br />
          {!seeMore && (
            <Button
              hideHover={true}
              noBorder={true}
              onClick={() => setSeeMore(true)}
              size={"S"}
              label={Localize(`ERROR.WHAT_NOW`)}
            />
          )}
          {seeMore && (
            <>
              <div style={{ marginTop: 10 }}>
                {Localize("ERROR.WHAT_NOW_DESC")}
              </div>
               {code && (
                <div className="message error-box">
                  <code className="error-message">
                    id: {id}
                    <br />
                    {code}
                  </code>
                </div>
              )} 
              <div className="action">
                {typeof dismiss === "function" && (
                  <Button
                    primary={true}
                    onClick={dismiss}
                    size={"S"}
                    label={Localize(`ERROR.BACK_HOME`)}
                  />
                )}
                {typeof forceLogin === "function" && (
                  <Button
                    onClick={forceLogin}
                    style={{ marginTop: 20 }}
                    size={"S"}
                    label={Localize(`ERROR.LOGIN_AGAIN`)}
                  />
                )}
              </div>
            </>
          )}
              */}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

// const mapStateToProps = ({ app }) => ({
//   errorRedux: app.error,
// });

// export default connect(mapStateToProps, {})(ErrorPage);
