import React, { lazy } from "react";
import Config from "./Config";
import {
  HomeOutlined,
  ShopOutlined,
  ContactsOutlined,
  RocketOutlined,
  SettingOutlined,
  PercentageOutlined,
} from "@ant-design/icons";

// npm libs
// ---

// internal libs
import { type } from "./libs/router";
import { AdminLayout } from "./layouts";
import {
  removeFromLocalStorage,
  setOnLocalStorage,
} from "./libs/helpers/localstorage";

// components
import Loading from "./components/Loading/Loading";
import HomePage from "./pages/home/home.page";
import FontColorsOutlined from "@ant-design/icons/es/icons/FontColorsOutlined";
import Localize from "./components/Localized/Localize.comp";
import pitchScoreListPage from "./pages/pitchScore/pitchScore.list.page";
const CompanyListPage = lazy(() => import("./pages/company/company.list.page"));
const CompanyDetailPage = lazy(() =>
  import("./pages/company/company.detail.page")
);
const ContactListPage = lazy(() => import("./pages/contact/contact.list.page"));
const ContactDetailPage = lazy(() =>
  import("./pages/contact/contact.detail.page")
);
const BrandListPage = lazy(() => import("./pages/brand/brand.list.page"));
const BrandDetailPage = lazy(() => import("./pages/brand/brand.detail.page"));
const AuthPage = lazy(() => import("./pages/system/auth.page"));
const LoginPage = lazy(() => import("./pages/system/login.page"));
const Forbidden = lazy(() => import("./pages/system/forbidden.page"));
const SettingsPage = lazy(() => import("./pages/settings/settings.page"));
const PitchDetailPage = lazy(() => import("./pages/pitch/pitch.detail.page"));
const CreaDetailPage = lazy(() => import("./pages/crea/crea.detail.page"));
const RegistryPage = lazy(() => import("./pages/registry/registry.page"));
const AdminDetail = lazy(() => import("./components/Users/UserDetail"));
const RssDetail = lazy(() => import("./components/News/NewsDetail"));

export default [
  {
    id: "login",
    path: "/login",
    title: Localize("PAGES.LOGIN.TITLE"),
    noFollow: true,
    showInMenu: false,
    type: type.AUTHENTICATION,
    component: LoginPage,
    // component: ({ location }) => {
    //   const { search } = location;
    //   const URLparams = new URLSearchParams(search);
    //   const redirectUri = URLparams.get("redirectUri");
    //   setOnLocalStorage({ redirectUri });
    //   const loginUrl = `${Config.apiBaseurl}/${Config.apis.oauth.login}?redirect_uri=${Config.appBaseurl}/authorize`;
    //   window.location = loginUrl;
    //   return Loading({ coverBack: true });
    // },
  },
  {
    id: "authorize",
    path: "/authorize",
    title: Localize("PAGES.AUTHORIZE.TITLE"),
    noFollow: true,
    component: AuthPage,
    showInMenu: false,
    type: type.AUTHENTICATION,
  },
  {
    id: "logout",
    path: "/logout/do",
    showInMenu: false,
    noFollow: true,
    type: type.SECURED,
    component: () => {
      removeFromLocalStorage("user");
      removeFromLocalStorage("token");
      window.location = `${Config.apiBaseurl}/${Config.apis.oauth.logout}`;
      return Loading({ coverBack: true });
    },
  },
  {
    id: "root",
    path: "/",
    title: Localize("PAGES.HOME.TITLE"),
    showInMenu: false,
    icon: "home",
    redirectTo: "/home",
  },
  {
    id: "home",
    title: Localize("PAGES.HOME.TITLE"),
    path: "/home",
    component: HomePage,
    showInMenu: false,
    icon: <HomeOutlined />,
    layout: AdminLayout,
    type: type.SECURED,
  },
  {
    title: Localize("PAGES.COMPANIES.TITLE"),
    showInMenu: true,
    icon: <ShopOutlined />,
    id: "company_list",
    path: "/companies",
    component: CompanyListPage,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    exact: false,
    type: type.SECURED,
    scopes: ["company"],
  },
  {
    id: "company_detail",
    title: Localize("PAGES.COMPANIES.DETAIL.TITLE"),
    path: "/companies/:id",
    component: CompanyDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.SECURED,
    scopes: ["company"],
  },
  {
    title: Localize("PAGES.CONTACTS.TITLE"),
    showInMenu: true,
    icon: <ContactsOutlined />,
    type: type.SECURED,
    id: "contact_list",
    path: "/contacts",
    component: ContactListPage,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    scopes: ["contact"],
  },
  {
    id: "contact_detail",
    title: Localize("PAGES.CONTACTS.DETAIL.TITLE"),
    path: "/contacts/:id",
    component: ContactDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.SECURED,
    scopes: ["contact"],
  },
  {
    title: Localize("PAGES.BRANDS.TITLE"),
    icon: <RocketOutlined />,
    type: type.SECURED,
    id: "brand_list",
    path: "/brands",
    component: BrandListPage,
    showInMenu: true,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    scopes: ["brand"],
  },

  {
    id: "brand_detail",
    title: Localize("PAGES.BRANDS.DETAIL.TITLE"),
    path: "/brands/:id",
    component: BrandDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.SECURED,
    scopes: ["brand"],
  },
  {
    title: Localize("PAGES.PITCH_SCORES.TITLE"),
    icon: <PercentageOutlined />,
    type: type.SECURED,
    id: "pitch_scores",
    path: "/pitch-scores",
    component: pitchScoreListPage,
    showInMenu: true,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    scopes: ["pitchScores"],
  },
  {
    id: "pitch_detail",
    title: Localize("PAGES.PITCH.DETAIL.TITLE"),
    path: "/pitch/:id",
    component: PitchDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.SECURED,
    scopes: ["pitch"],
  },
  {
    id: "registry",
    title: Localize("PAGES.REGISTRY.TITLE"),
    path: "/registry",
    icon: <FontColorsOutlined />,
    component: RegistryPage,
    showInMenu: true,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.SECURED,
    scopes: ["registry"],
  },
  {
    title: Localize("PAGES.SETTINGS.TITLE"),
    icon: <SettingOutlined />,
    type: type.SECURED,
    id: "settings",
    path: "/settings/action",
    component: SettingsPage,
    showInMenu: true,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    scopes: ["setting"],
  },
  {
    title: Localize("PAGES.SETTINGS.TITLE"),
    icon: <SettingOutlined />,
    type: type.SECURED,
    id: "settings",
    path: "/settings/:setting",
    component: SettingsPage,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    scopes: ["setting"],
  },
  {
    id: "pitch_detail",
    title: Localize("PAGES.PITCH.DETAIL.TITLE"),
    path: "/pitch/:id",
    component: PitchDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.SECURED,
    scopes: ["pitch"],
  },
  {
    id: "crea_detail",
    title: Localize("PAGES.CREA.DETAIL.TITLE"),
    path: "/crea/:id",
    component: CreaDetailPage,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.SECURED,
    // scopes: ["crea"],
  },
  {
    id: "forbidden",
    title: Localize("LOGIN.FORBIDDEN"),
    path: "/forbidden",
    component: Forbidden,
    showInMenu: false,
    // layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.PUBLIC,
  },
  {
    title: Localize("PAGES.SETTINGS.TITLE"),
    type: type.SECURED,
    id: "userDetail",
    path: "/user/:type/:id",
    component: AdminDetail,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
  },
  {
    title: Localize("PAGES.SETTINGS.TITLE"),
    type: type.SECURED,
    id: "newsDetail",
    path: "/settings/rss/:id",
    component: RssDetail,
    showInMenu: false,
    layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
  },
];
