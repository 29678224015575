import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  SCOPE_LOADING,
  SCOPE_LOADED,
  SCOPE_LOAD_ERROR,
  SCOPE_CREATE_LOADING,
  SCOPE_CREATE_LOAD_ERROR,
  SCOPE_EDIT_LOADING,
  SCOPE_EDIT_COMPLETE,
  SCOPE_EDIT_LOAD_ERROR,
  SCOPE_DELETE_LOADING,
  SCOPE_DELETE_COMPLETE,
  SCOPE_DELETE_LOAD_ERROR,
  SCOPE_CREATE_COMPLETE,
} from "../../actions/registry/registry.scope.actions";

const reducer = {
  /*
   * SCOPE LIST
   */
  [SCOPE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SCOPE_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [SCOPE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SCOPE CREATE
   */
  [SCOPE_CREATE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SCOPE_CREATE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SCOPE_CREATE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SCOPE EDIT
   */
  [SCOPE_EDIT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SCOPE_EDIT_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SCOPE_EDIT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SCOPE DELETE
   */
  [SCOPE_DELETE_LOADING]: (state) => ({
    ...state,
    scope: { ...state.scope, loading: true },
  }),
  [SCOPE_DELETE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SCOPE_DELETE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    scope: { ...state.scope, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
