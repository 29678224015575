import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";

export const getFlatV2EntityListApi = async ({ headers, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.flatV2.list}?entity=${entity}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getFlatV2EntitySchemaApi = async ({ headers, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.flatV2.schema}?entity=${entity}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const createFlatV2EntityApi = async ({ headers, entity, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.flatV2.list
    }?entity=${encodeURIComponent(entity)}`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const editFlatV2EntityApi = async ({ headers, id, entity, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.flatV2.list
    }?entity=${encodeURIComponent(entity)}&id=${id}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify({
        key: data.key,
        parent: data.parent,
        active: data.active || undefined,
        translations: data.translations || undefined,
        extraFields: data.extraFields || undefined,
      }),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const deleteFlatV2EntityApi = async ({ headers, id, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.flatV2.list
    }?entity=${encodeURIComponent(entity)}&id=${id}`,
    {
      method: "DELETE",
      headers,
    }
  );
  return res;
};
