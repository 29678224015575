import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const INTERNATIONAL_INVEST_LOADING = "INTERNATIONAL_INVEST_LOADING";
export const INTERNATIONAL_INVEST_LOADED = "INTERNATIONAL_INVEST_LOADED";
export const INTERNATIONAL_INVEST_LOAD_ERROR =
  "INTERNATIONAL_INVEST_LOAD_ERROR";

// EMPLOYER RANGE GET LIST
export const getInternationalInvestAction = callApi({
  apiCall: (p) =>
    getFlatEntityListApi({ ...p, entity: "international_invest" }),
  secured: true,
  startAction: INTERNATIONAL_INVEST_LOADING,
  successAction: INTERNATIONAL_INVEST_LOADED,
  errorAction: INTERNATIONAL_INVEST_LOAD_ERROR,
});

export const INTERNATIONAL_INVEST_CREATE_LOADING =
  "INTERNATIONAL_INVEST_CREATE_LOADING";
export const INTERNATIONAL_INVEST_CREATE_COMPLETE =
  "INTERNATIONAL_INVEST_CREATE_COMPLETE";
export const INTERNATIONAL_INVEST_CREATE_LOAD_ERROR =
  "INTERNATIONAL_INVEST_CREATE_LOAD_ERROR";

// INTERNATIONAL_INVEST CREATE
export const createInternationalInvestDetailAction = callApi({
  apiCall: (p) => createFlatEntityApi(p),
  secured: true,
  startAction: INTERNATIONAL_INVEST_CREATE_LOADING,
  successAction: INTERNATIONAL_INVEST_CREATE_COMPLETE,
  errorAction: INTERNATIONAL_INVEST_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getInternationalInvestAction());
    loadDictionaries();
  },
});

export const INTERNATIONAL_INVEST_EDIT_LOADING =
  "INTERNATIONAL_INVEST_EDIT_LOADING";
export const INTERNATIONAL_INVEST_EDIT_COMPLETE =
  "INTERNATIONAL_INVEST_EDIT_COMPLETE";
export const INTERNATIONAL_INVEST_EDIT_LOAD_ERROR =
  "INTERNATIONAL_INVEST_EDIT_LOAD_ERROR";

// INTERNATIONAL_INVEST EDIT
export const editInternationalInvestAction = callApi({
  apiCall: (p) => editFlatEntityApi(p),
  secured: true,
  startAction: INTERNATIONAL_INVEST_EDIT_LOADING,
  successAction: INTERNATIONAL_INVEST_EDIT_COMPLETE,
  errorAction: INTERNATIONAL_INVEST_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getInternationalInvestAction());
    loadDictionaries();
  },
});

export const INTERNATIONAL_INVEST_DELETE_LOADING =
  "INTERNATIONAL_INVEST_DELETE_LOADING";
export const INTERNATIONAL_INVEST_DELETE_COMPLETE =
  "INTERNATIONAL_INVEST_DELETE_COMPLETE";
export const INTERNATIONAL_INVEST_DELETE_LOAD_ERROR =
  "INTERNATIONAL_INVEST_DELETE_LOAD_ERROR";

// INTERNATIONAL_INVEST DELETE
export const deleteInternationalInvestAction = callApi({
  apiCall: (p) => deleteFlatEntityApi(p),
  secured: true,
  startAction: INTERNATIONAL_INVEST_DELETE_LOADING,
  successAction: INTERNATIONAL_INVEST_DELETE_COMPLETE,
  errorAction: INTERNATIONAL_INVEST_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getInternationalInvestAction());
  },
});
