import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const INCOME_RANGE_LOADING = "INCOME_RANGE_LOADING";
export const INCOME_RANGE_LOADED = "INCOME_RANGE_LOADED";
export const INCOME_RANGE_LOAD_ERROR = "INCOME_RANGE_LOAD_ERROR";

// INCOME RANGE GET LIST
export const getIncomeRangeAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "income_range" }),
  secured: true,
  startAction: INCOME_RANGE_LOADING,
  successAction: INCOME_RANGE_LOADED,
  errorAction: INCOME_RANGE_LOAD_ERROR,
});

export const INCOME_RANGE_CREATE_LOADING = "INCOME_RANGE_CREATE_LOADING";
export const INCOME_RANGE_CREATE_COMPLETE = "INCOME_RANGE_CREATE_COMPLETE";
export const INCOME_RANGE_CREATE_LOAD_ERROR = "INCOME_RANGE_CREATE_LOAD_ERROR";

// INCOME_RANGE CREATE
export const createIncomeRangeDetailAction = callApi({
  apiCall: (p) => createFlatEntityApi(p),
  secured: true,
  startAction: INCOME_RANGE_CREATE_LOADING,
  successAction: INCOME_RANGE_CREATE_COMPLETE,
  errorAction: INCOME_RANGE_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getIncomeRangeAction());
    loadDictionaries();
  },
});

export const INCOME_RANGE_EDIT_LOADING = "INCOME_RANGE_EDIT_LOADING";
export const INCOME_RANGE_EDIT_COMPLETE = "INCOME_RANGE_EDIT_COMPLETE";
export const INCOME_RANGE_EDIT_LOAD_ERROR = "INCOME_RANGE_EDIT_LOAD_ERROR";

// INCOME_RANGE EDIT
export const editIncomeRangeAction = callApi({
  apiCall: (p) => editFlatEntityApi(p),
  secured: true,
  startAction: INCOME_RANGE_EDIT_LOADING,
  successAction: INCOME_RANGE_EDIT_COMPLETE,
  errorAction: INCOME_RANGE_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getIncomeRangeAction());
    loadDictionaries();
  },
});

export const INCOME_RANGE_DELETE_LOADING = "INCOME_RANGE_DELETE_LOADING";
export const INCOME_RANGE_DELETE_COMPLETE = "INCOME_RANGE_DELETE_COMPLETE";
export const INCOME_RANGE_DELETE_LOAD_ERROR = "INCOME_RANGE_DELETE_LOAD_ERROR";

// INCOME_RANGE DELETE
export const deleteIncomeRangeAction = callApi({
  apiCall: (p) => deleteFlatEntityApi(p),
  secured: true,
  startAction: INCOME_RANGE_DELETE_LOADING,
  successAction: INCOME_RANGE_DELETE_COMPLETE,
  errorAction: INCOME_RANGE_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getIncomeRangeAction());
  },
});
