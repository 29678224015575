import React, { useEffect, useRef, useState } from "react";
import { Button, Popover, TreeSelect } from "antd";
import { forEach, isNil, isEqual, includes, get } from "lodash";
import WarningOutlined from "@ant-design/icons/es/icons/WarningOutlined";
import { translateRegistryOnView } from "../../libs/utils";

import "./tree.scss";

const { SHOW_PARENT } = TreeSelect;

const Tree = (props) => {
  const {
    datatree,
    disabled,
    valueData,
    onChange,
    itemSchema,
    translateEntity,
    placeholder = "__",
    treeCheckable = true,
    onSelect = false,
  } = props;

  const [value, setValue] = useState(undefined);
  const [data, setData] = useState([]);
  const [disabledEl, setDisabledEl] = useState(null);
  const [showPopDisabled, setShowPopDisabled] = useState(false);
  const [currentDisabled, setCurrentDisabled] = useState([]);

  const onTreeChange = (value, e) => {
    setValue(value);
    if (typeof onSelect === "function") {
      onSelect(value, e);
    } else {
      onChange(itemSchema.key, value);
    }
  };

  const triggerChange = (value) => {
    onChange(itemSchema?.key, value);
  };

  const recursiveData = (array) => {
    const result = [];
    forEach(array, (el, k) => {
      const { key: title, _id: value, path, children } = el;
      // if (el.active) {
      result.push({
        title: translateRegistryOnView(value, translateEntity)
          ? translateRegistryOnView(value, translateEntity)
          : title,
        value,
        disabled: !el.active,
        key: value,
        path,
        children:
          children && children.length > 0 ? recursiveData(children) : [],
      });
      // }
    });
    return result;
  };

  const disabledList = [];
  const checkDisabled = (array) => {
    if (array.length > 0) {
      forEach(array, (el, k) => {
        const { _id: value, children } = el;
        if (!el.active) {
          disabledList.push(value);
        }
        if (children && children.length > 0) {
          checkDisabled(children);
        }
      });
      return disabledList;
    }
    return null;
  };

  useEffect(() => {
    if (datatree && data && data.length == 0) {
      const result = recursiveData(datatree);
      const dis = checkDisabled(datatree);
      if (result.length > 0) {
        setData(result);
      }
      if (!disabledEl) {
        setDisabledEl(dis);
      }
    }
  }, [datatree]);

  const prevValue = useRef();
  useEffect(() => {
    if (
      (!isNil(valueData) && !value && Array.isArray(disabledEl)) ||
      (!isEqual(prevValue.current, valueData) && Array.isArray(disabledEl))
    ) {
      const finalVal = [];
      const finalsDis = [];
      forEach(valueData, (v) => {
        if (!includes(disabledEl, v)) {
          finalVal.push(v);
        } else {
          finalsDis.push(v);
        }
      });
      prevValue.current = valueData;

      onTreeChange(finalVal);
      triggerChange(finalVal);
      setCurrentDisabled(finalsDis);
    }
  }, [valueData, value, disabledEl]);

  const tProps = {
    treeData: data,
    value,
    onChange: onTreeChange,
    treeCheckable,
    showCheckedStrategy: SHOW_PARENT,
    placeholder,
    style: {
      width: "100%",
    },
  };

  const tPropsDisable = {
    treeData: data,
    onChange: onTreeChange,
    treeCheckable,
    showCheckedStrategy: SHOW_PARENT,
    placeholder,
    style: {
      width: "100%",
    },
  };

  return (
    <div className="treeSelect">
      {currentDisabled.length > 0 && (
        <Popover
          content={
            disabledEl && (
              <>
                <TreeSelect
                  {...tPropsDisable}
                  value={currentDisabled}
                  disabled={true}
                />
              </>
            )
          }
          trigger="click"
          visible={showPopDisabled}
          onVisibleChange={setShowPopDisabled}
        >
          <Button
            style={{
              color: "red",
              fontSize: "1rem",
              padding: 0,
              position: "absolute",
              top: 0,
              right: "12px",
            }}
            type="link"
          >
            <WarningOutlined />
          </Button>
        </Popover>
      )}
      {data.length > 0 ? <TreeSelect {...tProps} disabled={disabled} /> : null}
    </div>
  );
};

export default Tree;
