import React, { Fragment } from "react";
import moment from "moment";
import {
  Input,
  Tag,
  DatePicker,
  Select,
  Radio,
  Image,
  Upload,
  Tooltip,
  Button,
  Popover,
  Switch,
  AutoComplete,
} from "antd";
import { find, groupBy, isNil, orderBy } from "lodash";

import {
  LinkOutlined,
  PlusOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  StopOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Localize from "../Localized/Localize.comp";
import { translateRegistryOnView, turnKeyIntoLabel } from "../../libs/utils";
import Led from "../Led/Led";
import Placeholders from "../Placeholders";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import { Link } from "react-router-dom";
import Financials from "../Financials/Financials";
import FlatV2Picker from "../FlatV2Picker";

const { TextArea } = Input;
const { Option, OptGroup } = Select;
const { Dragger } = Upload;

const tenant = getFromLocalStorage("lefacTenant");

export const renderDetailItem = ({
  idx = Math.round(Math.random() * 1000),
  item,
  itemSchema,
  placeholder = "",
  disabled,
  error,
  datasource,
  onChange,
  onBlur = (key, value) => key && value,
  imageUploadOptions = {
    headers: null,
    url: null,
    callback: null,
    disabled,
    onDelete: null,
    readOnly: false,
    uploadUrl: null,
  },
  customRenderer,
}) => {
  if (
    [
      "id",
      "_id",
      "created_at",
      "modified_at",
      "modified_by",
      "deleted_at",
    ].indexOf(itemSchema.key) >= 0
  ) {
    disabled = true;
  }
  if (customRenderer && typeof customRenderer === "function") {
    return customRenderer({ item, onChange, disabled, itemSchema });
  }
  switch (itemSchema.type) {
    case "Boolean":
      return disabled ? (
        <span
          style={{
            height: 32,
            marginBottom: 5,
            display: "table",
          }}
        >
          <span
            style={{
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            <Led on={item} type="success" style={{ marginRight: 10 }} />
          </span>
          <span
            style={{
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            {item ? Localize("COMMON.YES") : Localize("COMMON.NO")}
          </span>
        </span>
      ) : (
        <Switch
          style={{ marginTop: 5 }}
          checked={!isNil(item) ? item : true}
          onChange={(value) => {
            if (typeof onChange === "function") {
              onChange(itemSchema.key, value);
            }
          }}
        />
      );
    case "Link":
      return (
        <>
          {Array.isArray(item) &&
            item.map((i, k) =>
              disabled
                ? i &&
                  !i.disabled &&
                  (i.link || !disabled) && (
                    <Tag
                      key={k}
                      className={i.link ? "filled tag-bg" : "empty tag-bg"}
                    >
                      <LinkOutlined />
                      <a
                        href={i.link}
                        target="_Blank"
                        style={{ paddingLeft: 5 }}
                      >
                        {i.label ? turnKeyIntoLabel(i.label) : i.link}
                      </a>
                      {!disabled && <CloseOutlined />}
                    </Tag>
                  )
                : !i.disabled && (
                    <div
                      key={k}
                      style={{
                        float: "left",
                        width: "33%",
                        padding: "10px 20px",
                      }}
                    >
                      <LinkOutlined />{" "}
                      {i.label ? turnKeyIntoLabel(i.label) : i.link}
                      <Input
                        style={{ width: "100%" }}
                        value={i.link}
                        size="small"
                        allowClear
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          if (typeof onChange === "function" && !disabled) {
                            item[k].link = value
                              .trim()
                              .replace(
                                /\f\n\r\t\v​\u00a0\u1680​\u2000​-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff/g,
                                ""
                              );
                            onChange(itemSchema.key, item);
                          }
                        }}
                      />
                    </div>
                  )
            )}
        </>
      );
    case "Image":
      return (
        <Upload
          name="file"
          listType="picture-card"
          disabled={imageUploadOptions.disabled || item}
          showUploadList={false}
          action={imageUploadOptions.uploadUrl && imageUploadOptions.uploadUrl}
          headers={{
            ...imageUploadOptions.headers,
            "x-tenant": tenant ? tenant.data : "italy",
          }}
          onChange={(info) => {
            if (info.file.status === "uploading") {
              return;
            }
            if (info.file.status === "done") {
              const { response } = info.file;
              typeof imageUploadOptions.callback === "function" &&
                imageUploadOptions.callback(response);
            }
          }}
        >
          {item ? (
            <Popover
              trigger={imageUploadOptions.readOnly ? [] : "click"}
              placement="left"
              content={
                !imageUploadOptions.readOnly && (
                  <Button
                    disabled={!item || !disabled}
                    size="small"
                    onClick={() => imageUploadOptions.onDelete(itemSchema.key)}
                  >
                    <DeleteOutlined />
                    {Localize("COMMON.DELETE")}
                  </Button>
                )
              }
            >
              <img
                src={`${imageUploadOptions.url}`}
                alt={itemSchema.key}
                style={{ width: "100%", opacity: disabled ? 1 : 0.2 }}
              />
            </Popover>
          ) : (
            <Placeholders.ImgPlaceholder
              style={{ width: "100%", opacity: disabled ? 1 : 0.4 }}
            />
          )}
        </Upload>
      );
    case "Enum":
      const renderOptions = () => {
        const hasParent = !!(itemSchema.enum && itemSchema.enum?.[0]?.parent);
        if (hasParent) {
          const group = groupBy(itemSchema.enum, "parent");

          return Object.keys(group).map((g, k) => {
            // CHECK if there is almost 1 element active in group
            const findActive = find(group[g], (o) => o.active);
            if (findActive) {
              return (
                <OptGroup key={k} label={g}>
                  {group[g].map((el, jl) => {
                    if (el.active) {
                      return (
                        <Option key={`${g}-${jl}`} value={el.id}>
                          {translateRegistryOnView(el.id, itemSchema.key, true)
                            ? translateRegistryOnView(
                                el.id,
                                itemSchema.key,
                                true
                              )
                            : el.id}
                        </Option>
                      );
                    }
                  })}
                </OptGroup>
              );
            }
          });
        }

        return (orderBy(itemSchema.enum, "id") || []).map((e, j) => {
          if (e.active) {
            return (
              <Option key={j} value={e.id}>
                {translateRegistryOnView(e.id, itemSchema.key, true)
                  ? translateRegistryOnView(e.id, itemSchema.key, true)
                  : e.id}
              </Option>
            );
          }
        });
      };

      return (
        <Select
          value={item}
          style={{ width: "100%" }}
          disabled={disabled}
          placeholder={disabled ? "—" : ""}
          onChange={(value) =>
            typeof onChange === "function" &&
            !disabled &&
            onChange(itemSchema.key, value)
          }
          showSearch
        >
          {renderOptions()}
        </Select>
      );
    case "Set":
      return (
        <>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder={disabled ? "—" : ""}
            disabled={disabled}
            value={item}
            onChange={(value) =>
              typeof onChange === "function" &&
              !disabled &&
              onChange(itemSchema.key, value)
            }
            options={(itemSchema.set || [])
              .map((s, j) => {
                if (s.active) {
                  return {
                    disabled: s.id === "",
                    value: s.id,
                    label: translateRegistryOnView(s.id, itemSchema.key, true)
                      ? translateRegistryOnView(s.id, itemSchema.key, true)
                      : s.id,
                    key: j,
                  };
                }
              })
              .filter((i) => typeof i === "object")}
          />
        </>
      );
    case "Date":
      return (
        <DatePicker
          placeholder={disabled ? "—" : ""}
          // onChange={onChange}
          value={item && moment(item)}
          format="DD-MM-YYYY"
          disabled={disabled}
          style={{ marginBottom: 5 }}
          onChange={(value) => {
            if (typeof onChange === "function" && !disabled) {
              onChange(itemSchema.key, value);
            }
          }}
        />
      );
    case "RO_User":
      return item?.full_name ? (
        <Link to={`/user/s_admin/${item?.id}`}>{item?.full_name}</Link>
      ) : null;
    case "User":
      return item?.full_name ? (
        <Link to={`/user/s_admin/${item?.id}`}>{item?.full_name}</Link>
      ) : null;
    case "FinancialHistory":
      return (
        <Financials
          dataSource={item}
          editable={!disabled}
          onChange={(value) => {
            if (typeof onChange === "function" && !disabled) {
              onChange(itemSchema.key, value);
            }
          }}
        ></Financials>
      );
    default:
      if (itemSchema.type?.startsWith("FlatV2/")) {
        return (
          <FlatV2Picker
            value={item}
            onChange={(value) => onChange(itemSchema.key, value)}
            entityName={itemSchema.type.split("/")[1]}
            disabled={disabled}
          />
        );
      }
      if (itemSchema.type?.startsWith("FlatV2Multi/")) {
        let displayExtraField = null;
        if (itemSchema.type.split("/")[1] === "CollectiveAgreement") {
          displayExtraField = "identifier";
        }
        return (
          <FlatV2Picker
            multi
            value={item}
            onChange={(value) => onChange(itemSchema.key, value)}
            entityName={itemSchema.type.split("/")[1]}
            disabled={disabled}
            {...{ displayExtraField }}
          />
        );
      }
      if (Array.isArray(item)) {
        return item.map((i, k) => (
          <Fragment key={k}>
            {renderDetailItem({
              idx: k,
              item: i,
              itemSchema,
              disabled,
              error,
              onChange,
              onBlur,
              repeating: item.length,
            })}
          </Fragment>
        ));
      }
      return (itemSchema.key === "website" ||
        itemSchema.key === "linkedin" ||
        itemSchema.key === "xing") &&
        disabled ? (
        <a href={item} target="_blank">
          {item}
        </a>
      ) : (
        // togliere gli "a capo" all'onchange
        <TextArea
          // <Input
          className={error && "input-error"}
          value={
            disabled &&
            itemSchema.key === "tel" &&
            (!item || parseInt(item) === 0)
              ? placeholder
              : parseInt(item) !== 0
              ? item
              : ""
          }
          placeholder={disabled ? "—" : placeholder}
          style={{
            marginBottom: 5,
            textAlign: item && item.length > 100 ? "justify" : "left",
          }}
          disabled={disabled}
          cols={item && item.length > 300 ? 100 : null}
          onChange={(e) => {
            const {
              target: { value },
            } = e;
            if (typeof onChange === "function" && !disabled) {
              onChange(
                itemSchema.key,
                value.replace(
                  /\f\n\r\t\v​\u00a0\u1680​\u2000​-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff/g,
                  ""
                ),
                itemSchema.type === "Number" && /^\d+$/g
              );
            }
          }}
          onBlur={(e) => {
            const {
              target: { value },
            } = e;
            onBlur(itemSchema.key, value);
          }}
          autoSize
        />
        // />
      );
  }
};

export const checkIfNeedsRender = (schema, type) => {
  if (type === "CREATE") {
    return (
      schema.type !== "Image" &&
      schema.type !== "Link" &&
      schema.key !== "created_at" &&
      schema.key !== "modified_at" &&
      schema.key !== "deleted_at" &&
      schema.key !== "modified_by"
    );
  }
  if (type === "PITCH") {
    return (
      schema.key !== "created_at" &&
      schema.key !== "modified_at" &&
      schema.key !== "modified_by" &&
      schema.key !== "deleted_at" &&
      schema.key !== "company"
    );
  }
  if (type === "COMPANY") {
    return (
      // schema.key !== "created_at" &&
      // schema.key !== "modified_at" &&
      schema.key !== "deleted_at"
    );
  }
  if (type === "BRAND_CREATE") {
    return (
      schema.key !== "created_at" &&
      schema.key !== "modified_at" &&
      schema.key !== "modified_by" &&
      schema.key !== "deleted_at" &&
      schema.key !== "company" &&
      schema.type !== "Image" &&
      schema.type !== "Link"
    );
  }
  if (type === "BRAND") {
    return (
      schema.key !== "created_at" &&
      schema.key !== "modified_at" &&
      schema.key !== "modified_by" &&
      schema.key !== "deleted_at" &&
      schema.type !== "Image" &&
      schema.key !== "company"
    );
  }
  if (type === "CONTACT") {
    return (
      // schema.key !== "created_at" &&
      // schema.key !== "modified_at" &&
      schema.key !== "deleted_at" &&
      schema.key !== "company" &&
      schema.key !== "companyType"
    );
  }
  if (type === "CREA") {
    return (
      schema.key !== "created_at" &&
      schema.key !== "modified_at" &&
      schema.key !== "modified_by" &&
      schema.key !== "deleted_at" &&
      schema.key !== "snapshot_url" &&
      schema.key !== "company"
    );
  }
  if (type === "LOGO") {
    return schema.key == "logo";
  }
};

export const googlePlacesParser = (place) => {
  const keyParse = [
    "street_number",
    "route",
    "locality",
    "postal_town",
    "country",
    "administrative_area_level_3",
    "administrative_area_level_2",
    "administrative_area_level_1",
    "postal_code",
  ];

  const obj = {};

  keyParse.forEach((val) => {
    const _place = find(place, (p) => {
      return p.types.includes(val);
    });
    obj[val] = _place ? _place.short_name : null;
  });

  return obj;
};
