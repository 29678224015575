import {
  FLAT_LIST_LOADED,
  FLAT_LIST_LOADING,
  FLAT_LIST_LOAD_ERROR,
} from "../actions/flat.actions";
import { reducerFromMap } from "../libs/helpers/reducers";

const reducer = {
  /*
   * FLAT ENTITY LIST
   */
  [FLAT_LIST_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [FLAT_LIST_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    list: data,
  }),
  [FLAT_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, { list: [] });
