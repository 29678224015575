import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const MEDIUM_PLANNING_LOADING = "MEDIUM_PLANNING_LOADING";
export const MEDIUM_PLANNING_LOADED = "MEDIUM_PLANNING_LOADED";
export const MEDIUM_PLANNING_LOAD_ERROR = "MEDIUM_PLANNING_LOAD_ERROR";

// EMPLOYER RANGE GET LIST
export const getMediumPlanningAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "medium_planning" }),
  secured: true,
  startAction: MEDIUM_PLANNING_LOADING,
  successAction: MEDIUM_PLANNING_LOADED,
  errorAction: MEDIUM_PLANNING_LOAD_ERROR,
});

export const MEDIUM_PLANNING_CREATE_LOADING = "MEDIUM_PLANNING_CREATE_LOADING";
export const MEDIUM_PLANNING_CREATE_COMPLETE =
  "MEDIUM_PLANNING_CREATE_COMPLETE";
export const MEDIUM_PLANNING_CREATE_LOAD_ERROR =
  "MEDIUM_PLANNING_CREATE_LOAD_ERROR";

// MEDIUM_PLANNING CREATE
export const createMediumPlanningDetailAction = callApi({
  apiCall: (p) => createFlatEntityApi(p),
  secured: true,
  startAction: MEDIUM_PLANNING_CREATE_LOADING,
  successAction: MEDIUM_PLANNING_CREATE_COMPLETE,
  errorAction: MEDIUM_PLANNING_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getMediumPlanningAction());
    loadDictionaries();
  },
});

export const MEDIUM_PLANNING_EDIT_LOADING = "MEDIUM_PLANNING_EDIT_LOADING";
export const MEDIUM_PLANNING_EDIT_COMPLETE = "MEDIUM_PLANNING_EDIT_COMPLETE";
export const MEDIUM_PLANNING_EDIT_LOAD_ERROR =
  "MEDIUM_PLANNING_EDIT_LOAD_ERROR";

// MEDIUM_PLANNING EDIT
export const editMediumPlanningAction = callApi({
  apiCall: (p) => editFlatEntityApi(p),
  secured: true,
  startAction: MEDIUM_PLANNING_EDIT_LOADING,
  successAction: MEDIUM_PLANNING_EDIT_COMPLETE,
  errorAction: MEDIUM_PLANNING_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getMediumPlanningAction());
    loadDictionaries();
  },
});

export const MEDIUM_PLANNING_DELETE_LOADING = "MEDIUM_PLANNING_DELETE_LOADING";
export const MEDIUM_PLANNING_DELETE_COMPLETE =
  "MEDIUM_PLANNING_DELETE_COMPLETE";
export const MEDIUM_PLANNING_DELETE_LOAD_ERROR =
  "MEDIUM_PLANNING_DELETE_LOAD_ERROR";

// MEDIUM_PLANNING DELETE
export const deleteMediumPlanningAction = callApi({
  apiCall: (p) => deleteFlatEntityApi(p),
  secured: true,
  startAction: MEDIUM_PLANNING_DELETE_LOADING,
  successAction: MEDIUM_PLANNING_DELETE_COMPLETE,
  errorAction: MEDIUM_PLANNING_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getMediumPlanningAction());
  },
});
