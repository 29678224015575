import { reducerFromMap } from "../libs/helpers/reducers";
import {
  getPaginatedProps,
  getDetailAndSchemaProps,
  getDetailProps,
} from "../libs/helpers/callApi";

import {
  CREA_ENTITY_LOADING,
  CREA_ENTITY_LOADED,
  CREA_ENTITY_LOAD_ERROR,
  CREA_DETAIL_LOADING,
  CREA_DETAIL_LOAD_ERROR,
  CREA_DETAIL_LOADED,
  CREA_SNAPSHOT_LOADING,
  CREA_SNAPSHOT_LOADED,
  CREA_SNAPSHOT_LOAD_ERROR,
  CREA_DETAIL_EDITING,
  CREA_DETAIL_EDIT_ERROR,
  CREA_DETAIL_EDITED,
  /*  CREA_DETAIL_LOADING,
  CREA_DETAIL_LOADED,
  CREA_DETAIL_LOAD_ERROR,
  CREA_DETAIL_EDITING,
  CREA_DETAIL_EDITED,
  CREA_DETAIL_EDIT_ERROR,*/
  // CREA_DETAIL_CREATING,
  // CREA_DETAIL_CREATED,
  // CREA_DETAIL_CREATE_ERROR,
} from "../actions/crea.actions";

const reducer = {
  /*
   * CREA_ENTITY
   */
  [CREA_ENTITY_LOADING]: (state) => ({
    ...state,
    entity: { loading: true },
  }),
  [CREA_ENTITY_LOADED]: (state, { data }) => ({
    ...state,
    entity: {
      loading: false,
      schema: data,
    },
  }),
  [CREA_ENTITY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    entity: { loading: false },
    error: data,
  }),
  /* /!*
   * CREA_LIST
   *!/
  [CREA_LIST_LOADING]: (state) => ({
    ...state,
    list: { ...state.list, loading: true },
  }),
  [CREA_LIST_LOADED]: (state, { data }) => ({
    ...state,
    list: { loading: false, ...getPaginatedProps(data) },
  }),
  [CREA_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    list: { ...state.list, loading: false },
    error: data,
  }),*/

  /*
   * CREA_DETAIL
   */
  [CREA_DETAIL_LOADING]: (state) => ({
    ...state,
    detail: {
      ...state.detail,
      loading: true,
    },
  }),
  [CREA_DETAIL_LOADED]: (state, { data }) => ({
    ...state,
    detail: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [CREA_DETAIL_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*
   * CREA_DETAIL SNAPSHOT
   */
  [CREA_SNAPSHOT_LOADING]: (state) => ({
    ...state,
    snapshot: {
      ...state.snapshot,
      loading: true,
    },
  }),
  [CREA_SNAPSHOT_LOADED]: (state, { data }) => {
    return {
      ...state,
      snapshot: {
        loading: false,
        src: data,
      },
    };
  },
  [CREA_SNAPSHOT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    snapshot: { loading: false },
    error: data,
  }),
  /*
   * CREA_DETAIL_EDIT
   */
  [CREA_DETAIL_EDITING]: (state) => ({
    ...state,
    detail: { ...state.detail, loading: true },
  }),
  [CREA_DETAIL_EDITED]: (state, { data }) => ({
    ...state,
    detail: {
      ...state.detail,
      loading: false,
      ...getDetailProps(data),
    },
  }),
  [CREA_DETAIL_EDIT_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, { list: {} });
