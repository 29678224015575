import callApi from "../../libs/helpers/callApi";
import {
  createRegistryTreeDetailApi,
  deleteRegistryTreeApi,
  editRegistryTreeDetailApi,
  getTreeDataApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const CREDITS_LOADING = "CREDITS_LOADING";
export const CREDITS_LOADED = "CREDITS_LOADED";
export const CREDITS_LOAD_ERROR = "CREDITS_LOAD_ERROR";

// CREDITS LIST
export const getTreeCreditsAction = callApi({
  apiCall: (p) => getTreeDataApi({ ...p, entity: "Credits" }),
  secured: true,
  startAction: CREDITS_LOADING,
  successAction: CREDITS_LOADED,
  errorAction: CREDITS_LOAD_ERROR,
});

export const CREDITS_CREATE_LOADING = "CREDITS_CREATE_LOADING";
export const CREDITS_CREATE_COMPLETE = "CREDITS_CREATE_COMPLETE";
export const CREDITS_CREATE_LOAD_ERROR = "CREDITS_CREATE_LOAD_ERROR";

// CREDITS CREATE
export const createTreeCreditsDetailAction = callApi({
  apiCall: (p) => createRegistryTreeDetailApi(p),
  secured: true,
  startAction: CREDITS_CREATE_LOADING,
  successAction: CREDITS_CREATE_COMPLETE,
  errorAction: CREDITS_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getTreeCreditsAction());
    loadDictionaries();
  },
});

export const CREDITS_EDIT_LOADING = "CREDITS_EDIT_LOADING";
export const CREDITS_EDIT_COMPLETE = "CREDITS_EDIT_COMPLETE";
export const CREDITS_EDIT_LOAD_ERROR = "CREDITS_EDIT_LOAD_ERROR";

// CREDITS EDIT
export const editTreeCreditsDetailAction = callApi({
  apiCall: (p) => editRegistryTreeDetailApi(p),
  secured: true,
  startAction: CREDITS_EDIT_LOADING,
  successAction: CREDITS_EDIT_COMPLETE,
  errorAction: CREDITS_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getTreeCreditsAction());
    loadDictionaries();
  },
});

export const CREDITS_DELETE_LOADING = "CREDITS_DELETE_LOADING";
export const CREDITS_DELETE_COMPLETE = "CREDITS_DELETE_COMPLETE";
export const CREDITS_DELETE_LOAD_ERROR = "CREDITS_DELETE_LOAD_ERROR";

// CREDITS DELETE
export const deleteTreeCreditsAction = callApi({
  apiCall: (p) => deleteRegistryTreeApi(p),
  secured: true,
  startAction: CREDITS_DELETE_LOADING,
  successAction: CREDITS_DELETE_COMPLETE,
  errorAction: CREDITS_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getTreeCreditsAction());
  },
});
