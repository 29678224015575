import AppInfo from "./libs/helpers/appInfo";

const dev = {
  env: "dev",
  label: "Development",
  logger: true,
};

const test = {
  env: "test",
  label: "Test",
  logger: true,
};

const prod = {
  env: "prod",
  label: "Staging",
  logger: false,
};

const apis = {
  v1: "api/v1",
  oauth: {
    login: "api/v1/auth/login",
    callback: "api/v1/auth/token",
    logout: "api/v1/auth/logout",
    me: "api/v1/user/me",
  },
  company: {
    list: "api/v1/company",
    getManyById: "api/v1/company/ids",
    detail: "api/v1/company",
    generateContactUsernames: "api/v1/company/contact-email-usernames",
    generateContactDomains: "api/v1/company/contact-email-domains",
  },
  contact: {
    list: "api/v1/contact",
    detail: "api/v1/contact",
  },
  brand: {
    list: "api/v1/brand",
    detail: "api/v1/brand",
  },
  pitch: {
    list: "api/v1/pitch",
    detail: "api/v1/pitch",
  },
  crea: {
    list: "api/v1/crea",
    detail: "api/v1/crea",
  },
  tree: {
    list: "api/v1/tree",
    detail: "api/v1/tree",
  },
  flat: {
    list: "api/v1/flat",
    detail: "api/v1/flat",
  },
  flatV2: {
    list: "api/v1/flatV2",
    schema: "api/v1/flatV2/schema",
    detail: "api/v1/flatV2",
  },
  admin: {
    list: "api/v1/admin",
    detail: "api/v1/admin",
  },
  rssFeed: {
    list: "api/v1/rssFeeder",
    detail: "api/v1/rssFeeder",
  },
  nielsen: {
    export: "api/v1/nielsen/export",
  },
  investmentRound: {
    api: "api/v1/investmentRounds",
  },
  utils: {
    stats: "api/v1/utils/exportDataStats",
    specificExtraction: "api/v1/utils/exportSpecificData",
    specificCompanyExtraction: "api/v1/utils/exportSpecificCompanyData",
    loginReport: "api/v1/admin/reports/login",
  },
  entityNews: {
    create: "api/v1/entity-news",
    update: "api/v1/entity-news",
    delete: "api/v1/entity-news",
    getForEntity: "api/v1/entity-news",
  },
  extraCredits: {
    update: "api/v1/ecommerce/extra-credits",
  },
};
const env_config = function () {
  switch (AppInfo.env) {
    case "production":
      return prod;
    case "test":
      return test;
    case "development":
      return dev;
    default:
      return dev;
  }
};

const Config = {
  // Add common config values here
  // start: new Date(),
  app_name: AppInfo.name,
  app_version: AppInfo.version,
  // supported_languages: ["EN", "IT"],
  regex: {
    passwordRules: [
      {
        id: "LENGHT",
        regex: ".{8,}",
      },
      {
        id: "CASE",
        regex: "[a-z]+[A-Z]+|[A-Z]+[a-z]+",
      },
      {
        id: "NUM",
        regex: "[0-9]",
      },
      {
        id: "SPECIAL",
        regex: "[.#$+\\-_!*()%]",
      },
    ],
    email:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    Letters: /[a-z A-Z\-'\s]/g,
    FirstLetter: /^[a-z A-Z]/g,
    Last: /[a-zA-Z']\b/g,
    Only: /(['-/])\1/g,
    NoSubstring: /-/g,
    sdi: /[A-Za-z0-9]/,
    year: /^\d{4}$/g,
    piva: /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}[A-Z]{1}|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?([0-9]{7}[A-Z]{1}|[0-9]{1}[A-Z]{1}[0-9]{5}[A-Z]{1}|[0-9]{6}[A-Z]{2})|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NO)?[0-9]{9}MVA|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10}|(ES)?([A-Z]{1}[0-9]{8}|[0-9]{8}[A-Z]{1}|[A-Z]{1}[0-9]{7}[A-Z]{1})|(CHE)?[0-9]{9}(MWST|TVA|IVA))$/,
  },
  apis,
  ...env_config(),
  start: new Date().toISOString(),
  logger: false,
  localeCookieName: "locale",
  ...window.config,
};

export default Config;
