import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const EMPLOYER_RANGE_LOADING = "EMPLOYER_RANGE_LOADING";
export const EMPLOYER_RANGE_LOADED = "EMPLOYER_RANGE_LOADED";
export const EMPLOYER_RANGE_LOAD_ERROR = "EMPLOYER_RANGE_LOAD_ERROR";

// EMPLOYER RANGE GET LIST
export const getEmployerRangeAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "employer_range" }),
  secured: true,
  startAction: EMPLOYER_RANGE_LOADING,
  successAction: EMPLOYER_RANGE_LOADED,
  errorAction: EMPLOYER_RANGE_LOAD_ERROR,
});

export const EMPLOYER_RANGE_CREATE_LOADING = "EMPLOYER_RANGE_CREATE_LOADING";
export const EMPLOYER_RANGE_CREATE_COMPLETE = "EMPLOYER_RANGE_CREATE_COMPLETE";
export const EMPLOYER_RANGE_CREATE_LOAD_ERROR =
  "EMPLOYER_RANGE_CREATE_LOAD_ERROR";

// EMPLOYER_RANGE CREATE
export const createEmployerRangeDetailAction = callApi({
  apiCall: (p) => createFlatEntityApi(p),
  secured: true,
  startAction: EMPLOYER_RANGE_CREATE_LOADING,
  successAction: EMPLOYER_RANGE_CREATE_COMPLETE,
  errorAction: EMPLOYER_RANGE_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getEmployerRangeAction());
    loadDictionaries();
  },
});

export const EMPLOYER_RANGE_EDIT_LOADING = "EMPLOYER_RANGE_EDIT_LOADING";
export const EMPLOYER_RANGE_EDIT_COMPLETE = "EMPLOYER_RANGE_EDIT_COMPLETE";
export const EMPLOYER_RANGE_EDIT_LOAD_ERROR = "EMPLOYER_RANGE_EDIT_LOAD_ERROR";

// EMPLOYER_RANGE EDIT
export const editEmployerRangeAction = callApi({
  apiCall: (p) => editFlatEntityApi(p),
  secured: true,
  startAction: EMPLOYER_RANGE_EDIT_LOADING,
  successAction: EMPLOYER_RANGE_EDIT_COMPLETE,
  errorAction: EMPLOYER_RANGE_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getEmployerRangeAction());
    loadDictionaries();
  },
});

export const EMPLOYER_RANGE_DELETE_LOADING = "EMPLOYER_RANGE_DELETE_LOADING";
export const EMPLOYER_RANGE_DELETE_COMPLETE = "EMPLOYER_RANGE_DELETE_COMPLETE";
export const EMPLOYER_RANGE_DELETE_LOAD_ERROR =
  "EMPLOYER_RANGE_DELETE_LOAD_ERROR";

// EMPLOYER_RANGE DELETE
export const deleteEmployerRangeAction = callApi({
  apiCall: (p) => deleteFlatEntityApi(p),
  secured: true,
  startAction: EMPLOYER_RANGE_DELETE_LOADING,
  successAction: EMPLOYER_RANGE_DELETE_COMPLETE,
  errorAction: EMPLOYER_RANGE_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getEmployerRangeAction());
  },
});
