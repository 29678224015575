import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";

export const getTreeDataApi = async ({ headers, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.tree.list}?entity=${entity}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const createRegistryTreeDetailApi = async ({
  headers,
  entity,
  data,
}) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.tree.list}?entity=${encodeURIComponent(
      entity
    )}`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};
export const editRegistryTreeDetailApi = async ({
  headers,
  id,
  entity,
  data,
}) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.tree.list
    }/${id}?entity=${encodeURIComponent(entity)}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const deleteRegistryTreeApi = async ({ headers, id, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.tree.list
    }/${id}?entity=${encodeURIComponent(entity)}`,
    {
      method: "DELETE",
      headers,
    }
  );
  return res;
};

export const getFlatEntityListApi = async ({ headers, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.flat.list}?entity=${entity}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const createFlatEntityApi = async ({ headers, entity, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.flat.list}?entity=${encodeURIComponent(
      entity
    )}`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};
export const editFlatEntityApi = async ({ headers, id, entity, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.flat.list
    }?entity=${entity}&id=${encodeURIComponent(id)}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const deleteFlatEntityApi = async ({ headers, id, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.flat.list
    }?entity=${entity}&id=${encodeURIComponent(id)}`,
    {
      method: "DELETE",
      headers,
    }
  );
  return res;
};
