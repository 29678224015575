import callApi from "../../libs/helpers/callApi";
import {
  createRegistryTreeDetailApi,
  deleteRegistryTreeApi,
  editRegistryTreeDetailApi,
  getTreeDataApi,
} from "../../api/registry.api";
import { mergeSectorApi } from "../../api/company.api";
import { openSectorMergedNotification } from "../../pages/registry/_sectors.partial";
import { loadDictionaries } from "../../locales";

export const SECTOR_LOADING = "SECTOR_LOADING";
export const SECTOR_LOADED = "SECTOR_LOADED";
export const SECTOR_LOAD_ERROR = "SECTOR_LOAD_ERROR";

// SECTOR
export const getTreeSectorAction = callApi({
  apiCall: (p) => getTreeDataApi({ ...p, entity: "Sector" }),
  secured: true,
  startAction: SECTOR_LOADING,
  successAction: SECTOR_LOADED,
  errorAction: SECTOR_LOAD_ERROR,
});

export const SECTOR_CREATE_LOADING = "SECTOR_CREATE_LOADING";
export const SECTOR_CREATE_COMPLETE = "SECTOR_CREATE_COMPLETE";
export const SECTOR_CREATE_LOAD_ERROR = "SECTOR_CREATE_LOAD_ERROR";

// SECTOR CREATE
export const createTreeSectorDetailAction = callApi({
  apiCall: (p) => createRegistryTreeDetailApi(p),
  secured: true,
  startAction: SECTOR_CREATE_LOADING,
  successAction: SECTOR_CREATE_COMPLETE,
  errorAction: SECTOR_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getTreeSectorAction());
    loadDictionaries();
  },
});

export const SECTOR_EDIT_LOADING = "SECTOR_EDIT_LOADING";
export const SECTOR_EDIT_COMPLETE = "SECTOR_EDIT_COMPLETE";
export const SECTOR_EDIT_LOAD_ERROR = "SECTOR_EDIT_LOAD_ERROR";

// SECTOR EDIT
export const editTreeSectorDetailAction = callApi({
  apiCall: (p) => editRegistryTreeDetailApi(p),
  secured: true,
  startAction: SECTOR_EDIT_LOADING,
  successAction: SECTOR_EDIT_COMPLETE,
  errorAction: SECTOR_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getTreeSectorAction());
    loadDictionaries();
  },
});

export const SECTOR_DELETE_LOADING = "SECTOR_DELETE_LOADING";
export const SECTOR_DELETE_COMPLETE = "SECTOR_DELETE_COMPLETE";
export const SECTOR_DELETE_LOAD_ERROR = "SECTOR_DELETE_LOAD_ERROR";

// SECTOR DELETE
export const deleteTreeSectorAction = callApi({
  apiCall: (p) => deleteRegistryTreeApi(p),
  secured: true,
  startAction: SECTOR_DELETE_LOADING,
  successAction: SECTOR_DELETE_COMPLETE,
  errorAction: SECTOR_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getTreeSectorAction());
  },
});

export const SECTOR_MERGE_LOADING = "SECTOR_MERGE_LOADING";
export const SECTOR_MERGE_COMPLETE = "SECTOR_MERGE_COMPLETE";
export const SECTOR_MERGE_LOAD_ERROR = "SECTOR_MERGE_LOAD_ERROR";

export const mergeSectorAction = callApi({
  apiCall: (p) => mergeSectorApi(p),
  secured: true,
  startAction: SECTOR_MERGE_LOADING,
  successAction: SECTOR_MERGE_COMPLETE,
  errorAction: SECTOR_MERGE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getTreeSectorAction());
    openSectorMergedNotification();
  },
});
