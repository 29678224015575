import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import history from "../store/history";

import auth from "./auth.reducer";
import app from "./app.reducer";
import company from "./company.reducer";
import contact from "./contact.reducer";
import brand from "./brand.reducer";
import pitch from "./pitch.reducer";
import sector from "./registry/registry.sector.reducer";
import category from "./registry/registry.category.reducer";
import income_range from "./registry/registry.income_range.reducer";
import employer_range from "./registry/registry.employer_range.reducer";
import medium_planning from "./registry/registry.medium_planning.reducer";
import international_invest from "./registry/registry.international_invest.reducer";
import other_categories from "./registry/registry.other_categories.reducer";
import scope from "./registry/registry.scope.reducer";
import source from "./registry/registry.source.reducer";
import main_category from "./registry/registry.main_category.reducer";
import functionReg from "./registry/registry.function.reducer";
import credits from "./registry/registry.credits.reducer";
import crea_category from "./registry/registry.crea_category.reducer";
import user from "./user.reducer";
import crea from "./crea.reducer";
import rssFeed from "./rssFeed.reducer";
import flat from "./flat.reducer";
import flatV2 from "./flatV2.reducer";
import group from "./registry/registry.secondary_group.reducer";
import InvestmentRoundFundingType from "./registry/registry.funding_type.reducer";
import investmentRound from "./investmentRound.reducer";

const rootReducer = combineReducers({
  router: connectRouter(history),
  app,
  auth,
  company,
  contact,
  brand,
  pitch,
  sector,
  category,
  income_range,
  employer_range,
  medium_planning,
  international_invest,
  other_categories,
  credits,
  scope,
  source,
  main_category,
  functionReg,
  user,
  crea,
  crea_category,
  rssFeed,
  flat,
  flatV2,
  group,
  InvestmentRoundFundingType,
  investmentRound,
});

export default rootReducer;
