import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const CREA_CATEGORY_LOADING = "CREA_CATEGORY_LOADING";
export const CREA_CATEGORY_LOADED = "CREA_CATEGORY_LOADED";
export const CREA_CATEGORY_LOAD_ERROR = "CREA_CATEGORY_LOAD_ERROR";

// CREA_CATEGORY GET LIST
export const getCreaCategoryAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "crea_category" }),
  secured: true,
  startAction: CREA_CATEGORY_LOADING,
  successAction: CREA_CATEGORY_LOADED,
  errorAction: CREA_CATEGORY_LOAD_ERROR,
});

export const CREA_CATEGORY_CREATE_LOADING = "CREA_CATEGORY_CREATE_LOADING";
export const CREA_CATEGORY_CREATE_COMPLETE = "CREA_CATEGORY_CREATE_COMPLETE";
export const CREA_CATEGORY_CREATE_LOAD_ERROR =
  "CREA_CATEGORY_CREATE_LOAD_ERROR";

// CREA_CATEGORY CREATE
export const createCreaCategoryDetailAction = callApi({
  apiCall: (p) => createFlatEntityApi(p),
  secured: true,
  startAction: CREA_CATEGORY_CREATE_LOADING,
  successAction: CREA_CATEGORY_CREATE_COMPLETE,
  errorAction: CREA_CATEGORY_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getCreaCategoryAction());
    loadDictionaries();
  },
});

export const CREA_CATEGORY_EDIT_LOADING = "CREA_CATEGORY_EDIT_LOADING";
export const CREA_CATEGORY_EDIT_COMPLETE = "CREA_CATEGORY_EDIT_COMPLETE";
export const CREA_CATEGORY_EDIT_LOAD_ERROR = "CREA_CATEGORY_EDIT_LOAD_ERROR";

// CREA_CATEGORY EDIT
export const editCreaCategoryAction = callApi({
  apiCall: (p) => editFlatEntityApi(p),
  secured: true,
  startAction: CREA_CATEGORY_EDIT_LOADING,
  successAction: CREA_CATEGORY_EDIT_COMPLETE,
  errorAction: CREA_CATEGORY_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getCreaCategoryAction());
    loadDictionaries();
  },
});

export const CREA_CATEGORY_DELETE_LOADING = "CREA_CATEGORY_DELETE_LOADING";
export const CREA_CATEGORY_DELETE_COMPLETE = "CREA_CATEGORY_DELETE_COMPLETE";
export const CREA_CATEGORY_DELETE_LOAD_ERROR =
  "CREA_CATEGORY_DELETE_LOAD_ERROR";

// CREA_CATEGORY DELETE
export const deleteCreaCategoryAction = callApi({
  apiCall: (p) => deleteFlatEntityApi(p),
  secured: true,
  startAction: CREA_CATEGORY_DELETE_LOADING,
  successAction: CREA_CATEGORY_DELETE_COMPLETE,
  errorAction: CREA_CATEGORY_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getCreaCategoryAction());
  },
});
