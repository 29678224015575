import { reducerFromMap } from "../../libs/helpers/reducers";
import {
  getPaginatedProps,
  getDetailAndSchemaProps,
  getDetailProps,
} from "../../libs/helpers/callApi";

import {
  /*  SECTOR_LOADING,
  SECTOR_LOADED,
  SECTOR_LOAD_ERROR,
  SECTOR_EDIT_LOADING,
  SECTOR_EDIT_COMPLETE,
  SECTOR_EDIT_LOAD_ERROR,*/
  CREDITS_LOADING,
  CREDITS_LOADED,
  CREDITS_LOAD_ERROR,
  /*  SECTOR_DELETE_LOADING,
  SECTOR_DELETE_COMPLETE,
  SECTOR_DELETE_LOAD_ERROR,
  SECTOR_CREATE_LOADING,
  SECTOR_CREATE_COMPLETE,
  SECTOR_CREATE_LOAD_ERROR,*/
} from "../../actions/registry/registry.credits.actions";

const reducer = {
  /*
   *  CREDITS LIST
   */
  [CREDITS_LOADING]: (state) => ({
    ...state,
    credits: { ...state.credits, loading: true },
  }),
  [CREDITS_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [CREDITS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    credits: { ...state.credits, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
