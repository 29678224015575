import { useState, useEffect, useCallback } from "react";
import usePermissions from "./usePermissions";
import { getPitchScoresApi } from "../api/pitch.api";

const PAGE_SIZE = 20;

export default function usePitchScores({
  baseFilters = {},
  failSilently = false,
} = {}) {
  const [options, setOptions] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    sortBy: "score",
    sortDirection: "desc",
  });
  const [filters, setFilters] = useState({ ...baseFilters });

  const [pitchScoresData, setPitchScoresData] = useState(null);
  const [pitchScoresLoading, setPitchScoresLoading] = useState(false);

  const permissions = usePermissions();
  console.log("usePitchScores render");

  // eslint-disable-next-line no-shadow
  const getPitchScores = useCallback(async () => {
    console.log("getPitchScores");
    const { page, pageSize, sortBy, sortDirection } = options;
    setPitchScoresLoading(true);
    try {
      const sortFieldMap = {
        company: "company",
        category: "pitchCategory",
        endDate: "latestEndDate",
        scope: "pitchScope",
        score: "pitchScore",
      };
      const data = await getPitchScoresApi({
        data: {
          page,
          pageSize,
          sortBy: sortBy ? sortFieldMap[sortBy] || sortBy : sortBy,
          sortDirection,
          ...filters,
        },
      });
      setPitchScoresData(data);
      setPitchScoresLoading(false);
    } catch (e) {
      console.error(e);
      if (!failSilently) window.setError(e);
    }
  }, [filters, options]);

  useEffect(() => {
    console.log("usePitchScores effect");
    if (permissions.pitchScores) {
      getPitchScores();
    } else {
      console.log(
        "not getting pitch scores cause lacking permission",
        permissions
      );
    }
  }, [getPitchScores, permissions]);

  return {
    data: pitchScoresData,
    loading: pitchScoresLoading,
    options,
    setOptions,
    filters,
    setFilters,
    reload: getPitchScores,
  };
}
