import { reducerFromMap } from "../libs/helpers/reducers";

import {
  ADMIN_DETAIL_LOAD_ERROR,
  ADMIN_DETAIL_LOADED,
  ADMIN_DETAIL_LOADING,
  ADMIN_LIST_LOADING,
  ADMIN_LIST_LOADED,
  ADMIN_LIST_LOAD_ERROR,
  ADMIN_PROFILES_LOADING,
  ADMIN_PROFILES_LOADED,
  ADMIN_PROFILES_LOAD_ERROR,
  TENANT_LIST_LOADING,
  TENANT_LIST_LOAD_ERROR,
  TENANT_LIST_LOADED,
  SADMIN_LIST_LOADED,
  SADMIN_LIST_LOAD_ERROR,
  SADMIN_LIST_LOADING,
  CUSTOMER_LIST_LOADING,
  CUSTOMER_LIST_LOADED,
  CUSTOMER_LIST_LOAD_ERROR,
  PLUGIN_LIST_LOADING,
  PLUGIN_LIST_LOADED,
  PLUGIN_LIST_LOAD_ERROR,
} from "../actions/user.actions";
import {
  getDetailAndSchemaProps,
  getPaginatedProps,
} from "../libs/helpers/callApi";

const reducer = {
  /*
   * ADMIN _ENTITY
   */
  [ADMIN_DETAIL_LOADING]: (state) => ({
    ...state,
    detail: { data: {}, loading: true },
  }),
  [ADMIN_DETAIL_LOADED]: (state, { data }) => ({
    ...state,
    detail: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [ADMIN_DETAIL_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*
   * ADMIN_LIST
   */
  [ADMIN_LIST_LOADING]: (state) => ({
    ...state,
    adminList: { ...state.userList, loading: true },
  }),
  [ADMIN_LIST_LOADED]: (state, { data }) => ({
    ...state,
    adminList: { loading: false, ...getPaginatedProps(data) },
    detail: { data: {} },
  }),
  [ADMIN_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    adminList: { ...state.userList, loading: false },
    error: data,
  }),
  /*
   * SADMIN_LIST
   */
  [SADMIN_LIST_LOADING]: (state) => ({
    ...state,
    sadminList: { ...state.userList, loading: true },
  }),
  [SADMIN_LIST_LOADED]: (state, { data }) => ({
    ...state,
    sadminList: { loading: false, ...getPaginatedProps(data) },
    // detail: { data: {} },
  }),
  [SADMIN_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    sadminList: { ...state.userList, loading: false },
    error: data,
  }),
  /*
   * CUSTOMER_LIST
   */
  [CUSTOMER_LIST_LOADING]: (state) => ({
    ...state,
    customerList: { ...state.customerList, loading: true },
  }),
  [CUSTOMER_LIST_LOADED]: (state, { data }) => ({
    ...state,
    customerList: { loading: false, ...getPaginatedProps(data) },
    detail: { data: {} },
  }),
  [CUSTOMER_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    customerList: { ...state.customerList, loading: false },
    error: data,
  }),
  /*
   * ADMIN_PROFILES
   */
  [ADMIN_PROFILES_LOADING]: (state) => ({
    ...state,
    profiles: { ...state.profiles, loading: true },
  }),
  [ADMIN_PROFILES_LOADED]: (state, { data }) => ({
    ...state,
    profiles: { loading: false, ...getDetailAndSchemaProps(data) },
  }),
  [ADMIN_PROFILES_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    profiles: { ...state.profiles, loading: false },
    error: data,
  }),
  /*
   * TENANT_LIST
   */
  [TENANT_LIST_LOADING]: (state) => ({
    ...state,
    tenant: { ...state.tenant, loading: true },
  }),
  [TENANT_LIST_LOADED]: (state, { data }) => ({
    ...state,
    tenant: { loading: false, ...getDetailAndSchemaProps(data) },
  }),
  [TENANT_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    tenant: { ...state.tenant, loading: false },
    error: data,
  }),
  /*
   * PLUGIN_LIST
   */
  [PLUGIN_LIST_LOADING]: (state) => ({
    ...state,
    plugin: { ...state.plugin, loading: true },
  }),
  [PLUGIN_LIST_LOADED]: (state, { data }) => ({
    ...state,
    plugin: { loading: false, ...getDetailAndSchemaProps(data) },
  }),
  [PLUGIN_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    plugin: { ...state.plugin, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  adminList: {},
  customerList: {},
  sadminList: {},
  profiles: { data: [] },
  tenant: { data: [] },
  plugin: {},
  detail: { data: {} },
});
