import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  INTERNATIONAL_INVEST_LOADING,
  INTERNATIONAL_INVEST_LOADED,
  INTERNATIONAL_INVEST_LOAD_ERROR,
  INTERNATIONAL_INVEST_CREATE_LOADING,
  INTERNATIONAL_INVEST_CREATE_LOAD_ERROR,
  INTERNATIONAL_INVEST_EDIT_LOADING,
  INTERNATIONAL_INVEST_EDIT_COMPLETE,
  INTERNATIONAL_INVEST_EDIT_LOAD_ERROR,
  INTERNATIONAL_INVEST_DELETE_LOADING,
  INTERNATIONAL_INVEST_DELETE_COMPLETE,
  INTERNATIONAL_INVEST_DELETE_LOAD_ERROR,
  INTERNATIONAL_INVEST_CREATE_COMPLETE,
} from "../../actions/registry/registry.international_invest.actions";

const reducer = {
  /*
   * INTERNATIONAL_INVEST LIST
   */
  [INTERNATIONAL_INVEST_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [INTERNATIONAL_INVEST_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [INTERNATIONAL_INVEST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * INTERNATIONAL_INVEST CREATE
   */
  [INTERNATIONAL_INVEST_CREATE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [INTERNATIONAL_INVEST_CREATE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [INTERNATIONAL_INVEST_CREATE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * INTERNATIONAL_INVEST EDIT
   */
  [INTERNATIONAL_INVEST_EDIT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [INTERNATIONAL_INVEST_EDIT_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [INTERNATIONAL_INVEST_EDIT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * INTERNATIONAL_INVEST DELETE
   */
  [INTERNATIONAL_INVEST_DELETE_LOADING]: (state) => ({
    ...state,
    international_invest: { ...state.international_invest, loading: true },
  }),
  [INTERNATIONAL_INVEST_DELETE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [INTERNATIONAL_INVEST_DELETE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    international_invest: { ...state.international_invest, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
