import { reducerFromMap } from "../libs/helpers/reducers";
import {
  getPaginatedProps,
  getDetailAndSchemaProps,
  getDetailProps,
  getSchemaProps,
} from "../libs/helpers/callApi";

import {
  COMPANY_ENTITY_LOADING,
  COMPANY_ENTITY_LOADED,
  COMPANY_ENTITY_LOAD_ERROR,
  COMPANY_LIST_LOADING,
  COMPANY_LIST_LOADED,
  COMPANY_LIST_LOAD_ERROR,
  COMPANY_DETAIL_LOADING,
  COMPANY_DETAIL_LOADED,
  COMPANY_DETAIL_LOAD_ERROR,
  COMPANY_DETAIL_CREATING,
  COMPANY_DETAIL_CREATED,
  COMPANY_DETAIL_CREATE_ERROR,
  COMPANY_DETAIL_EDITING,
  COMPANY_DETAIL_EDITED,
  COMPANY_DETAIL_EDIT_ERROR,
  COMPANY_CONTACTS_LOADING,
  COMPANY_CONTACTS_LOADED,
  COMPANY_CONTACTS_LOAD_ERROR,
  COMPANY_BRANDS_LOADING,
  COMPANY_BRANDS_LOADED,
  COMPANY_BRANDS_LOAD_ERROR,
  COMPANY_NIELSEN_LOADING,
  COMPANY_NIELSEN_LOADED,
  COMPANY_NIELSEN_LOAD_ERROR,
  UPLOAD_NIELSEN_LOADING,
  UPLOAD_NIELSEN_COMPLETE,
  UPLOAD_NIELSEN_ERROR,
  COMPANY_PITCH_LOADING,
  COMPANY_PITCH_LOAD_ERROR,
  COMPANY_PITCH_LOADED,
  COMPANY_CREA_LOADING,
  COMPANY_CREA_LOADED,
  COMPANY_CREA_LOAD_ERROR,
  COMPANY_ACQUIRE_CONTACTS_LOADING,
  COMPANY_ACQUIRE_CONTACTS_LOADED,
  COMPANY_ACQUIRE_CONTACTS_LOAD_ERROR,
  COMPANY_ACQUIRE_BRANDS_LOADING,
  COMPANY_ACQUIRE_BRANDS_LOADED,
  COMPANY_ACQUIRE_BRANDS_LOAD_ERROR,
  COMPANY_MERGE_LOADING,
  COMPANY_MERGE_LOADED,
  COMPANY_MERGE_LOAD_ERROR,
  UPLOAD_FINANCIALS_LOADING,
  UPLOAD_FINANCIALS_COMPLETE,
  UPLOAD_FINANCIALS_ERROR,
  EXPORT_COMPANY_LOADING,
  EXPORT_COMPANY_LOADED,
  EXPORT_COMPANY_LOAD_ERROR,
  DOWNLOAD_NIELSEN_FILE_LOADING,
  DOWNLOAD_NIELSEN_FILE_LOADED,
  DOWNLOAD_NIELSEN_FILE_LOAD_ERROR,
  DOWNLOAD_DATA_STATS_FILE_LOADING,
  DOWNLOAD_DATA_STATS_FILE_LOADED,
  DOWNLOAD_DATA_STATS_FILE_LOAD_ERROR,
  COMPANY_GROUP_LOADING,
  COMPANY_GROUP_LOADED,
  COMPANY_GROUP_LOAD_ERROR,
  DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOADING,
  DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOADED,
  DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOAD_ERROR,
  DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOADING,
  DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOADED,
  DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOAD_ERROR,
} from "../actions/company.actions";

const reducer = {
  /*
   * COMPANY_ENTITY
   */
  [COMPANY_ENTITY_LOADING]: (state) => ({
    ...state,
    entity: { loading: true },
  }),
  [COMPANY_ENTITY_LOADED]: (state, { data }) => ({
    ...state,
    entity: {
      loading: false,
      schema: data,
    },
  }),
  [COMPANY_ENTITY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    entity: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_LIST
   */
  [COMPANY_LIST_LOADING]: (state) => ({
    ...state,
    list: { ...state.list, loading: true },
  }),
  [COMPANY_LIST_LOADED]: (state, { data }) => ({
    ...state,
    list: {
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    list: { ...state.list, loading: false },
    error: data,
  }),
  /*
   * COMPANY_DETAIL
   */
  [COMPANY_DETAIL_LOADING]: (state) => ({
    ...state,
    detail: {
      // ...state.detail,
      loading: true,
    },
  }),
  [COMPANY_DETAIL_LOADED]: (state, { data }) => ({
    ...state,
    detail: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [COMPANY_DETAIL_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_DETAIL
   */
  [COMPANY_DETAIL_CREATING]: (state) => ({
    ...state,
    detail: { ...state.detail, loading: true },
  }),
  [COMPANY_DETAIL_CREATED]: (state, { data }) => ({
    ...state,
    detail: {
      ...state.detail,
      loading: false,
      ...getDetailProps(data),
    },
  }),
  [COMPANY_DETAIL_CREATE_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_DETAIL
   */
  [COMPANY_DETAIL_EDITING]: (state) => ({
    ...state,
    detail: { ...state.detail, loading: true },
  }),
  [COMPANY_DETAIL_EDITED]: (state, { data }) => ({
    ...state,
    detail: {
      ...state.detail,
      loading: false,
      ...getDetailProps(data),
    },
  }),
  [COMPANY_DETAIL_EDIT_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_CONTACTS
   */
  [COMPANY_CONTACTS_LOADING]: (state) => ({
    ...state,
    contacts: { loading: true },
  }),
  [COMPANY_CONTACTS_LOADED]: (state, { data }) => ({
    ...state,
    contacts: {
      ...state.contacts,
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_CONTACTS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    contacts: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_BRANDS
   */
  [COMPANY_BRANDS_LOADING]: (state) => ({
    ...state,
    brands: { loading: true },
  }),
  [COMPANY_BRANDS_LOADED]: (state, { data }) => ({
    ...state,
    brands: {
      ...state.brands,
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_BRANDS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    brands: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_NIELSEN
   */
  [COMPANY_NIELSEN_LOADING]: (state) => ({
    ...state,
    nielsen: { loading: true },
  }),
  [COMPANY_NIELSEN_LOADED]: (state, { data }) => ({
    ...state,
    nielsen: {
      ...state.nielsen,
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_NIELSEN_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    nielsen: { loading: false },
    error: data,
  }),
  /*
   * UPLOAD FILE NIELSEN
   */
  [UPLOAD_NIELSEN_LOADING]: (state) => ({
    ...state,
    nielsen: { loading: true },
  }),
  [UPLOAD_NIELSEN_COMPLETE]: (state, { data }) => ({
    ...state,
    nielsen: { loading: false, response: data.status },
  }),
  [UPLOAD_NIELSEN_ERROR]: (state, { data }) => ({
    ...state,
    nielsen: { loading: false },
    error: data,
  }),
  /*
   * UPLOAD FILE FINANCIALS
   */
  [UPLOAD_FINANCIALS_LOADING]: (state) => ({
    ...state,
    financials: { loading: true },
  }),
  [UPLOAD_FINANCIALS_COMPLETE]: (state, { data }) => ({
    ...state,
    financials: { loading: false, response: data.status },
  }),
  [UPLOAD_FINANCIALS_ERROR]: (state, { data }) => ({
    ...state,
    financials: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_PITCHS
   */
  [COMPANY_PITCH_LOADING]: (state) => ({
    ...state,
    pitch: { loading: true },
  }),
  [COMPANY_PITCH_LOADED]: (state, { data }) => ({
    ...state,
    pitch: {
      ...state.pitch,
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_PITCH_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    pitch: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_CREAS
   */
  [COMPANY_CREA_LOADING]: (state) => ({
    ...state,
    crea: { loading: true },
  }),
  [COMPANY_CREA_LOADED]: (state, { data }) => ({
    ...state,
    crea: {
      ...state.crea,
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [COMPANY_CREA_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    crea: { loading: false },
    error: data,
  }),
  /*
   * COMPANY_ACQUIRE_CONTACTS
   */
  [COMPANY_ACQUIRE_CONTACTS_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [COMPANY_ACQUIRE_CONTACTS_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [COMPANY_ACQUIRE_CONTACTS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * COMPANY_ACQUIRE_BRANDS
   */
  [COMPANY_ACQUIRE_BRANDS_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [COMPANY_ACQUIRE_BRANDS_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [COMPANY_ACQUIRE_BRANDS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * COMPANY_MERGE
   */
  [COMPANY_MERGE_LOADING]: (state) => ({
    ...state,
    merge: { loading: true },
  }),
  [COMPANY_MERGE_LOADED]: (state, { data }) => ({
    ...state,
    merge: { loading: false },
  }),
  [COMPANY_MERGE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    merge: { loading: false },
    error: data,
  }),
  /*
   * EXPORT COMPANY
   */
  [EXPORT_COMPANY_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [EXPORT_COMPANY_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [EXPORT_COMPANY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * DOWNLOAD NIELSEN FILE
   */
  [DOWNLOAD_NIELSEN_FILE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [DOWNLOAD_NIELSEN_FILE_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [DOWNLOAD_NIELSEN_FILE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * DOWNLOAD DATA STATS FILE
   */
  [DOWNLOAD_DATA_STATS_FILE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [DOWNLOAD_DATA_STATS_FILE_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [DOWNLOAD_DATA_STATS_FILE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * DOWNLOAD SPECIFIC EXTRACTION FILE
   */
  [DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [DOWNLOAD_SPECIFIC_EXTRACTION_FILE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * DOWNLOAD SPECIFIC COMPANY EXTRACTION FILE
   */
  [DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [DOWNLOAD_SPECIFIC_COMPANY_EXTRACTION_FILE_LOAD_ERROR]: (
    state,
    { data }
  ) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * COMPANY_GROUP
   */
  [COMPANY_GROUP_LOADING]: (state) => ({
    ...state,
    group: { loading: true },
  }),
  [COMPANY_GROUP_LOADED]: (state, { data }) => ({
    ...state,
    group: {
      ...state.group,
      loading: false,
      data: data,
    },
  }),
  [COMPANY_GROUP_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  list: {},
  detail: {},
  nielsen: {},
  financials: {},
  merge: {},
  group: {},
});
