import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";

export const getContactEntityApi = async ({ headers, page }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.contact.list}/entity`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getContactListApi = async ({ headers, page, params = null }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.contact.list}?pageNum=${page || 1}${
      params ? `&${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getContactDuplicatesApi = async ({ headers, params = [] }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.contact.list
    }/checkDuplicate?${params.join("&")}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const createContactDetailApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.contact.detail}`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getContactDetailApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.contact.detail}/${id}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const editContactDetailApi = async ({ headers, id, data }) => {
  if (data.modified_by) delete data.modified_by;
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.contact.detail}/${id}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getContactCompanyApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}?stats=no`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getContactBrandsApi = async ({ headers, id, params = null }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.contact.detail}/${id}/brands${
      params ? `?${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const addBrandToContactApi = async ({ headers, data = [], id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.contact.detail}/${id}/brands`,
    {
      method: "POST",
      headers,
      body: JSON.stringify({ _id: data }),
    }
  );

  return res;
};
