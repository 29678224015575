import { reducerFromMap } from "../libs/helpers/reducers";
import {
  getPaginatedProps,
  getDetailAndSchemaProps,
  getDetailProps,
} from "../libs/helpers/callApi";

import {
  PITCH_ENTITY_LOADING,
  PITCH_ENTITY_LOADED,
  PITCH_ENTITY_LOAD_ERROR,
  PITCH_DETAIL_LOADING,
  PITCH_DETAIL_LOADED,
  PITCH_DETAIL_LOAD_ERROR,
  PITCH_DETAIL_EDITING,
  PITCH_DETAIL_EDITED,
  PITCH_DETAIL_EDIT_ERROR,
  // PITCH_DETAIL_CREATING,
  // PITCH_DETAIL_CREATED,
  // PITCH_DETAIL_CREATE_ERROR,
} from "../actions/pitch.actions";

const reducer = {
  /*
   * BRAND_ENTITY
   */
  [PITCH_ENTITY_LOADING]: (state) => ({
    ...state,
    entity: { loading: true },
  }),
  [PITCH_ENTITY_LOADED]: (state, { data }) => ({
    ...state,
    entity: {
      loading: false,
      schema: data,
    },
  }),
  [PITCH_ENTITY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    entity: { loading: false },
    error: data,
  }),
  /* /!*
   * PITCH_LIST
   *!/
  [PITCH_LIST_LOADING]: (state) => ({
    ...state,
    list: { ...state.list, loading: true },
  }),
  [PITCH_LIST_LOADED]: (state, { data }) => ({
    ...state,
    list: { loading: false, ...getPaginatedProps(data) },
  }),
  [PITCH_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    list: { ...state.list, loading: false },
    error: data,
  }),*/
  /*
   * PITCH_DETAIL
   */
  [PITCH_DETAIL_LOADING]: (state) => ({
    ...state,
    detail: {
      ...state.detail,
      loading: true,
    },
  }),
  [PITCH_DETAIL_LOADED]: (state, { data }) => ({
    ...state,
    detail: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [PITCH_DETAIL_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*
   * PITCH_DETAIL_EDIT
   */
  [PITCH_DETAIL_EDITING]: (state) => ({
    ...state,
    detail: { ...state.detail, loading: true },
  }),
  [PITCH_DETAIL_EDITED]: (state, { data }) => ({
    ...state,
    detail: {
      ...state.detail,
      loading: false,
      ...getDetailProps(data),
    },
  }),
  [PITCH_DETAIL_EDIT_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*/!*
   * PITCH CREATE
   *!/
  [PITCH_DETAIL_CREATING]: (state) => ({
    ...state,
    detail: { ...state.detail, loading: true },
  }),
  [PITCH_DETAIL_CREATED]: (state, { data }) => ({
    ...state,
    detail: {
      ...state.detail,
      loading: false,
      ...getDetailProps(data),
    },
  }),
  [PITCH_DETAIL_CREATE_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),*/
};

export default reducerFromMap(reducer, { list: {} });
