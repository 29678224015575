import {
  getFlatV2EntityListApi,
  createFlatV2EntityApi,
  deleteFlatV2EntityApi,
  editFlatV2EntityApi,
} from "../api/flat.api";
import callApi from "../libs/helpers/callApi";
import { loadDictionaries } from "../locales";

export const FlatV2InvestmentRoundFundingTypeEntity =
  "InvestmentRoundFundingType";

export const FLATV2_LIST_LOADING = "FLATV2_LIST_LOADING";
export const FLATV2_LIST_LOADED = "FLATV2_LIST_LOADED";
export const FLATV2_LIST_LOAD_ERROR = "FLATV2_LIST_LOAD_ERROR";

export const getFlatV2EntityAction = callApi({
  apiCall: (p) => getFlatV2EntityListApi(p),
  secured: true,
  startAction: FLATV2_LIST_LOADING,
  successAction: FLATV2_LIST_LOADED,
  errorAction: FLATV2_LIST_LOAD_ERROR,
  extraActionParams: (p) => ({ entity: p.entity }),
});

export const CREATE_FLATV2_ENTITY_LOADING = "CREATE_FLATV2_ENTITY_LOADING";
export const CREATE_FLATV2_ENTITY_LOADED = "CREATE_FLATV2_ENTITY_LOADED";
export const CREATE_FLATV2_ENTITY_LOAD_ERROR =
  "CREATE_FLATV2_ENTITY_LOAD_ERROR";

export const createFlatV2EntityAction = callApi({
  apiCall: (p) => createFlatV2EntityApi(p),
  secured: true,
  startAction: CREATE_FLATV2_ENTITY_LOADING,
  successAction: CREATE_FLATV2_ENTITY_LOADED,
  errorAction: CREATE_FLATV2_ENTITY_LOAD_ERROR,
  processResult: (result) => {
    loadDictionaries();
    return result;
  },
  extraActionParams: (p) => ({ entity: p.entity }),
  afterSuccessAction: ({ dispatch, params }) => {
    dispatch(
      getFlatV2EntityAction({
        id: params.data.companyId,
        entity: params.entity,
      })
    );
  },
});

export const EDIT_FLATV2_ENTITY_LOADING = "EDIT_FLATV2_ENTITY_LOADING";
export const EDIT_FLATV2_ENTITY_LOADED = "EDIT_FLATV2_ENTITY_LOADED";
export const EDIT_FLATV2_ENTITY_LOAD_ERROR = "EDIT_FLATV2_ENTITY_LOAD_ERROR";

export const editFlatV2EntityAction = callApi({
  apiCall: (p) => editFlatV2EntityApi(p),
  secured: true,
  startAction: EDIT_FLATV2_ENTITY_LOADING,
  successAction: EDIT_FLATV2_ENTITY_LOADED,
  processResult: (result) => {
    loadDictionaries();
    return result;
  },
  errorAction: EDIT_FLATV2_ENTITY_LOAD_ERROR,
  extraActionParams: (p) => ({ entity: p.entity }),
  afterSuccessAction: ({ dispatch, params }) => {
    dispatch(
      getFlatV2EntityAction({
        id: params.data.companyId,
        entity: params.entity,
      })
    );
  },
});

export const DELETE_FLATV2_ENTITY_LOADING = "DELETE_FLATV2_ENTITY_LOADING";
export const DELETE_FLATV2_ENTITY_LOADED = "DELETE_FLATV2_ENTITY_LOADED";
export const DELETE_FLATV2_ENTITY_LOAD_ERROR =
  "DELETE_FLATV2_ENTITY_LOAD_ERROR";

export const deleteFlatV2EntityAction = callApi({
  apiCall: (p) => deleteFlatV2EntityApi(p),
  secured: true,
  startAction: DELETE_FLATV2_ENTITY_LOADING,
  successAction: DELETE_FLATV2_ENTITY_LOADED,
  errorAction: DELETE_FLATV2_ENTITY_LOAD_ERROR,
  extraActionParams: (p) => ({ entity: p.entity }),
  afterSuccessAction: ({ dispatch, params }) => {
    dispatch(
      getFlatV2EntityAction({
        id: params.companyId,
        entity: params.entity,
      })
    );
  },
});
