import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  CATEGORY_LOADING,
  CATEGORY_LOADED,
  CATEGORY_LOAD_ERROR,
} from "../../actions/registry/registry.category.actions";

const reducer = {
  /*
   *  CATEGORY LIST
   */
  [CATEGORY_LOADING]: (state) => ({
    ...state,
    category: { ...state.category, loading: true },
  }),
  [CATEGORY_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [CATEGORY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    category: { ...state.category, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
