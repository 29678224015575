import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const SCOPE_LOADING = "SCOPE_LOADING";
export const SCOPE_LOADED = "SCOPE_LOADED";
export const SCOPE_LOAD_ERROR = "SCOPE_LOAD_ERROR";

// INCOME RANGE GET LIST
export const getScopeAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "scope" }),
  secured: true,
  startAction: SCOPE_LOADING,
  successAction: SCOPE_LOADED,
  errorAction: SCOPE_LOAD_ERROR,
});

export const SCOPE_CREATE_LOADING = "SCOPE_CREATE_LOADING";
export const SCOPE_CREATE_COMPLETE = "SCOPE_CREATE_COMPLETE";
export const SCOPE_CREATE_LOAD_ERROR = "SCOPE_CREATE_LOAD_ERROR";

// SCOPE CREATE
export const createScopeDetailAction = callApi({
  apiCall: (p) => createFlatEntityApi(p),
  secured: true,
  startAction: SCOPE_CREATE_LOADING,
  successAction: SCOPE_CREATE_COMPLETE,
  errorAction: SCOPE_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getScopeAction());
    loadDictionaries();
  },
});

export const SCOPE_EDIT_LOADING = "SCOPE_EDIT_LOADING";
export const SCOPE_EDIT_COMPLETE = "SCOPE_EDIT_COMPLETE";
export const SCOPE_EDIT_LOAD_ERROR = "SCOPE_EDIT_LOAD_ERROR";

// SCOPE EDIT
export const editScopeAction = callApi({
  apiCall: (p) => editFlatEntityApi(p),
  secured: true,
  startAction: SCOPE_EDIT_LOADING,
  successAction: SCOPE_EDIT_COMPLETE,
  errorAction: SCOPE_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getScopeAction());
    loadDictionaries();
  },
});

export const SCOPE_DELETE_LOADING = "SCOPE_DELETE_LOADING";
export const SCOPE_DELETE_COMPLETE = "SCOPE_DELETE_COMPLETE";
export const SCOPE_DELETE_LOAD_ERROR = "SCOPE_DELETE_LOAD_ERROR";

// SCOPE DELETE
export const deleteScopeAction = callApi({
  apiCall: (p) => deleteFlatEntityApi(p),
  secured: true,
  startAction: SCOPE_DELETE_LOADING,
  successAction: SCOPE_DELETE_COMPLETE,
  errorAction: SCOPE_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getScopeAction());
  },
});
