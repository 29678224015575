import callApi from "../../libs/helpers/callApi";
import {
  createRegistryTreeDetailApi,
  deleteRegistryTreeApi,
  editRegistryTreeDetailApi,
  getTreeDataApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const CATEGORY_LOADING = "CATEGORY_LOADING";
export const CATEGORY_LOADED = "CATEGORY_LOADED";
export const CATEGORY_LOAD_ERROR = "CATEGORY_LOAD_ERROR";

// CATEGORY LIST
export const getTreeCategoryAction = callApi({
  apiCall: (p) => getTreeDataApi({ ...p, entity: "Category" }),
  secured: true,
  startAction: CATEGORY_LOADING,
  successAction: CATEGORY_LOADED,
  errorAction: CATEGORY_LOAD_ERROR,
});

export const CATEGORY_CREATE_LOADING = "CATEGORY_CREATE_LOADING";
export const CATEGORY_CREATE_COMPLETE = "CATEGORY_CREATE_COMPLETE";
export const CATEGORY_CREATE_LOAD_ERROR = "CATEGORY_CREATE_LOAD_ERROR";

// CATEGORY CREATE
export const createTreeCategoryDetailAction = callApi({
  apiCall: (p) => createRegistryTreeDetailApi(p),
  secured: true,
  startAction: CATEGORY_CREATE_LOADING,
  successAction: CATEGORY_CREATE_COMPLETE,
  errorAction: CATEGORY_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getTreeCategoryAction());
    loadDictionaries();
  },
});

export const CATEGORY_EDIT_LOADING = "CATEGORY_EDIT_LOADING";
export const CATEGORY_EDIT_COMPLETE = "CATEGORY_EDIT_COMPLETE";
export const CATEGORY_EDIT_LOAD_ERROR = "CATEGORY_EDIT_LOAD_ERROR";

// CATEGORY EDIT
export const editTreeCategoryDetailAction = callApi({
  apiCall: (p) => editRegistryTreeDetailApi(p),
  secured: true,
  startAction: CATEGORY_EDIT_LOADING,
  successAction: CATEGORY_EDIT_COMPLETE,
  errorAction: CATEGORY_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getTreeCategoryAction());
    loadDictionaries();
  },
});

export const CATEGORY_DELETE_LOADING = "CATEGORY_DELETE_LOADING";
export const CATEGORY_DELETE_COMPLETE = "CATEGORY_DELETE_COMPLETE";
export const CATEGORY_DELETE_LOAD_ERROR = "CATEGORY_DELETE_LOAD_ERROR";

// CATEGORY DELETE
export const deleteTreeCategoryAction = callApi({
  apiCall: (p) => deleteRegistryTreeApi(p),
  secured: true,
  startAction: CATEGORY_DELETE_LOADING,
  successAction: CATEGORY_DELETE_COMPLETE,
  errorAction: CATEGORY_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getTreeCategoryAction());
  },
});
