import axios from "axios";
import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export const getPitchEntityApi = async ({ headers, page }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.pitch.list}/entity`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};
//

export const getPitchDetailApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.pitch.list}/${id}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const editPitchDetailApi = async ({ headers, id, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.pitch.detail}/${id}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const getPitchScoresApi = async ({ headers, data = {} }) => {
  const tenant = getFromLocalStorage("lefacTenant")?.data;
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  try {
    const res = await axios.post(
      `${Config.apiBaseurl}/api/v1/pitch/scores`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-tenant": tenant,
          ...headers,
        },
      }
    );

    return res.data;
  } catch (e) {
    if (e.response) {
      const { statusText, status } = e.response;
      const { httpStatus, clientType, description } = e.response.data;
      throw new ErrorMessage(
        statusText,
        description,
        httpStatus,
        status,
        clientType
      );
    } else throw e;
  }
};

export const editPitchScoreApi = async ({ headers, data = {}, id }) => {
  const tenant = getFromLocalStorage("lefacTenant")?.data;
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  try {
    const res = await axios.post(
      `${Config.apiBaseurl}/api/v1/pitch/scores/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-tenant": tenant,
          ...headers,
        },
      }
    );

    return res.data;
  } catch (e) {
    if (e.response) {
      const { statusText, status } = e.response;
      const { httpStatus, clientType, description } = e.response.data;
      throw new ErrorMessage(
        statusText,
        description,
        httpStatus,
        status,
        clientType
      );
    } else throw e;
  }
};
