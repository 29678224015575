import React from "react";
import Localize from "../../components/Localized/Localize.comp";
// import Lc from "../../components/Localized/Localize.comp";

const HomePage = () => {
  return (
    <>
      <p>{Localize("PAGES.HOME.DESCRIPTION")}</p>

      {/*<Localized search="PAGES.HOME.DESCRIPTION" />*/}

      {/*{Localized({search: "PAGES.HOME.DESCRIPTION"})}*/}
      {/* {Lc("PAGES.HOME.DESCRIPTION")} */}
    </>
  );
};

export default HomePage;
