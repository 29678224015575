import React, { useEffect, useState } from "react";
import { Col } from "antd";
import SearchPlaces from "../SearchPlaces/SearchPlaces";
import { googlePlacesParser } from "../DetailPane/_DetailPane.helpers";
import Localize from "../Localized/Localize.comp";

const Address = (props) => {
  const {
    style,
    searchStyle,
    editing,
    dataForm,
    onChange,
    searchPlaceholder,
    formattedAddressPlaceholder,
    showFormatted = true,
    showNote = true,
  } = props;

  const [formattedAddress, setFormattedAddress] = useState();

  useEffect(() => {
    if (dataForm) {
      setFormattedAddress(getFormattedAddress(dataForm));
    }
  }, [dataForm]);

  const getFormattedAddress = (data) => {
    if (data && data.address) {
      return (
        <div>
          <p>{data.address || "-"}</p>
          <p>
            {data.CP ? `${data.CP} - ` : ""}
            {data.city}
            {data.province ? ` (${data.province}) - ` : ""}
            {data.region ? `${data.region}, ` : ""}
            {data.nation}
          </p>
        </div>
      );
    }
    return "";
  };

  const getStringAddress = (data) => {
    if (data && data.address) {
      return `${data.address || ""}, ${data.CP || ""} ${data.city || ""} ${
        data.province || ""
      }${data.region ? ` (${data.region})` : ""}, ${data.nation || ""}`;
    }
    return "";
  };

  return (
    <>
      {editing && (
        <Col style={{ ...searchStyle }}>
          <div style={{ minWidth: "300px" }}>
            <b>{searchPlaceholder}:</b>
            <SearchPlaces
              placeInput={
                dataForm.address
                  ? getStringAddress(dataForm)
                  : formattedAddressPlaceholder
              }
              searchPlaceholder={searchPlaceholder}
              onChange={(gplace) => {
                const { address_components, geometry } = gplace;
                const place = googlePlacesParser(address_components);
                const { location } = geometry || {};

                if (place && location) {
                  const {
                    street_number,
                    route,
                    locality,
                    postal_town,
                    administrative_area_level_3: hamlet,
                    administrative_area_level_2: province,
                    administrative_area_level_1: region,
                    country: nation,
                    postal_code: CP,
                  } = place;

                  const city = locality || hamlet || postal_town;

                  const dataAddress = {
                    address: `${route || ""} ${street_number || ""}`,
                    region,
                    province,
                    CP,
                    city,
                    nation,
                    position: {
                      lat: location.lat(),
                      lng: location.lng(),
                    },
                  };

                  setFormattedAddress(getFormattedAddress(dataAddress));

                  onChange({
                    ...dataForm,
                    ...dataAddress,
                  });
                } else {
                  const dataAddress = {
                    address: "",
                    city: "",
                    nation: "",
                  };
                  onChange({
                    ...dataForm,
                    ...dataAddress,
                  });
                }
              }}
            />
          </div>
        </Col>
      )}
      {(showFormatted || formattedAddressPlaceholder) && (
        <Col style={style}>
          <p>
            <b>{Localize("COMMON.ADDRESS")}:</b>
          </p>
          <div>
            {dataForm.address ? formattedAddress : formattedAddressPlaceholder}
          </div>
        </Col>
      )}
      {showNote && (
        <Col style={style}>
          <p>
            <b>{Localize("COMMON.ADDRESS_NOTE")}:</b>
          </p>
          <p>
            <textarea
              disabled={!editing}
              className="ant-input"
              value={dataForm.addressNotes}
              placeholder="—"
              onChange={(e) => {
                onChange({
                  ...dataForm,
                  addressNotes: e.target.value,
                });
              }}
            />
          </p>
        </Col>
      )}
    </>
  );
};

export default Address;
