import { reducerFromMap } from "../libs/helpers/reducers";
import {
  RSS_FEED_LIST_LOADED,
  RSS_FEED_LIST_LOADING,
  RSS_FEED_DELETE_LOADING,
  RSS_FEED_DELETE_LOADED,
  RSS_FEED_LOAD_ERROR,
  RSS_FEED_EDIT_LOADING,
  RSS_FEED_EDIT_LOADED,
  RSS_FEED_LIST_LOAD_ERROR,
} from "../actions/rssFeed.actions";
import { getPaginatedProps } from "../libs/helpers/callApi";

const reducer = {
  /*
   * RSS_FEED_LIST
   */
  [RSS_FEED_LIST_LOADING]: (state) => ({
    ...state,
    rssFeedList: { ...state.rssFeedList, loading: true },
  }),
  [RSS_FEED_LIST_LOADED]: (state, { data }) => ({
    ...state,
    rssFeedList: { loading: false, ...getPaginatedProps(data) },
  }),
  [RSS_FEED_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    rssFeedList: { ...state.rssFeedList, loading: false },
    error: data,
  }),
  /*
   * RSS_FEED_DELETE
   */
  [RSS_FEED_DELETE_LOADING]: (state) => ({
    ...state,
    rssFeedList: { ...state.rssFeedList, loading: true },
  }),
  [RSS_FEED_DELETE_LOADED]: (state, { data }) => ({
    ...state,
    rssFeedList: { ...state.rssFeedList, loading: false },
  }),
  [RSS_FEED_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    rssFeedList: { ...state.rssFeedList, loading: true },
    error: data,
  }),
  /*
   * RSS_FEED_EDIT
   */
  [RSS_FEED_EDIT_LOADING]: (state) => ({
    ...state,
    rssFeedList: { ...state.rssFeedList, loading: true },
  }),
  [RSS_FEED_EDIT_LOADING]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [RSS_FEED_EDIT_LOADED]: (state, { data }) => ({
    ...state,
    rssFeedList: { ...state.rssFeedList, loading: true },
    error: data,
  }),
};

export default reducerFromMap(reducer, { rssFeedList: {} });
