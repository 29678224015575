import React, { useState } from "react";
import { Layout, Row, Col, Dropdown, Menu, Button, Modal } from "antd";
import {
  DownOutlined,
  GlobalOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
//Svg as component
import { Link } from "react-router-dom";
// import ReactMarkdown from "react-markdown";

// // import changelogFile from "../../../changelog.md";

import { useDispatch, useSelector } from "react-redux";
import LocalizedFlag from "../Localized/LocalizedFlag";

import Logo from "../../assets/images/blinko-azul.png";

import "./Header.scss";
import Localize, {
  getCurrentLocale,
} from "../../components/Localized/Localize.comp";
import Config from "../../Config";
import TenantSelector from "../TenantSelector/TenantSelector";
import { refreshPage } from "../../libs/utils";
import { setLanguageAction } from "../../actions/app.actions";

const { Header } = Layout;

export default ({ collapsed, onToggleClick, username, logoutAction }) => {
  const [showModal, setShowModal] = useState(false);

  const locale = useSelector((state) => state.app.language);
  const dispatch = useDispatch();

  return (
    <>
      <Header className="header">
        <Row justify="space-between">
          <Col span="auto">
            <div className="logo">
              <img
                style={{ cursor: "pointer", maxWidth: "100px" }}
                alt="logo"
                src={Logo}
              />
            </div>
          </Col>
          <Col span="auto">
            {/* <Button
            size="small"
            onClick={() => {
              getChangelog();
              setShowModal(!showModal);
            }}
          >
            Changelog
          </Button> */}
            <Dropdown
              className="dropdown-user"
              overlay={
                <Menu>
                  {(Config.locales || []).map((l, i) => (
                    <Menu.Item key={i}>
                      <Button
                        type="link"
                        onClick={() => {
                          dispatch(setLanguageAction({ language: l }));
                          window.location.reload();
                        }}
                      >
                        {LocalizedFlag({
                          locale: l,
                          style: { height: 10 },
                        })}
                      </Button>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={["click"]}
              placement="bottomCenter"
              arrow
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                style={{ marginRight: 30 }}
              >
                {LocalizedFlag({
                  locale,
                  style: { height: 10 },
                })}
              </a>
            </Dropdown>
            <Dropdown
              className="dropdown-user"
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    onClick={() => {
                      setShowModal(!showModal);
                    }}
                  >
                    <GlobalOutlined /> {Localize("LOGIN.CHANGE_TENANT")}
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={typeof logoutAction === "function" && logoutAction}
                  >
                    <LogoutOutlined /> {Localize("LOGIN.LOGOUT")}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              placement="bottomRight"
              arrow
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <UserOutlined />
                {username || "User"}
                <DownOutlined />
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Modal
        title={
          <>
            <GlobalOutlined /> {Localize("LOGIN.CHANGE_TENANT")}
          </>
        }
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <TenantSelector
          onSelect={() => {
            setShowModal(false);
            refreshPage();
          }}
        />
      </Modal>
    </>
  );
};
