import React, { useEffect, useState } from "react";
import { Modal, Tree } from "antd";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import Localize from "../../libs/localization";
import "./tree.scss";

const TreeView = (props) => {
  const {
    entityList,
    entityName,
    onSelect,
    moveCallback,
    draggable = true,
  } = props;
  const [backup, setBackup] = useState([]);
  const [list, setList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (entityList) {
      renderSector(entityList);
    }
  }, [entityList]);

  const onDragEnter = (drag) => {};

  const loop = (data, key, callback) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children, key, callback);
      }
    }
  };

  const onDrop = (info) => {
    const dropKey = info.node.props.eventKey;
    const dropItem = info.node.props;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const data = [...list];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.push(dragObj);
      });

      if (dropItem.parentPath === null) {
        dragObj.path = `${
          dropItem.path !== "/" ? dropItem.path : ""
        }/${dropKey}`;
        dragObj.parentPath = `/`;
      }

      if (dropItem.parentPath === "/") {
        dragObj.path = `${dropItem.path}/${dropKey}`;
        dragObj.parentPath = `${dropItem.path}`;
      }
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      if (dropItem.parentPath === null) {
        dragObj.path = `/`;
        dragObj.parentPath = null;
      }

      if (dropItem.parentPath === "/") {
        dragObj.path = `${dropItem.path}`;
        dragObj.parentPath = `/`;
      }

      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });

      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setList(data);
    // RICHIEDE CONFERMA SPOSTAMENTO
    showMoveConfirm(dragObj);
  };

  function showMoveConfirm(dragObj) {
    Modal.confirm({
      title: Localize("COMMON.CONFIRM_MOVE"),
      okText: Localize("COMMON.YES"),
      okType: "danger",
      cancelText: Localize("COMMON.NO"),
      onOk: () => {
        dispatch(
          moveCallback({
            id: escape(dragObj.key),
            entity: entityName,
            data: {
              key: dragObj.label,
              path: dragObj.path,
            },
          })
        );
      },
      onCancel: () => {
        setList(backup);
      },
    });
  }

  const recursiveChildRender = (_list, parentPath = null) => {
    return _list.map((data) => {
      return {
        key: data._id,
        title: (
          <span className={`${!data.active ? "deleted" : ""}`}>{data.key}</span>
        ),
        label: data.key,
        path: data.path,
        parentPath,
        isActive: data.active,
        children:
          data.children && recursiveChildRender(data.children, data.path),
      };
    });
  };

  const renderSector = (_list) => {
    const res = recursiveChildRender(_list);
    const deep = cloneDeep(res);
    setBackup(deep);
    return setList(res);
  };

  return (
    <Tree
      onSelect={(data, ev) => {
        const { key, title, path, isActive, label } = ev.node;
        onSelect({ key, title, path, isActive, label });
      }}
      className="draggable-tree tree-background"
      draggable={draggable}
      blockNode
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      treeData={list}
    />
  );
};

export default TreeView;
