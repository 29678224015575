import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Col, Button, Popconfirm, notification } from "antd";
import { mergeCompanyAction } from "../../actions/company.actions";
import DetailPane from "../../components/DetailPane/DetailPane";
import Localize from "../../libs/localization";
import CompanyAutocomplete from "../../components/Autocomplete/CompanyAutocomplete";

export const openCompanyMergedNotification = () => {
  notification.open({
    message: `${Localize("PAGES.SETTINGS.MERGE.COMPANY_NOTIFICATION_DESC")}`,
    onClick: () => {
      console.log("Notification Clicked!");
    },
  });
};

const TransferSettingsPartial = (props) => {
  const [fromCompany, setFromCompany] = useState(null);
  const [toCompany, setToCompany] = useState(null);
  const token = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.company.merge.loading);

  return (
    <>
      <DetailPane
        title="Merge Companies"
        component={
          <Form>
            <Row gutter={16} align="middle">
              <Col span={8}>
                <p>
                  <b>{Localize("PAGES.SETTINGS.MERGE.FROM_COMPANY")}:</b>
                </p>
                <Form.Item name="fromCompany">
                  <CompanyAutocomplete
                    cancelValue
                    item={fromCompany}
                    token={token}
                    onSelect={(value, opt) => {
                      setFromCompany(opt.obj);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <p>
                  <b>{Localize("PAGES.SETTINGS.MERGE.TO_COMPANY")}:</b>
                </p>
                <Form.Item name="toCompany">
                  <CompanyAutocomplete
                    cancelValue
                    item={toCompany}
                    token={token}
                    onSelect={(value, opt) => {
                      setToCompany(opt.obj);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Popconfirm
                  placement="left"
                  title={Localize("CONFIRM.SAVE_EDIT")}
                  onConfirm={() => {
                    dispatch(
                      mergeCompanyAction({
                        destinationId: toCompany.id,
                        sourceId: fromCompany.id,
                      })
                    );
                    setFromCompany(null);
                    setToCompany(null);
                  }}
                  okText={Localize("COMMON.YES")}
                  cancelText={Localize("COMMON.NO")}
                >
                  <Button type="primary" disabled={!fromCompany || !toCompany}>
                    {Localize("PAGES.SETTINGS.MERGE.MERGE_COMPANIES_BTM")}
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Form>
        }
        loading={loading}
        disableEditing={true}
        disableCreation={true}
      />
    </>
  );
};

export default TransferSettingsPartial;
