import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  CREA_CATEGORY_LOADING,
  CREA_CATEGORY_LOADED,
  CREA_CATEGORY_LOAD_ERROR,
  CREA_CATEGORY_CREATE_LOADING,
  CREA_CATEGORY_CREATE_LOAD_ERROR,
  CREA_CATEGORY_EDIT_LOADING,
  CREA_CATEGORY_EDIT_COMPLETE,
  CREA_CATEGORY_EDIT_LOAD_ERROR,
  CREA_CATEGORY_DELETE_LOADING,
  CREA_CATEGORY_DELETE_COMPLETE,
  CREA_CATEGORY_DELETE_LOAD_ERROR,
  CREA_CATEGORY_CREATE_COMPLETE,
} from "../../actions/registry/registry.creaCategory.actions";

const reducer = {
  /*
   * CREA_CATEGORY LIST
   */
  [CREA_CATEGORY_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [CREA_CATEGORY_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [CREA_CATEGORY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * CREA_CATEGORY CREATE
   */
  [CREA_CATEGORY_CREATE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [CREA_CATEGORY_CREATE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [CREA_CATEGORY_CREATE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * CREA_CATEGORY EDIT
   */
  [CREA_CATEGORY_EDIT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [CREA_CATEGORY_EDIT_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [CREA_CATEGORY_EDIT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * CREA_CATEGORY DELETE
   */
  [CREA_CATEGORY_DELETE_LOADING]: (state) => ({
    ...state,
    crea_category: { ...state.crea_category, loading: true },
  }),
  [CREA_CATEGORY_DELETE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [CREA_CATEGORY_DELETE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    crea_category: { ...state.crea_category, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
