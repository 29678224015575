import React from "react";
import { Steps, Button, message, Popconfirm } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Localize from "../Localized/Localize.comp";

const { Step } = Steps;

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  render() {
    const { current } = this.state;
    const { steps, status, save } = this.props;
    if (steps) {
      return (
        <>
          <Steps current={current} status={status} style={{ padding: 20 }}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content" style={{ padding: 30 }}>
            {steps[current].content}
          </div>
          <div className="steps-action" style={{ padding: 20 }}>
            {current > 0 && (
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => this.prev()}
                disabled={status === "error"}
              >
                {Localize("COMMON.PREVIOUS")}
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => this.next()}
                disabled={status === "error"}
              >
                {Localize("COMMON.NEXT")}
              </Button>
            )}
            {current === steps.length - 1 && (
              <Popconfirm
                placement="left"
                title={Localize("CONFIRM.SAVE_EDIT")}
                onConfirm={() => save()}
                disabled={status === "error"}
                okText={Localize("COMMON.YES")}
                cancelText={Localize("COMMON.NO")}
              >
                <Button type="primary" disabled={status === "error"}>
                  <SaveOutlined /> {Localize("COMMON.SAVE")}
                </Button>
              </Popconfirm>
            )}
          </div>
        </>
      );
    }
  }
}

export default Stepper;
