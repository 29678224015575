import callApi from "../libs/helpers/callApi";
import {
  createFlatV2EntityApi,
  deleteFlatV2EntityApi,
  editFlatV2EntityApi,
  getFlatV2EntityListApi,
} from "../api/flat.api";

export const FLAT_LIST_LOADING = "FLAT_LIST_LOADING";
export const FLAT_LIST_LOADED = "FLAT_LIST_LOADED";
export const FLAT_LIST_LOAD_ERROR = "FLAT_LIST_LOAD_ERROR";

// FLAT LIST
export const getFlatListAction = callApi({
  apiCall: (p) => getFlatV2EntityListApi(p),
  secured: true,
  startAction: FLAT_LIST_LOADING,
  successAction: FLAT_LIST_LOADED,
  errorAction: FLAT_LIST_LOAD_ERROR,
});

export const FLAT_ENTITY_CREATE_LOADING = "FLAT_ENTITY_CREATE_LOADING";
export const FLAT_ENTITY_CREATE_COMPLETE = "FLAT_ENTITY_CREATE_COMPLETE";
export const FLAT_ENTITY_CREATE_LOAD_ERROR = "FLAT_ENTITY_CREATE_LOAD_ERROR";

// FLAT ENTITY CREATE
export const createFlatEntityAction = callApi({
  apiCall: (p) => createFlatV2EntityApi(p),
  secured: true,
  startAction: FLAT_ENTITY_CREATE_LOADING,
  successAction: FLAT_ENTITY_CREATE_COMPLETE,
  errorAction: FLAT_ENTITY_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) =>
    dispatch(getFlatListAction({ entity: params.entity })),
});

export const FLAT_ENTITY_EDIT_LOADING = "FLAT_ENTITY_EDIT_LOADING";
export const FLAT_ENTITY_EDIT_COMPLETE = "FLAT_ENTITY_EDIT_COMPLETE";
export const FLAT_ENTITY_EDIT_LOAD_ERROR = "FLAT_ENTITY_EDIT_LOAD_ERROR";

// FLAT ENTITY EDIT
export const editFlatEntityAction = callApi({
  apiCall: (p) => editFlatV2EntityApi(p),
  secured: true,
  startAction: FLAT_ENTITY_EDIT_LOADING,
  successAction: FLAT_ENTITY_EDIT_COMPLETE,
  errorAction: FLAT_ENTITY_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) =>
    dispatch(getFlatListAction({ entity: params.entity })),
});

export const FLAT_ENTITY_DELETE_LOADING = "FLAT_ENTITY_DELETE_LOADING";
export const FLAT_ENTITY_DELETE_COMPLETE = "FLAT_ENTITY_DELETE_COMPLETE";
export const FLAT_ENTITY_DELETE_LOAD_ERROR = "FLAT_ENTITY_DELETE_LOAD_ERROR";

// FLAT ENTITY DELETE
export const deleteFlatEntityAction = callApi({
  apiCall: (p) => deleteFlatV2EntityApi(p),
  secured: true,
  startAction: FLAT_ENTITY_DELETE_LOADING,
  successAction: FLAT_ENTITY_DELETE_COMPLETE,
  errorAction: FLAT_ENTITY_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) =>
    dispatch(getFlatListAction({ entity: params.entity })),
});
