import { reducerFromMap } from "../../libs/helpers/reducers";
import {
  getPaginatedProps,
  getDetailAndSchemaProps,
  getDetailProps,
} from "../../libs/helpers/callApi";

import {} from "../../actions/pitch.actions";
import {
  SECTOR_LOADING,
  SECTOR_LOADED,
  SECTOR_LOAD_ERROR,
  SECTOR_EDIT_LOADING,
  SECTOR_EDIT_COMPLETE,
  SECTOR_EDIT_LOAD_ERROR,
  SECTOR_DELETE_LOADING,
  SECTOR_DELETE_COMPLETE,
  SECTOR_DELETE_LOAD_ERROR,
  SECTOR_CREATE_LOADING,
  SECTOR_CREATE_COMPLETE,
  SECTOR_CREATE_LOAD_ERROR,
  SECTOR_MERGE_LOADING,
  SECTOR_MERGE_COMPLETE,
  SECTOR_MERGE_LOAD_ERROR,
} from "../../actions/registry/registry.sector.actions";

const reducer = {
  /*
   * SECTOR LIST
   */
  [SECTOR_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SECTOR_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [SECTOR_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SECTOR CREATE
   */
  [SECTOR_CREATE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SECTOR_CREATE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SECTOR_CREATE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SECTOR EDIT
   */
  [SECTOR_EDIT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SECTOR_EDIT_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SECTOR_EDIT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * SECTOR DELETE
   */
  [SECTOR_DELETE_LOADING]: (state) => ({
    ...state,
    sector: { ...state.sector, loading: true },
  }),
  [SECTOR_DELETE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SECTOR_DELETE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    sector: { ...state.sector, loading: false },
    error: data,
  }),
  /*
   * SECTOR MERGE
   */
  [SECTOR_MERGE_LOADING]: (state) => ({
    ...state,
    sector: { ...state.sector, loading: true },
  }),
  [SECTOR_MERGE_COMPLETE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [SECTOR_MERGE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    sector: { ...state.sector, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
