import {
  COMPANY_INVESTMENT_ROUND_LOADED,
  COMPANY_INVESTMENT_ROUND_LOADING,
  COMPANY_INVESTMENT_ROUND_LOAD_ERROR,
  CREATE_INVESTMENT_ROUND_LOADED,
  CREATE_INVESTMENT_ROUND_LOADING,
  CREATE_INVESTMENT_ROUND_LOAD_ERROR,
  DELETE_INVESTMENT_ROUND_LOADED,
  DELETE_INVESTMENT_ROUND_LOADING,
  DELETE_INVESTMENT_ROUND_LOAD_ERROR,
} from "../actions/investmentRound.actions";
import { reducerFromMap } from "../libs/helpers/reducers";

const reducer = {
  /*
   * COMPANY INVESTMENT ROUND
   */
  [COMPANY_INVESTMENT_ROUND_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [COMPANY_INVESTMENT_ROUND_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    list: data,
  }),
  [COMPANY_INVESTMENT_ROUND_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * INVESTMENT ROUND CREATION
   */
  [CREATE_INVESTMENT_ROUND_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [CREATE_INVESTMENT_ROUND_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [CREATE_INVESTMENT_ROUND_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * INVESTMENT ROUND DELETE
   */
  [DELETE_INVESTMENT_ROUND_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [DELETE_INVESTMENT_ROUND_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [DELETE_INVESTMENT_ROUND_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  list: [],
});
