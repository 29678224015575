import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";
import { loadDictionaries } from "../../locales";

export const FUNCTION_LOADING = "FUNCTION_LOADING";
export const FUNCTION_LOADED = "FUNCTION_LOADED";
export const FUNCTION_LOAD_ERROR = "FUNCTION_LOAD_ERROR";

// FUNCTION GET LIST
export const getFunctionAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "function" }),
  secured: true,
  startAction: FUNCTION_LOADING,
  successAction: FUNCTION_LOADED,
  errorAction: FUNCTION_LOAD_ERROR,
});

export const FUNCTION_CREATE_LOADING = "FUNCTION_CREATE_LOADING";
export const FUNCTION_CREATE_COMPLETE = "FUNCTION_CREATE_COMPLETE";
export const FUNCTION_CREATE_LOAD_ERROR = "FUNCTION_CREATE_LOAD_ERROR";

// FUNCTION CREATE
export const createFunctionDetailAction = callApi({
  apiCall: (p) => createFlatEntityApi(p),
  secured: true,
  startAction: FUNCTION_CREATE_LOADING,
  successAction: FUNCTION_CREATE_COMPLETE,
  errorAction: FUNCTION_CREATE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getFunctionAction());
    loadDictionaries();
  },
});

export const FUNCTION_EDIT_LOADING = "FUNCTION_EDIT_LOADING";
export const FUNCTION_EDIT_COMPLETE = "FUNCTION_EDIT_COMPLETE";
export const FUNCTION_EDIT_LOAD_ERROR = "FUNCTION_EDIT_LOAD_ERROR";

// FUNCTION EDIT
export const editFunctionAction = callApi({
  apiCall: (p) => editFlatEntityApi(p),
  secured: true,
  startAction: FUNCTION_EDIT_LOADING,
  successAction: FUNCTION_EDIT_COMPLETE,
  errorAction: FUNCTION_EDIT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getFunctionAction());
    loadDictionaries();
  },
});

export const FUNCTION_DELETE_LOADING = "FUNCTION_DELETE_LOADING";
export const FUNCTION_DELETE_COMPLETE = "FUNCTION_DELETE_COMPLETE";
export const FUNCTION_DELETE_LOAD_ERROR = "FUNCTION_DELETE_LOAD_ERROR";

// FUNCTION DELETE
export const deleteFunctionAction = callApi({
  apiCall: (p) => deleteFlatEntityApi(p),
  secured: true,
  startAction: FUNCTION_DELETE_LOADING,
  successAction: FUNCTION_DELETE_COMPLETE,
  errorAction: FUNCTION_DELETE_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    dispatch(getFunctionAction());
  },
});
